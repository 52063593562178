import {Controller} from "@hotwired/stimulus";
import Locale from "../locale";
import moment from "moment";
import {easepick, AmpPlugin, RangePlugin, LockPlugin, DateTime} from "@easepick/bundle";

export default class extends Controller {

    connect() {
        const calendars = this.data.has("calendars") ? this.data.get("calendars") : 1,
            plugins = [RangePlugin, AmpPlugin, LockPlugin],
            minDate = this.data.has("minDate") ? this.data.get("minDate") : '2014-01-01',
            maxDate = new DateTime(),
            options = {
                element: this.element,
                css: [this.data.get("css")],
                lang: Locale.get(),
                calendars: calendars,
                grid: this.data.has("grid") ? this.data.get("grid") : 1,
                firstDay: this.data.has("firstDay") ? parseInt(this.data.get("firstDay")) : 1,
                inline: this.data.has("inline") && !!this.data.get("inline"),
                plugins: plugins,
                AmpPlugin: {
                    dropdown: {
                        years: true,
                        minYear: moment(minDate).year(),
                    },
                    darkMode: false
                },
                RangePlugin: {
                    tooltip: true,
                },
                LockPlugin: {
                    minDate: minDate,
                    maxDate: maxDate,
                    maxDays: 500
                }
            };
        if (calendars === 1) {
            // When showing multiple calendars, every dropdown controls the first calendar which is confusing.
            options['AmpPlugin']['dropdown']['months'] = true
        }

        this.picker = new easepick.create(options);

        if (calendars > 1) {
            // When showing multiple calendars, make sure we don't show a bunch of disabled months.
            let firstDate = moment(maxDate.toJSDate()).startOf('month').subtract(calendars - 1, 'months');
            if (firstDate.isBefore()) {
                this.picker.gotoDate(new DateTime(firstDate.toDate()));
            }
        }
        if (this.data.has('shadow')) {
            this.picker.on('render', (e) => {
                e.target.classList.add('shadow-' + this.data.get('shadow'));
            });
        }
        if (this.data.has('cycleDate') && this.data.has('cycleDuration')) {
            const cycleDate = new DateTime(this.data.get('cycleDate')), cycleDuration = this.data.get('cycleDuration');
            this.picker.on('view', (evt) => {
                const {view, date, target} = evt.detail;
                if (view === 'CalendarDay' && date.diff(cycleDate, 'days') % cycleDuration === 0) {
                    target.classList.add('working-cycle-start');
                }
            });
        }
    }

    disconnect() {
        this.picker.destroy();
    }
}