import CodeMirror from "codemirror";
import {Pos} from "codemirror/src/line/pos";

/*
 * Deletes entire tab is backspace is pressed on a line with only tabs.
 * (Borrowed from the sublime keymap)
 */

CodeMirror.commands.smartBackspace = function (cm) {
    if (cm.somethingSelected()) return CodeMirror.Pass;
    cm.operation(function () {
        const cursors = cm.listSelections(), indentUnit = cm.getOption("indentUnit");

        for (let i = cursors.length - 1; i >= 0; i--) {
            const cursor = cursors[i].head,
                toStartOfLine = cm.getRange({line: cursor.line, ch: 0}, cursor),
                column = CodeMirror.countColumn(toStartOfLine, null, cm.getOption("tabSize"));

            // Delete by one character by default
            let deletePos = cm.findPosH(cursor, -1, "char", false);

            if (toStartOfLine && !/\S/.test(toStartOfLine) && column % indentUnit === 0) {
                const prevIndent = new Pos(cursor.line,
                    CodeMirror.findColumn(toStartOfLine, column - indentUnit, indentUnit));

                // Smart delete only if we found a valid prevIndent location
                if (prevIndent.ch !== cursor.ch) deletePos = prevIndent;
            }

            cm.replaceRange("", deletePos, cursor, "+delete");
        }
    });
};