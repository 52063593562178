import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="revision-wizard"
export default class extends Controller {
    static targets = ['customerInput', 'selectResource', 'clearResources', 'confirmationForm',
        'selectActionUpdateToggle', 'selectActionUndoToggle', 'selectActionTabs', 'selectActionInstructionTab',
        'selectActionUpdateTab', 'selectActionUndoTab']
    static values = {
        selectedResourceCount: Number
    }

    connect() {
    }

    customerInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    selectResourceTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this.selectedResourceCountValue += 1;
            } else {
                this.selectedResourceCountValue -= 1;
            }
            this._setConfirmationEnabled(this.selectedResourceCountValue > 0);
        });
    }

    clearResourcesTargetConnected(el) {
        el.addEventListener('click', () => {
            this.selectResourceTargets.forEach(input => {
                input.checked = false;
                input.dispatchEvent(new Event('change', {'bubbles': true}));
            });
            this.selectedResourceCountValue = 0;
            this._setConfirmationEnabled(false);
        });
    }

    selectActionUpdateToggleTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this._showSelectActionTab(this.selectActionUpdateTabTarget);
                this._setConfirmationEnabled(true);
            }
        });
    }

    selectActionUndoToggleTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this._showSelectActionTab(this.selectActionUndoTabTarget);
                this._setConfirmationEnabled(true);
            }
        });
    }

    _setConfirmationEnabled(whether) {
        const disabled = !whether;
        this.confirmationFormTarget.querySelectorAll('input[type=submit],button[type=submit]').forEach(submitButton => {
            submitButton.disabled = disabled;
            submitButton.classList.toggle('disabled', disabled);
        });
    }

    _showSelectActionTab(tab) {
        for (const otherTab of this.selectActionTabsTarget.children) {
            if (otherTab.classList.contains('active')) {
                otherTab.setAttribute('aria-selected', false);
                otherTab.classList.remove('show');
                setTimeout(() => otherTab.classList.remove('active'), 150);
            }
        }
        if (!tab.classList.contains('active')) {
            tab.setAttribute('aria-selected', true);
            tab.classList.add('fade', 'show');
            setTimeout(() => tab.classList.add('active'), 150);
        }
    }

}
