import AutocompleteSource from './autocomplete_source'

export default class DistributorAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('distributors', {keys: ['name'], ...options})
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `<span class="text-truncate flex-fill">${selection.match}</span>`;
        if (selection.value.full_address) {
            const address = document.createElement("address");
            address.classList.add('small', 'text-muted', 'm-0', 'w-100');
            address.textContent = selection.value.full_address;
            node.classList.add('flex-wrap');
            node.append(address);
        }
    }
}
