import AutoSubmitForms from './auto-submit-form';
import ChartHelper from "./charts";

const isTouchDevice = function () {
    return 'ontouchstart' in window        // works on most browsers
        || navigator.maxTouchPoints;       // works on IE10/11 and Surface
};

const initializePage = function () {
    if (isTouchDevice()) {
        $('body').addClass('touch-enabled');
    }
    $("form[data-autosubmit='true']").each(function () {
        AutoSubmitForms.initialize(this);
    });
    $('.form-group.check_boxes input.check_all').each(function () {
        SelectAllCheckbox.initialize(this, '.form-group')
    });
    $('.chart-container[data-source]').each(function () {
        ChartHelper.load($(this));
    });

    let $fullscreenToggles = $('[data-toggle=fullscreen][data-target]');
    if (document.fullscreenEnabled === false || navigator.userAgent.match(/iPhone/i)) {
        $fullscreenToggles.hide();
    } else {
        $fullscreenToggles.off('click').on('click', function (e) {
            let isFullScreen = window.fullScreen || document.mozFullScreen || document.webkitIsFullScreen || document.fullscreenElement != null || document.fullScreenElement != null,
                element;
            e.preventDefault();
            if (isFullScreen) {
                ["exitFullscreen",
                    "cancelFullScreen",
                    "mozCancelFullScreen",
                    "webkitCanvelFullscreen",
                    "webkitCancelFullScreen",
                    "webkitExitFullscreen"].some(function (c) {
                    if (document[c]) {
                        document[c]();
                        return true;
                    }
                });
            } else {
                element = $($(this).attr('data-target')).get(0);
                if (element == null) {
                    console.error('Cannot find element to fullscreen: ' + $(this).attr('data-target'));
                    return;
                }
                ["requestFullscreen",
                    "requestFullScreen",
                    "mozRequestFullScreen",
                    "webkitRequestFullscreen",
                    "webkitRequestFullScreen",
                    "webkitEnterFullscreen"].some(function (c) {
                    if (element[c]) {
                        element[c]();
                        return true;
                    }
                });
            }
        });
    }

    $('.sidebar-toggle').click(function () {
        let sidebar = $('#sidebar, .row-offcanvas'),
            sidebarToggles = $('.sidebar-toggle');
        if (sidebar.hasClass('active')) {
            sidebar.removeClass('active');
            sidebarToggles.find('#show-sidebar').show();
            sidebarToggles.find('#hide-sidebar').hide();
        } else {
            sidebar.addClass('active');
            sidebarToggles.find('#hide-sidebar').show();
            sidebarToggles.find('#show-sidebar').hide();
        }
    });

    $('form .prevent-submit-on-enter').on('click', function (e) {
        e.preventDefault();
        return false;
    });

    $(document).on('click.disableSomeMore', 'a[data-disable-with]', function () {
        $(this).addClass('disabled');
    });
};

$(document).on('turbo:load', initializePage);