import {Controller} from '@hotwired/stimulus'
import {AniX} from "anix";

const RECORD_TARGET_SELECTOR = '[data-nested-form-target=record]';

// Connects to data-controller="nested-form"
export default class extends Controller {
    static targets = ['template', 'fields', 'record']
    static values = {
        templateChildIndex: String,
        insertionMethod: {type: String, default: 'beforeend'},
        sortable: {type: Boolean, default: false},
        sortableHandleSelector: {type: String, default: '.sortable-handle.attribute-sortable'},
        sortablePositionInputName: {type: String, default: 'position'}
    }

    initialize() {
        this.updatePositions = this.updatePositions.bind(this)
        this.newRecordCounter = 1;
    }

    connect() {
        if (!this.hasTemplateTarget) {
            throw new Error('No template target set!');
        }
        if (!this.hasFieldsTarget) {
            throw new Error('No field target set!');
        }
        if (!this.hasTemplateChildIndexValue) {
            throw new Error('No template child index value set!');
        }

        if (this.sortableValue) {
            Sortable.create(this.fieldsTarget, {
                handle: this.sortableHandleClassValue,
                onMove: (event) => {
                    return event.related.dataset.nestedFormTarget === 'record';
                },
                onEnd: (event) => {
                    this.updatePositions();
                }
            });
            this.recordTargets.forEach((recordEl) => this._updateSortButtons(recordEl));
        }
    }

    add(event) {
        event.preventDefault();

        const index = new Date().getTime() * 100 + (this.newRecordCounter++ % 100),
            node = this.templateTarget.content.cloneNode(true).firstChild;

        node.innerHTML = node.innerHTML.replace(new RegExp(this.templateChildIndexValue, "g"), index.toString())

        this.fieldsTarget.insertAdjacentElement(this.insertionMethodValue, node);
        this.afterAdd(node, index);
    }

    remove(event) {
        event.preventDefault();

        const nestedField = event.target.closest(RECORD_TARGET_SELECTOR);
        if (nestedField.dataset.newRecord === 'true') {
            nestedField.remove();
        } else {
            nestedField.dataset.deleted = true;
            nestedField.classList.add('d-none');

            const input = this._findRecordInput(nestedField, '_destroy');
            if (input) {
                input.value = '1';
            }
        }
        this.afterRemove(nestedField);
    }

    afterAdd(nestedField) {
        if (this.sortableValue) {
            this._updateSortButtons(nestedField);
            this._updateSortButtons(this._getPreviousRecordEl(nestedField));
            this._updateSortButtons(this._getNextRecordEl(nestedField));
            this.updatePositions();
        }
    }

    afterRemove(nestedField) {
        if (this.sortableValue) {
            this._updateSortButtons(this._getPreviousRecordEl(nestedField));
            this._updateSortButtons(this._getNextRecordEl(nestedField));
            this.updatePositions();
        }
    }

    updatePositions() {
        let index = 1;
        this.recordTargets.forEach((recordEl) => {
            const input = this._findRecordInput(recordEl, this.sortablePositionInputNameValue);
            if (recordEl.offsetWidth === 0 && recordEl.offsetHeight === 0) {
                // Row is deleted
                if (input != null) {
                    input.value = "";
                }
                return;
            }
            if (input != null) {
                input.value = (index++).toString();
            }

            this._updateSortButtons(recordEl);
        });
    }

    moveRecordUp(event) {
        let nestedField = event.target.closest(RECORD_TARGET_SELECTOR),
            previousRow = this._getPreviousRecordEl(nestedField);
        if (previousRow != null) {
            previousRow.insertAdjacentElement('beforebegin', nestedField);
            AniX.fromTo(nestedField, 0.5, {
                backgroundColor: '#ffc553'
            }, {
                backgroundColor: null
            });

            this.updatePositions(nestedField.parentElement);
            this._updateSortButtons(nestedField);
            this._updateSortButtons(previousRow);
        }
    }

    moveRecordDown(event) {
        let nestedField = event.target.closest(RECORD_TARGET_SELECTOR),
            nextRow = this._getNextRecordEl(nestedField);
        if (nextRow != null) {
            nextRow.insertAdjacentElement('afterend', nestedField);
            AniX.fromTo(nestedField, 0.5, {
                backgroundColor: '#ffc553'
            }, {
                backgroundColor: null
            });

            this.updatePositions(nestedField.parentElement);
            this._updateSortButtons(nestedField);
            this._updateSortButtons(nextRow);
        }
    }

    _updateSortButtons(nestedField) {
        if (nestedField) {
            let moveUpAction = this._findRecordAction(nestedField, 'moveRecordUp'),
                moveDownAction = this._findRecordAction(nestedField, 'moveRecordDown');
            if (moveUpAction) {
                moveUpAction.classList.toggle('disabled', this._getPreviousRecordEl(nestedField) == null);
            }
            if (moveDownAction) {
                moveDownAction.classList.toggle('disabled', this._getNextRecordEl(nestedField) == null);
            }
        }
    }

    _getPreviousRecordEl(record) {
        while ((record = record.previousElementSibling)) {
            // Check if this sibling is a record and if it is visible
            if (this._isVisibleRecord(record)) {
                return record;
            }
        }
        return null;
    }

    _getNextRecordEl(record) {
        while ((record = record.nextElementSibling)) {
            // Check if this sibling is a record and if it is visible
            if (this._isVisibleRecord(record)) {
                return record;
            }
        }
        return null;
    }

    _isVisibleRecord(el) {
        return el && el.dataset && el.dataset.nestedFormTarget === 'record' && el.offsetWidth > 0 && el.offsetHeight > 0
    }

    _findRecordInput(recordEl, name) {
        let input;
        recordEl.querySelectorAll(`input[name$='[${name}]']`).forEach(el => {
            // Make sure we select the input for this record
            if (input == null && el.closest(RECORD_TARGET_SELECTOR) === recordEl) {
                input = el;
            }
        });
        return input;
    }

    _findRecordAction(recordEl, name) {
        let input;
        recordEl.querySelectorAll(`[data-action*="nested-form#${name}"]`).forEach(el => {
            // Make sure we select the input for this record
            if (input == null && el.closest(RECORD_TARGET_SELECTOR) === recordEl) {
                input = el;
            }
        });
        return input;
    }
}

