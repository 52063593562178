import {Controller} from "@hotwired/stimulus"
import consumer from "../channels/consumer";

// Connects to data-controller="api-request-info"
export default class extends Controller {
    connect() {
        this.subscription = consumer.subscriptions.create(
            {
                channel: "ApiRequestUpdatesChannel",
                id: this.data.get("requestId"),
            },
            {
                received: () => {
                    this.reloadTurboFrame(this.element);
                }
            }
        );
    }

    disconnect() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    reloadTurboFrame(frame) {
        if (frame.getAttribute('src') == null) {
            frame.setAttribute('src', window.location);
        } else {
            frame.reload();
        }
    }
}
