import {Controller} from "@hotwired/stimulus"
import ChartHelper from "../charts";

// Connects to data-controller="dashboard-widgets"
export default class extends Controller {
    static targets = ['widget', 'refreshAll']

    static values = {
        autoRefreshInterval: {type: Number, default: 15}
    }

    connect() {
        super.connect();
        this._createRefreshInterval();
    }

    disconnect() {
        super.disconnect();
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }
    }

    refreshAllTargetConnected(el) {
        el.addEventListener('click', () => this.reloadAllWidgets({deferred: true}));
    }

    reloadAllWidgets(options) {
        this._setRefreshAllTargetsEnabled(false);

        if (this.refreshInterval) {
            // Prevent the refresh interval from firing
            clearInterval(this.refreshInterval);
            this._createRefreshInterval();
        }

        this.widgetTargets.forEach((el) => this.reloadWidget(el, options));

        // Throttle refresh to once every 10 seconds.
        setTimeout(() => this._setRefreshAllTargetsEnabled(true), 10000);
    }

    reloadWidget(widgetTarget, options) {
        let currentStatusContainer = widgetTarget.querySelector('.current-status-container');
        if (currentStatusContainer) {
            if (currentStatusContainer.nodeName === 'TURBO-FRAME') {
                if (currentStatusContainer.hasAttribute('complete')) {
                    let sourceUrl = currentStatusContainer.getAttribute('src');
                    if (sourceUrl && !sourceUrl.includes('disable_chart_animations=')) {
                        currentStatusContainer.setAttribute("src",
                            sourceUrl + (sourceUrl.includes('?') ? '&' : '?') + 'disable_chart_animations=t'
                        );
                    }
                    currentStatusContainer.reload();
                }
            } else {
                console.error("Current status container is not a turbo frame: ", widgetTarget);
            }
            return;
        }
        let chartContainer = widgetTarget.querySelector('.chart-container');
        if (chartContainer) {
            ChartHelper.load(chartContainer, options);
            return;
        }
        console.error("Refreshing this widget is unimplemented: ", widgetTarget);
    }

    _setRefreshAllTargetsEnabled(whether) {
        this.refreshAllTargets.forEach((el) => el.disabled = !whether);
    }

    _createRefreshInterval() {
        const minutes = this.autoRefreshIntervalValue;
        if (minutes >= 1) {
            this.refreshInterval = setInterval(
                () => this.reloadAllWidgets({deferred: true}),
                minutes * 60000
            );
        }
    }
}
