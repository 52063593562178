import Bloodhound from 'corejs-typeahead/dist/bloodhound.js';
import 'corejs-typeahead/dist/typeahead.jquery.js';

const TypeaheadHelper = {};
TypeaheadHelper.highlight = function (match, query) {
    if (!match || match.length === 0 || !match || match.length === 0) {
        return match;
    }
    $.each(query.split(/\s+/), function (index, word) {
        if (word) {
            word = word.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
            match = match.replace(new RegExp('(' + word + ')(?=[^>]*(<|$))', 'i'), function ($1, match) {
                return match ? '<strong class="highlight">' + match + '</strong>' : $1;
            });
        }
    });
    return match;
};

TypeaheadHelper.notFoundTemplate = function (message) {
    return '<div class="tt-not-found text-muted" style="padding: 0 10px;">' + message + '</div>'
};

// Export stuff to the window for now
window.Bloodhound = Bloodhound;
window.TypeaheadHelper = TypeaheadHelper;
