import {Controller} from "@hotwired/stimulus"
import Clipboard from "clipboard/dist/clipboard";
import i18n from "../i18n";

// Connects to data-controller="clipboard"
export default class extends Controller {
    static targets = ['content']

    connect() {
        if (Clipboard.isSupported()) {
            const clipboard = new Clipboard(this.element);
            clipboard.on('success', (e) => {
                // Jquery is required for this version of Bootstrap
                let $trigger = $(e.trigger);
                $trigger.tooltip({title: `✓ ${i18n.t('js.clipboard.copied')}`, trigger: 'manual'}).tooltip('show');
                setTimeout(function () {
                    $trigger.tooltip('dispose');
                }, 1000);
            });
        } else {
            this.element.remove();
        }
    }
}
