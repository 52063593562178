import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

export default class BreedAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('breeds', {keys: ['label'], ...options});
    }


    styleOptionNode(node, data) {
        if (data.value.id == null) {
            node.classList.add('d-flex', 'flex-wrap', 'align-items-center');
            node.innerHTML = `<span class="text-truncate flex-fill">${data.match}</span>`;

            const label = document.createElement("span");
            label.classList.add('small', 'badge', 'badge-pill', 'badge-primary');
            label.textContent = i18n.t('views.breeds.js.create_new');
            node.append(label);
        } else if (data.value.exists) {
            node.classList.add('checked');
        }
    }
}
