import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="automated-report-form"
export default class extends Controller {
    static targets = ["emailToggle", "httpToggle", "emailInputs", "httpInputs", "removeHeader"]

    connect() {
        // Connected
        if (this.hasEmailToggleTarget || this.hasHttpToggleTarget) {
            this._setRecipientVisibility();
        }
    }

    emailToggleTargetConnected(el) {
        el.addEventListener('input', () => {
            this._setRecipientVisibility();
        });
    }

    httpToggleTargetConnected(el) {
        el.addEventListener('input', () => {
            this._setRecipientVisibility();
        });
    }

    clearHeader(ev) {
        ev.preventDefault();
        const headerRow = ev.target.closest('[data-nested-form-target=record]')
        if (headerRow.previousElementSibling && headerRow.previousElementSibling.dataset.nestedFormTarget === 'record') {
            headerRow.remove();
        } else {
            // This is the first row (with input labels), remove the next row or clear the inputs
            const nextRow = headerRow.nextElementSibling;
            if (nextRow != null && nextRow.dataset.nestedFormTarget === 'record') {
                // Copy over input values from next row and remove it
                const inputs = headerRow.querySelectorAll('input[type=text]');
                nextRow.querySelectorAll('input[type=text]').forEach((nextSiblingInput, index) => {
                    inputs[index].value = nextSiblingInput.value;
                });
                nextRow.remove();
            } else {
                // Only 1 row is shown, just clear the inputs
                headerRow.querySelectorAll('input').forEach((input) => {
                    input.value = "";
                });
            }
        }
    }

    _setRecipientVisibility() {
        this.emailInputsTarget.classList.toggle('d-none', !this.emailToggleTarget.checked)
        this.httpInputsTarget.classList.toggle('d-none', !this.httpToggleTarget.checked)
    }
}
