import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n"
import {debounce} from "throttle-debounce";
import IMask from "imask";
import NestedFieldsHelper from "../nested-fields-helper";

const mask = IMask.createMask({mask: /^[0-9A-Za-z]{0,4}$/, maxLength: 4});
let connected = false;

// Connects to data-controller="barn-layout"
export default class extends Controller {
    static targets = ['roomSection', 'penGroupSection', 'penCount', 'submit']

    connect() {
        connected = true;
        this.updatePenCount();
    }

    disconnect() {
        connected = false;
    }

    roomSectionTargetConnected(el) {
        let previousRoom = el.closest('[data-nested-form-target="record"]')?.previousElementSibling?.querySelector('[data-barn-layout-target=roomSection]'),
            nameInput = el.querySelector('.layout_rooms_name input');
        if (previousRoom) {
            // Copy previous room name incremented by 1
            nameInput.value = this._incrementString(previousRoom.querySelector('.layout_rooms_name input').value);
        }

        const roomNameListener = (el, nameInput) => {
            let roomHeader = el.querySelector('h3'),
                roomName = nameInput.value;
            if (roomName.length) {
                roomHeader.textContent = i18n.t('activerecord.models.room', {count: 1}) + ' ' + roomName
                roomHeader.classList.remove('text-muted');
            } else {
                roomHeader.textContent = i18n.t('views.rooms.other_rooms')
                roomHeader.classList.add('text-muted');
            }
            el.querySelectorAll('input[name*="[prefix]"]').forEach(input => {
                input.setAttribute('placeholder', roomName.length && roomName.length <= 6 ? roomName + '.' : '');
            });
            el.querySelectorAll('[data-barn-layout-target="penGroupSection"]').forEach(penGroupSection => {
                this._showPenNames(penGroupSection);
            });
        }
        if (nameInput.value.length > 0) {
            roomNameListener(el, nameInput);
        }
        nameInput.addEventListener('input', () => roomNameListener(el, nameInput));

        if (connected) {
            if (nameInput.value.length) {
                let nestedInput = el.querySelector('input[name$="[prefix]"]');
                if (nestedInput) {
                    nestedInput.focus();
                }
            } else {
                nameInput.focus();
            }
            this.updatePenCount();
        }
    }

    roomSectionTargetDisconnected() {
        this.updatePenCount();
    }

    penGroupSectionTargetConnected(el) {
        let previousGroup = el.closest('[data-nested-form-target="record"]')?.previousElementSibling?.querySelector('[data-barn-layout-target=penGroupSection]');
        if (previousGroup) {
            // Get the position input to determine the indices for the inputs in the previous and the new card.
            const previousIndex = NestedFieldsHelper.determineIndex(previousGroup, 'type'),
                addedIndex = NestedFieldsHelper.determineIndex(el, 'type');
            NestedFieldsHelper.copyInputValues('pen_groups_attributes', previousGroup, previousIndex,
                el, addedIndex, (fromEl, toEl) => {
                    if (toEl.name.endsWith('[start_number]')) {
                        let startNumber = fromEl.value,
                            previousQuantity = parseInt(previousGroup.querySelector('input[name$="[quantity]"]').value);
                        for (let i = 0; i < previousQuantity; i++) {
                            startNumber = this._incrementString(startNumber);
                        }
                        toEl.value = startNumber;
                        return false;
                    }
                });
        }

        let roomWrapper = el.closest('[data-barn-layout-target=roomSection]'),
            roomName = roomWrapper.querySelector('.layout_rooms_name input').value,
            prefixInput = el.querySelector('input[name$="[prefix]"]');
        prefixInput.setAttribute('placeholder', roomName.length && roomName.length <= 6 ? roomName + '.' : '');
        el.querySelector('input[name$="[start_number]"]').addEventListener('input', e => {
            mask.resolve(e.target.value);
            e.target.value = mask.value;
        });
        el.querySelector('input[name$="[quantity]"]').addEventListener('input', _ => {
            this.updatePenCount();
        });
        el.querySelectorAll('select[name$="[type]"], input[name$="[prefix]"], input[name$="[start_number]"], input[name$="[quantity]"]').forEach(input => {
            input.addEventListener('input', debounce(50, _ => {
                this._showPenNames(el);
            }));
            input.addEventListener('change', _ => {
                this._showPenNames(el);
                this._validateRoom(roomWrapper);
            });
        });

        this._showPenNames(el);

        if (connected) {
            if (previousGroup) {
                prefixInput.focus();
            }
            this.updatePenCount();
        }
    }

    penGroupSectionTargetDisconnected(el) {
        let roomWrapper = el.closest('[data-barn-layout-target=roomSection]');
        if (roomWrapper) {
            this._validateRoom(roomWrapper);
        }
        this.updatePenCount();
    }


    updatePenCount() {
        let count = 0, input;
        if (this.hasPenGroupSectionTarget) {
            this.penGroupSectionTargets.forEach(el => {
                input = el.querySelector('input[name$="[quantity]"]');
                count += Math.max(Math.min(parseInt(input.value) || 1, 9999), 1);
            });
        }
        this.penCountTarget.textContent = count + ' ' + i18n.t('activerecord.models.pen', {count: count});
        this.submitTarget.disabled = count < 1;
    }

    _showPenNames(row) {
        let penTypeName = row.querySelector('select[name$="[type]"]').selectedOptions[0].text,
            prefix = row.querySelector('input[name$="[prefix]"]').value,
            quantity = Math.max(Math.min(parseInt(row.querySelector('input[name$="[quantity]"]').value) || 1, 9999), 1),
            number = row.querySelector('input[name$="[start_number]"]').value;
        if (prefix.length === 0 && number.length === 0 && quantity === 1) {
            row.querySelector('.example').innerHTML = '<strong>' + penTypeName + '</strong>';
            row.dataset.firstPenName = '';
            row.dataset.lastPenName = '';
        } else {
            if (prefix.length === 0) {
                prefix = row.closest('[data-barn-layout-target=roomSection]').querySelector('.layout_rooms_name input').value;
                if (prefix.length && prefix.length <= 6) {
                    prefix += '.';
                } else {
                    prefix = '';
                }
            }
            if (number.length === 0 && quantity > 1) {
                number = '1';
            }
            if (number.match(/^[0-9]+$/)) {
                number = this._pad(number, (parseInt(number) + quantity - 1).toString().length, '0')
            }
            row.querySelector('.example').innerHTML = '<strong>' + prefix + number + '</strong>';
            row.dataset.firstPenName = prefix + number;
            if (quantity > 1) {
                for (var i = 1; i < quantity; i++) {
                    number = this._incrementString(number);
                }
                row.querySelector('.example').innerHTML += ` - <strong>${prefix}${number}</strong>`;
                row.dataset.lastPenName = prefix + number;
            }
        }
    }

    _validateRoom(room) {
        let valid = true, nameLength = -1;
        room.querySelectorAll('.nested-fields').forEach(nestedField => {
            let firstPenName = nestedField.dataset.firstPenName || '', lastPenName = nestedField.dataset.lastPenName;
            if (nameLength === -1) {
                nameLength = firstPenName.length;
            } else {
                valid &= (firstPenName.length === nameLength)
            }
            if (lastPenName) {
                valid &= (lastPenName.length === nameLength);
            }
            return valid;
        });
        let warnings = room.querySelector('.pen_groups_warnings');
        if (warnings) {
            if (valid) {
                if (warnings.querySelector('.name_length')) warnings.querySelector('.name_length').remove();
                room.classList.remove('is-invalid');
            } else {
                room.classList.add('is-invalid');
                if (warnings.querySelector('.name_length').length === 0) {
                    warnings.innerHTML += '<div class="text-danger name_length"><i class="fas fa-exclamation-triangle">' + i18n.t('views.barn.layout.warning_name_length') + '</i></div>'
                }
            }
        }
    }

    _pad(str, length, c) {
        str = '' + str;
        while (str.length < length) {
            str = c + str;
        }
        return str;
    };

    _incrementString(s, pos) {
        if (!(s && s.length)) {
            return '';
        }
        if (typeof (pos) === 'undefined') {
            pos = s.length - 1
        }
        var c = s.charAt(pos);
        if (c === '9') {
            if (pos > 0) {
                return this._incrementString(s, pos - 1) + '0';
            } else {
                return '10';
            }

        } else if (c === 'Z') {
            if (pos > 0) {
                return this._incrementString(s, pos - 1) + 'A';
            } else {
                return 'AA';
            }

        } else if (c === 'z') {
            if (pos > 0) {
                return this._incrementString(s, pos - 1) + 'a';
            } else {
                return 'aa';
            }
        } else {
            return s.substring(0, pos) + String.fromCharCode(c.charCodeAt(0) + 1);
        }
    }
}