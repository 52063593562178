import AutocompleteSource from './autocomplete_source'

export default class SurveyFormChoiceAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        if (options.endpoint == null && options.fieldId == null) {
            throw "Endpoint or fieldId must be set for survey form choices"
        } else if (options.endpoint == null) {
            options.endpoint = `/survey_form_fields/${options.fieldId}/choices`
        }
        super('survey_form_choices', {keys: ['name'], ...options});
    }
}