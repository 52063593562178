import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="collapse-toggle"
export default class extends Controller {
    static targets = ["handle", "collapse"]
    static classes = ["openHandle", "closeHandle", "collapsed"]
    static values = {
        closeHandleContent: String,
        openHandleContent: String,
        isOpen: Boolean,
        disableInputsOnClose: Boolean
    }

    connect() {
        this.collapseTarget.classList.add('input-toggle-collapse')
        this.handleTarget.classList.add('input-toggle-handle')
        this._showState();

        if (this.handleTarget.nodeName === 'INPUT' && this.handleTarget.getAttribute('type') === 'checkbox') {
            this.handleTarget.addEventListener('input', () => {
                this.isOpenValue = this.handleTarget.checked
                this._showState();
            });
        }
    }

    toggle() {
        this.isOpenValue = !this.isOpenValue;
        this._showState();
    }

    async _showState(isOpened = this.isOpenValue, disableInputs = this.disableInputsOnCloseValue) {
        if (this.handleTarget.nodeName === 'INPUT' && this.handleTarget.getAttribute('type') === 'checkbox') {
            this.handleTarget.checked = isOpened
        }
        this.collapseTarget.classList.toggle('show', isOpened);
        if (disableInputs) {
            this.collapseTarget.querySelectorAll('input, select').forEach(input => input.disabled = !isOpened);
        }
        if (isOpened) {
            this.handleTarget.classList.remove(...this.openHandleClasses)
            this.handleTarget.classList.add(...this.closeHandleClasses)
            this.collapseTarget.classList.add(...this.collapsedClasses)
            if (this.hasCloseHandleContentValue) {
                this.handleTarget.innerHTML = this.closeHandleContentValue
            }
        } else {
            this.handleTarget.classList.remove(...this.closeHandleClasses)
            this.handleTarget.classList.add(...this.openHandleClasses)
            this.collapseTarget.classList.remove(...this.collapsedClasses)
            if (this.hasOpenHandleContentValue) {
                this.handleTarget.innerHTML = this.openHandleContentValue
            }
        }
    }
}
