import {Controller} from "@hotwired/stimulus"
import {debounce} from "throttle-debounce";

const textualInputTypes = ['text', 'textarea', 'search', 'number'];

// Connects to data-controller="auto-submit"
export default class extends Controller {
    static targets = ['form']
    static values = {
        debounce: {type: Number, default: 200}
    }

    connect() {
        let form = this.hasFormTarget ? this.formTarget : this.element;
        if (form == null || form.nodeName !== 'FORM') {
            throw this.hasFormTarget
                ? 'The form target must be a FORM node'
                : 'Element must be a FORM node, or the form should be marked with data-auto-submit-target="form"'
        }
        this.addInputListeners(form);

        // Use mutation observer to monitor changes to form and add event listeners to new inputs
        this.formObserver = new MutationObserver((mutationList) => this.mutationHandler(form, mutationList));
        this.formObserver.observe(form, {attributes: false, childList: true, subtree: true});
    }

    disconnect() {
        super.disconnect();
        this.formObserver?.disconnect();
    }

    addInputListeners(form) {
        form.querySelectorAll('input,select').forEach(input => {
            this.addInputListener(form, input);
        });
        form.querySelectorAll('button[type=reset]').forEach(resetButton => {
            resetButton.addEventListener('click', (e) => {
                form.reset();
                form.requestSubmit();
                e.preventDefault();
                return false;
            });
        });

        let submittedData = null;
        form.addEventListener('submit', (e) => {
            let submittingData = new URLSearchParams(new FormData(form)).toString();
            if (submittingData === submittedData) {
                e.preventDefault();
                return false;
            } else {
                submittedData = submittingData;
            }
        });
    }

    addInputListener(form, input) {
        let debounceMs = this.debounceValue, submitter = () => form.requestSubmit(),
            debouncedSubmitter = debounceMs && debounceMs > 0 ? debounce(debounceMs, submitter) : submitter;

        let name = input.getAttribute('name'), type = input.getAttribute('type');
        if (name && name.length && !input.getAttribute('data-no-autosubmit')) {
            if (textualInputTypes.includes(type)) {
                input.addEventListener('input', debounce(Math.max(debounceMs, 500), submitter));
            } else {
                // prevent adding both input and change submitting twice
                input.addEventListener('change', debouncedSubmitter);
            }
        }
    }

    mutationHandler(form, mutationList) {
        for (let mutation of mutationList) {
            if (mutation.type === 'childList' && mutation.addedNodes) {
                for (let node of mutation.addedNodes) {
                    if (node.querySelectorAll) {
                        node.querySelectorAll('input,select').forEach(input => {
                            this.addInputListener(form, input);
                        });
                    }
                }
            }
        }
    }
}
