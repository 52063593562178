import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["primary", "secondary"];

    connect() {
        this.primaryTarget.checked = this._areAllChecked(this.secondaryTargets);
        this.primaryTarget.addEventListener('change', () => {
            if (!this.dispatching) {
                let checked = this.primaryTarget.checked;
                this.secondaryTargets.forEach(cb => this._setChecked(cb, checked));
            }
        });
        this.secondaryTargets.forEach(cb => {
            cb.addEventListener('change', () => {
                if (!this.dispatching) {
                    this._setChecked(this.primaryTarget, this._areAllChecked(this.secondaryTargets));
                }
            });
        });
    }

    _areAllChecked(checkBoxes) {
        let unchecked = checkBoxes.find(el => !el.checked);
        return unchecked == null;
    };

    _setChecked(checkBox, checked) {
        if (checkBox.checked !== checked) {
            checkBox.checked = checked;

            this.dispatching = true;
            checkBox.dispatchEvent(new Event("change", { bubbles: true, cancelable: false }));
            this.dispatching = false;
        }
    }
}