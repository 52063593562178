import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="manage-pig-group-pigs"
export default class extends Controller {
    static targets = ["removeCard", "removeSubmit", "selectPig"]

    connect() {
        this.onSelectionChanged()
    }

    onSelectionChanged(ev) {
        const hasSelection = this.selectPigTargets.find(el => el.checked) != null,
            clickableTableController = this.application.getControllerForElementAndIdentifier(
                this.element.querySelector('table'), 'clickable-table'
            );
        this.removeCardTarget.classList.toggle('d-none', !hasSelection)
        if (clickableTableController) {
            if (hasSelection) {
                this.selectPigTargets.forEach(el => {
                    el.dataset.clickableTableTarget = 'primaryAction'
                });
                clickableTableController.cachePrimaryActions();
            } else {
                if (ev) {
                    ev.target.classList.remove('active');
                }
                // Don't immediately reset the clickableTarget controller
                setTimeout(() => {
                    if (this.selectPigTargets.find(el => el.checked) == null) {
                        this.selectPigTargets.forEach(el => {
                            delete el.dataset.clickableTableTarget
                        });
                        clickableTableController.cachePrimaryActions();
                    }
                }, 1000)
            }
        }
    }

}
