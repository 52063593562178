const Internal = {}, Locale = {};

Internal.listeners = [];
Internal.onLocaleChanged = function (locale) {
    if (this.listeners.length > 0) {
        this.listeners.forEach(function (listener) {
            listener.call(Locale, locale);
        });
    }
};
Internal.set = function (locale) {
    this.current = locale;
    this.onLocaleChanged(locale);
}

Locale.get = function () {
    return Internal.current;
}
Locale.set = function (locale) {
    Internal.set(locale);
}
Locale.addListener = function (listener) {
    Internal.listeners.push(listener);
    let current = Internal.current;
    if (current != null) {
        listener.call(current);
    }
}
Locale.removeListener = function (listener) {
    if (Internal.listeners != null) {
        Internal.listeners.remove(listener);
    }
}

export default Locale;