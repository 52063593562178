import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="gs1-validator"
export default class extends Controller {
    static values = {
        minLength: {type: Number, default: 13},
        maxLength: {type: Number, default: 13}
    }

    connect() {
        this.element.addEventListener("input", () => {
            if (this.element.value.length === this.maxLengthValue) {
                this._validate(this.element.value);
            } else {
                this._hideValidation();
            }
        });
        this.element.addEventListener("blur", () => {
            if (this.element.value.length === 0) {
                this._hideValidation();
            } else {
                this._validate(this.element.value);
            }
        });
    }

    _validate(data) {
        data = data.replace(/^0+/, '')
        if (data == null || data.length < this.minLengthValue || data.length > this.maxLengthValue) {
            if (this.minLengthValue === this.maxLengthValue) {
                this._showValidation(i18n.t('errors.messages.wrong_length', {count: this.minLengthValue}));
            } else if (data.length < this.minLengthValue) {
                this._showValidation(i18n.t('errors.messages.too_short', {count: this.minLengthValue}));
            } else {
                this._showValidation(i18n.t('errors.messages.too_long', {count: this.maxLengthValue}));
            }
        } else if (!data.match(/^\d+$/)) {
            this._showValidation(i18n.t('errors.messages.not_a_number'));
        } else {
            // https://www.gs1.org/services/how-calculate-check-digit-manually
            let number, sum = 0,
                checkDigitPosition = data.length - 1;
            for (let i = 0; i < checkDigitPosition; i++) {
                number = parseInt(data.charAt(i));
                sum += i % 2 === 0 ? number : number * 3;
            }
            if ((1000 - sum) % 10 === parseInt(data.charAt(checkDigitPosition))) {
                this._showValidation(null);
            } else {
                this._showValidation(i18n.t('errors.messages.gs1_check_digit_invalid'));
            }
        }
    };

    _hideValidation() {
        const group = this.element.closest('.form-group');
        let feedback = group.querySelector('.invalid-feedback');
        this.element.classList.remove('is-valid', 'is-invalid');
        feedback?.remove();
    };

    _showValidation(error) {
        const group = this.element.closest('.form-group');
        let feedback = group.querySelector('.invalid-feedback');
        if (error) {
            this.element.classList.add('is-invalid');
            this.element.classList.remove('is-valid');
            if (feedback == null) {
                feedback = document.createElement('div');
                feedback.classList.add('invalid-feedback');
                feedback.textContent = error
                group.append(feedback)
            }
        } else {
            this.element.classList.remove('is-invalid');
            this.element.classList.add('is-valid');
            feedback?.remove();
        }
    };
}
