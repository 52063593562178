import {Controller} from "@hotwired/stimulus";
import Locale from "../locale";
import moment from "moment";
import i18n from "../i18n";
import {AmpPlugin, DateTime, easepick, LockPlugin} from "@easepick/bundle";
import DateRangeFormatter from "../date_range_formatter";

function getTimeoutUntilMidnight() {
    return moment().endOf('day').add({minute: 30}).add({minute: Math.random() * 90}).diff(moment());
}

export default class extends Controller {
    static targets = ["formattedPeriod", "formattedRelativePeriod", "dayCalendar",
        "customPeriodForm", "customPeriodType", "customPeriodQuantity", "customCalendarBegin", "customCalendarEnd",
        "periodForm", "year", "quarter", "weekOfYear", "workingCycle", "workingCycleLocation"];
    static values = {
        baseUrl: String,
        currentBegin: String,
        currentEnd: String,
        intervalDuration: Object,
        intervalSize: Number,
        minDate: String,
        weekStart: Number,
        cycles: Array
    }


    initialize() {
        this.initPicker = this.initPicker.bind(this)
        this.confirm = this.confirm.bind(this)
        this.fullscreenListener = this.fullscreenListener.bind(this)
        this.startAutoRefresh = this.startAutoRefresh.bind(this)
        this.stopAutoRefresh = this.stopAutoRefresh.bind(this)
        this.refreshHandler = this.refreshHandler.bind(this)
    }

    connect() {
        if (this.hasDayCalendarTarget) {
            this.dayCalendarPicker = this.initPicker(this.dayCalendarTarget, 'day');
            if (this.hasIntervalSizeValue && this.intervalSizeValue > 1) {
                this.dayCalendarPicker.on('view', evt => {
                    const {view, date, target} = evt.detail,
                        endDate = this.dayCalendarPicker.getDate();
                    if (endDate == null || view !== 'CalendarDay') {
                        return;
                    }
                    const startDate = endDate.clone().subtract(this.intervalSizeValue - 1, 'days');
                    if (startDate.isSame(date)) {
                        target.classList.add('start', 'intermediate');
                    } else if (startDate.isBefore(date) && endDate.isAfter(date)) {
                        target.classList.add('in-range');
                    } else if (endDate.isSame(date)) {
                        target.classList.add('end');
                    }
                });
                this.dayCalendarPicker.renderAll();
            }

            this.dayCalendarPicker.on('select', event => {
                let {date} = event.detail;
                this.confirm('day', {date: moment(date.toJSDate())});
            });
        }
        if (this.hasCustomPeriodFormTarget) {
            if (this.hasCustomCalendarBeginTarget) {
                this.customCalendarBeginPicker = this.initPicker(this.customCalendarBeginTarget, 'customBegin');
            }
            if (this.hasCustomCalendarEndTarget) {
                this.customCalendarEndPicker = this.initPicker(this.customCalendarEndTarget, 'customEnd');
            }
            if (this.customCalendarBeginPicker != null && this.customCalendarEndPicker != null) {
                let viewListener = (evt) => {
                    const {view, date, target} = evt.detail,
                        startDate = this.customCalendarBeginPicker.getDate(),
                        endDate = this.customCalendarEndPicker.getDate();
                    if (startDate === endDate || view !== 'CalendarDay') {
                        return;
                    }
                    let periodType = this.customPeriodTypeTarget.value,
                        periodQuantity = parseInt(this.customPeriodQuantityTarget.value);
                    if (startDate != null && endDate != null && startDate.isBefore(date) && endDate.isAfter(date)) {
                        let showSubLimits = periodType && periodType.length && periodQuantity > (periodType === 'days' ? 2 : 0);
                        if (showSubLimits && moment(date.toJSDate()).diff(moment(startDate.toJSDate()), periodType, true) % periodQuantity === 0) {
                            target.classList.add('start', 'intermediate', 'in-range');
                        } else if (showSubLimits && moment(endDate.toJSDate()).diff(moment(date.toJSDate()), periodType, true) % periodQuantity === 0) {
                            target.classList.add('end', 'intermediate', 'in-range');
                        } else {
                            target.classList.add('in-range');
                        }
                    } else if (startDate != null && startDate.isSame(date)) {
                        if (evt.currentTarget === this.customCalendarBeginPicker.ui.container) {
                            target.classList.add('start');
                        } else {
                            target.classList.add('start', 'intermediate');
                        }
                    } else if (endDate != null && endDate.isSame(date)) {
                        if (evt.currentTarget === this.customCalendarEndPicker.ui.container) {
                            target.classList.add('end');
                        } else {
                            target.classList.add('end', 'intermediate');
                        }
                    }
                };
                this.customCalendarBeginPicker.on('view', viewListener);
                this.customCalendarEndPicker.on('view', viewListener);
                this.customCalendarBeginPicker.renderAll();
                this.customCalendarEndPicker.renderAll();

                this.customCalendarBeginPicker.on('select', e => {
                    const {date} = e.detail,
                        periodType = this.customPeriodTypeTarget.value,
                        periodQuantity = parseInt(this.customPeriodQuantityTarget.value);
                    if (date != null && periodType && periodType.length && periodQuantity > 0) {
                        let endDate = moment(date.toJSDate()).add(periodQuantity * (this.hasIntervalSizeValue ? this.intervalSizeValue : 1), periodType)
                            .subtract(1, 'day');
                        this.customCalendarEndPicker.setDate(endDate.toDate());
                        this.customCalendarEndPicker.gotoDate(endDate.toDate());
                    }
                });
                this.customCalendarEndPicker.on('select', e => {
                    const {date} = e.detail,
                        periodType = this.customPeriodTypeTarget.value,
                        periodQuantity = parseInt(this.customPeriodQuantityTarget.value);
                    if (date != null && periodType && periodType.length && periodQuantity > 0) {
                        let startDate = moment(date.toJSDate()).add(1, 'day')
                            .subtract(periodQuantity * (this.hasIntervalSizeValue ? this.intervalSizeValue : 1), periodType);
                        this.customCalendarBeginPicker.setDate(startDate.toDate());
                        this.customCalendarBeginPicker.gotoDate(startDate.toDate());
                    }
                });
            }
            if (this.hasCustomPeriodQuantityTarget && this.hasCustomPeriodTypeTarget) {
                if (this.hasIntervalDurationValue) {
                    let customPeriodType, customPeriodQuantity,
                        intervalDuration = moment.duration(this.intervalDurationValue);
                    if (!this.intervalDurationValue.hasOwnProperty('days') && this.intervalDurationValue.hasOwnProperty('months')) {
                        customPeriodType = 'months';
                        customPeriodQuantity = intervalDuration.asMonths();
                    } else {
                        customPeriodQuantity = intervalDuration.asDays();
                        if (customPeriodQuantity % 7 === 0) {
                            customPeriodType = 'weeks';
                            customPeriodQuantity = customPeriodQuantity / 7;
                        } else {
                            customPeriodType = 'days';
                        }
                    }
                    this.customPeriodTypeTarget.value = customPeriodType;
                    this.customPeriodQuantityTarget.value = customPeriodQuantity;
                }

                const updatePeriodQuantityBounds = () => {
                    let min, max;
                    switch (this.customPeriodTypeTarget.value) {
                        case 'days':
                            min = 2;
                            max = 1000;
                            break;
                        case 'weeks':
                            min = 1;
                            max = 155;
                            break;
                        case 'months':
                            min = 1;
                            max = 35;
                            break;
                    }
                    if (min && max) {
                        this.customPeriodQuantityTarget.setAttribute('min', min);
                        this.customPeriodQuantityTarget.setAttribute('max', max);
                        if (this.customPeriodQuantityTarget.value && this.customPeriodQuantityTarget.value.length) {
                            this.customPeriodQuantityTarget.value = Math.min(Math.max(parseInt(this.customPeriodQuantityTarget.value), min), max);
                        }
                    }
                };
                this.customPeriodTypeTarget.addEventListener('change', updatePeriodQuantityBounds);
                updatePeriodQuantityBounds();

                if (this.customCalendarBeginPicker != null && this.customCalendarEndPicker != null) {
                    const calendarUpdater = () => {
                        const periodType = this.customPeriodTypeTarget.value,
                            periodQuantity = parseInt(this.customPeriodQuantityTarget.value);
                        let endDate = this.customCalendarEndPicker.getDate(),
                            beginLockPlugin = this.customCalendarBeginPicker.PluginManager.getInstance('LockPlugin'),
                            endLockPlugin = this.customCalendarEndPicker.PluginManager.getInstance('LockPlugin');
                        beginLockPlugin.options.maxDate = periodType && periodType.length && periodQuantity > 0 ?
                            new DateTime(moment().startOf('day').subtract(periodQuantity * (this.hasIntervalSizeValue ? this.intervalSizeValue - 1 : 0), periodType).toDate()) : null;
                        endLockPlugin.options.maxDate = periodType && periodType.length && periodQuantity > 0 ?
                            new DateTime(moment().startOf('day').add(periodQuantity, periodType).toDate()).subtract(1, 'day') : null;
                        if (endDate != null && endLockPlugin.options.maxDate != null && endLockPlugin.options.maxDate.isBefore(endDate)) {
                            endDate = endLockPlugin.options.maxDate;
                            this.customCalendarEndPicker.setDate(endLockPlugin.options.maxDate);
                            this.customCalendarEndPicker.gotoDate(endLockPlugin.options.maxDate);
                        }
                        if (endDate != null && periodType && periodType.length && periodQuantity > 0) {
                            let startDate = moment(endDate.toJSDate()).add(1, 'day')
                                .subtract(periodQuantity * (this.hasIntervalSizeValue ? this.intervalSizeValue : 1), periodType);
                            this.customCalendarBeginPicker.setDate(startDate.toDate());
                            this.customCalendarBeginPicker.gotoDate(startDate.toDate());
                            this.customCalendarEndPicker.renderAll();
                        }

                    };
                    this.customPeriodTypeTarget.addEventListener('change', calendarUpdater);
                    this.customPeriodQuantityTarget.addEventListener('input', calendarUpdater);
                }
            }
            this.customPeriodFormTarget.addEventListener('submit', event => {
                event.preventDefault();

                const periodType = this.customPeriodTypeTarget.value,
                    periodQuantity = parseInt(this.customPeriodQuantityTarget.value);
                let end;
                if (this.customCalendarEndPicker != null) {
                    let date = this.customCalendarEndPicker.getDate();
                    if (date == null) {
                        //TODO error?
                        return;
                    }
                    end = moment(date.toJSDate());
                } else {
                    end = moment();
                }
                if (periodType && periodType.length && periodQuantity && periodQuantity > 0) {
                    this.confirm('custom', {
                        begin: end.clone().add(1, 'day').subtract(periodQuantity, periodType),
                        end: end
                    });
                } else {
                    //TODO error?
                }
            });
        }
        if (this.hasPeriodFormTarget) {
            if (this.hasYearTarget) {
                if (this.hasWeekOfYearTarget) {
                    const yearListener = () => {
                        if (parseInt(this.yearTarget.value) === moment().year()) {
                            let w;
                            for (w = moment().isoWeek(); w < this.weekOfYearTarget.options.length; w++) {
                                this.weekOfYearTarget.options[w].disabled = true;
                                this.weekOfYearTarget.options[w].classList.add('d-none');
                            }
                        } else {
                            for (let w = 0; w < this.weekOfYearTarget.options.length; w++) {
                                this.weekOfYearTarget.options[w].disabled = false;
                                this.weekOfYearTarget.options[w].classList.remove('d-none');
                            }
                        }
                        const hasWeek53 = moment().year(this.yearTarget.value).endOf('year').isoWeek() === 53;
                        this.weekOfYearTarget.options[52].disabled = !hasWeek53;
                        this.weekOfYearTarget.options[52].classList.toggle('d-none', !hasWeek53);
                        if (this.weekOfYearTarget.options[this.weekOfYearTarget.selectedIndex].disabled) {
                            for (let i = this.weekOfYearTarget.options.length - 1; i >= 0; i--) {
                                if (!this.weekOfYearTarget.options[i].disabled) {
                                    this.weekOfYearTarget.options[i].selected = true;
                                    break;
                                }
                            }
                        }
                    };
                    this.yearTarget.addEventListener('change', yearListener);
                    yearListener();
                }
                if (this.hasQuarterTarget) {
                    const yearListener = e => {
                        if (parseInt(this.yearTarget.value) === moment().year()) {
                            for (let q = moment().quarter(); q < 4; q++) {
                                this.quarterTarget.options[q].disabled = true;
                            }
                        } else {
                            for (let q = 0; q < 4; q++) {
                                this.quarterTarget.options[q].disabled = false;
                            }
                        }
                        if (this.quarterTarget.options[this.quarterTarget.selectedIndex].disabled) {
                            for (let i = this.quarterTarget.options.length - 1; i >= 0; i--) {
                                if (!this.quarterTarget.options[i].disabled) {
                                    this.quarterTarget.options[i].selected = true;
                                    break;
                                }
                            }
                        }
                    };
                    this.yearTarget.addEventListener('change', yearListener);
                }
                if (this.hasWorkingCycleTarget && this.hasCyclesValue) {
                    const updateWorkingCycles = () => {
                        const year = parseInt(this.yearTarget.value),
                            firstCycleStart = this.determineWorkingCycleStart(this.currentCycle, moment().year(year).startOf('year')),
                            yearDuration = (moment().year() <= year ? moment().add(this.currentCycle[1], 'days') : moment().year(year).endOf('year')).diff(firstCycleStart, 'days'),
                            maximumCycle = Math.floor(yearDuration / this.currentCycle[1]);

                        let option, cycleStart;
                        for (let i = 0; i < maximumCycle; i++) {
                            cycleStart = firstCycleStart.clone().add(i * this.currentCycle[1], 'days')
                            if (this.workingCycleTarget.options[i] == null) {
                                option = document.createElement("option");
                                option.setAttribute('value', (i + 1).toString());
                                this.workingCycleTarget.appendChild(option);
                            } else {
                                option = this.workingCycleTarget.options[i];
                            }
                            if (cycleStart.isSame(this.currentCycle[0], 'day')) {
                                option.textContent = `${(i + 1)} (${i18n.t('date_range.this_working_cycle')})`;
                            } else {
                                option.textContent = `${(i + 1)} (${DateRangeHelper.format(cycleStart, cycleStart.clone().add(this.currentCycle[1] - 1, 'days'))})`;
                            }
                        }
                        while (this.workingCycleTarget.options.length > maximumCycle) {
                            if (this.workingCycleTarget.options[maximumCycle].selected) {
                                this.workingCycleTarget.options[maximumCycle - 1].selected = true;
                            }
                            this.workingCycleTarget.options[maximumCycle].remove();
                        }
                    }
                    if (this.cyclesValue.length > 1 && this.hasWorkingCycleLocationTarget) {
                        const updateCurrentWorkingCycle = () => {
                            const locationId = this.workingCycleLocationTarget.value,
                                cycleIndex = this.cyclesValue.findIndex((value) => {
                                    return Array.isArray(value[2]) && value[2].includes(locationId);
                                });
                            this.currentCycle = this.cyclesValue[cycleIndex];
                            updateWorkingCycles();
                        };
                        this.workingCycleLocationTarget.addEventListener('change', updateCurrentWorkingCycle);
                        updateCurrentWorkingCycle();
                    } else if (this) {
                        this.currentCycle = this.cyclesValue[0];
                    }
                    this.yearTarget.addEventListener('change', updateWorkingCycles);
                }
                this.periodFormTarget.addEventListener('submit', event => {
                    event.preventDefault();
                    const year = parseInt(this.yearTarget.value);
                    if (this.hasWeekOfYearTarget) {
                        this.confirm('week', {year: year, week: this.weekOfYearTarget.value});
                    } else if (this.hasWorkingCycleTarget) {
                        this.confirm('working_cycle', {year: year, cycle: this.workingCycleTarget.value});
                    } else if (this.hasQuarterTarget) {
                        this.confirm('quarter', {year: year, quarter: this.quarterTarget.value});
                    } else {
                        this.confirm('year', {year: year});
                    }
                });
            }
        }
        if (this.hasFormattedPeriodTarget && this.hasCurrentBeginValue && this.hasCurrentEndValue) {
            this.formattedPeriodTarget.textContent = DateRangeFormatter.formatRange(moment(this.currentBeginValue), moment(this.currentEndValue), {weekStart: this.weekStartValue});
        }
        if (this.hasFormattedRelativePeriodTarget && this.hasCurrentBeginValue && this.hasCurrentEndValue) {
            let relativeEnd = moment(this.currentEndValue);
            if (relativeEnd.isAfter()) {
                relativeEnd = moment().endOf('day');
            }
            this.formattedRelativePeriodTarget.textContent = DateRangeFormatter.formatRelativeRange(moment(this.currentBeginValue), relativeEnd, {weekStart: this.weekStartValue});
        }

        document.addEventListener('fullscreenchange', this.fullscreenListener);
        if (document.fullscreenElement) {
            this.startAutoRefresh();
        }
    }

    disconnect() {
        document.removeEventListener('fullscreenchange', this.fullscreenListener);
        this.stopAutoRefresh();
        if (this.dayCalendarPicker != null) {
            this.dayCalendarPicker.destroy();
        }
        if (this.customCalendarBeginPicker != null) {
            this.customCalendarBeginPicker.destroy();
        }
        if (this.customCalendarEndPicker != null) {
            this.customCalendarEndPicker.destroy();
        }
    }

    initPicker(element, type) {
        let maxDate, selectedDate, calendars;
        if (type === 'day') {
            maxDate = new DateTime();
            selectedDate = this.hasCurrentEndValue ? this.currentEndValue : null;
        } else if (type === 'customBegin') {
            if (this.hasIntervalDurationValue && this.hasIntervalSizeValue) {
                let maxDateMoment = moment().startOf('day');
                for (let i = 0; i < this.intervalSizeValue - 1; i++) {
                    maxDateMoment.subtract(this.intervalDurationValue);
                }
                maxDate = new DateTime(maxDateMoment.toDate());
            }
            selectedDate = this.hasCurrentBeginValue ? this.currentBeginValue : null;
        } else if (type === 'customEnd') {
            maxDate = this.hasIntervalDurationValue ?
                new DateTime(moment().startOf('day').add(this.intervalDurationValue).toDate()).subtract(1, 'day')
                : null;
            selectedDate = this.hasCurrentEndValue ? this.currentEndValue : null;
        }
        if (type === 'day') {
            calendars = Math.max(Math.floor(element.parentElement.clientWidth / 320), 1);
        } else {
            calendars = 1;
        }
        const minDate = this.hasMinDateValue ? this.minDateValue : '2014-01-01',
            options = {
                element: element,
                css: [this.data.get("easepick-css")],
                lang: Locale.get(),
                date: selectedDate,
                firstDay: this.hasWeekStartValue ? this.weekStartValue : 1,
                calendars: calendars,
                grid: calendars,
                inline: true,
                plugins: [AmpPlugin, LockPlugin],
                AmpPlugin: {
                    dropdown: {
                        years: true, minYear: moment(minDate).year(), months: true
                    }, darkMode: false
                },
                LockPlugin: {
                    minDate: minDate, maxDate: maxDate
                }
            };
        let picker = new easepick.create(options);
        picker.ui.container.classList.add('shadow-none', 'border', 'range-plugin');
        return picker;
    }

    determineWorkingCycleStart(cycle, date) {
        const cycleDate = moment(cycle[0]),
            cycleDuration = cycle[1];
        if (typeof date === 'string') {
            date = moment(date);
        }
        let offset = date.diff(cycleDate, 'days') % cycleDuration;
        if (offset < 0) {
            offset += cycleDuration;
        }
        return date.clone().subtract(offset, 'days');
    }

    confirm(type, kwargs) {
        if (type === 'day') {
            if (kwargs.date == null) {
                console.error('date kwarg not specified');
                return;
            }
            Turbo.visit(this.baseUrlValue + '/' + kwargs.date.format('YYYY-MM-DD'));
        } else if (type === 'custom') {
            if (kwargs.begin == null) {
                console.error('begin kwarg not specified');
                return;
            }
            if (kwargs.end == null) {
                console.error('end kwarg not specified');
                return;
            }
            Turbo.visit(this.baseUrlValue + '/' + kwargs.begin.format('YYYY-MM-DD') + '--' + kwargs.end.format('YYYY-MM-DD'));
        } else {
            if (kwargs.year == null) {
                console.error('year kwarg not specified');
                return;
            }
            if (type === 'year') {
                Turbo.visit(this.baseUrlValue + '/' + kwargs.year);
            } else if (type === 'quarter') {
                if (kwargs.quarter == null) {
                    console.error('quarter kwarg not specified');
                    return;
                }
                Turbo.visit(this.baseUrlValue + '/' + kwargs.year + '/Q' + kwargs.quarter);
            } else if (type === 'week') {
                if (kwargs.week == null) {
                    console.error('week kwarg not specified');
                    return;
                }
                Turbo.visit(this.baseUrlValue + '/' + kwargs.year + '/W' + kwargs.week);
            } else if (type === 'working_cycle') {
                if (kwargs.cycle == null) {
                    console.error('cycle kwarg not specified');
                    return;
                }
                Turbo.visit(this.baseUrlValue + '/' + kwargs.year + '/C' + kwargs.cycle);
            } else {
                console.error('Unknown type: ' + type);
            }
        }
    }

    fullscreenListener() {
        if (document.fullscreenElement) {
            this.startAutoRefresh();
        } else {
            this.stopAutoRefresh();
        }
    }

    startAutoRefresh() {
        if (this.hasCurrentEndValue && moment(this.currentEndValue).isAfter(moment().startOf('day'))) {
            // Refresh every 15 minutes
            this.refreshInterval = setInterval(this.refreshHandler, 15000 * 60);
        } else {
            // Refresh around midnight
            this.refreshTimeout = setTimeout(this.refreshHandler, getTimeoutUntilMidnight());
        }
    }

    stopAutoRefresh() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
        }
        if (this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }

    refreshHandler() {
        let turboFrame = document.getElementById('report_results');
        turboFrame.src = location;
        turboFrame.reload();

        if (this.refreshTimeout != null) {
            // Refresh again around midnight tomorrow
            this.refreshTimeout = setTimeout(this.refreshHandler, getTimeoutUntilMidnight());
        }
    }
}