import AutocompleteSource from './autocomplete_source'

const createIdentifierIconElement = function (pig) {
    if (pig == null) {
        return null;
    }
    const icon = document.createElement("i");
    icon.classList.add('fa');
    if ((pig.current_code && pig.current_code.length) || (pig.current_name && pig.current_name.length)) {
        icon.classList.add('fa-tag');
    } else if (pig.breed_registry_code && pig.breed_registry_code.length) {
        icon.classList.add('fa-book');
    } else {
        icon.classList.add('fa-dot-circle');
    }
    return icon;
};

export default class AiBoarAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('ai_boars', {keys: ['short_identifier'], ...options});
    }

    async fetch(query) {
        if (this['includeOwnBoars']) {
            const results = [];
            results.push(this.getResults('ai_boar', query, this.extraParams));
            results.push(this.getResults('pig', query, Object.assign({'filter[pig_types]': 'breeding_male'}, this.extraParams)));
            return (await Promise.all(results)).flat(1);
        } else {
            return super.fetch(query);
        }
    }

    async getResults(type, query, ...extraParams) {
        const response = await fetch(this.addParameters(`/${type}s/typeahead`, query, ...extraParams));
        const results = await response.json();
        results.forEach((result) => result._type = type)
        return results;
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `<div class="text-wrap flex-fill">${selection.match}</div>`;

        const identifierIcon = createIdentifierIconElement(selection.value);
        identifierIcon.classList.add('fa-sm', 'mr-2');
        node.prepend(identifierIcon);
    }

    styleFormattedValue(node, selection) {
        const icon = createIdentifierIconElement(selection.value);
        icon.classList.add('mr-2');
        node.prepend(icon);
    }
}
