import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";
import zxcvbn from "zxcvbn"
import {debounce} from "throttle-debounce";

const PROGRESS_BG_CLASSES = ['bg-danger', 'bg-warning', 'bg-secondary', 'bg-success'];
const YEAR_IN_SECONDS = 365 * 24 * 3600;

// Connects to data-controller="authentication"
export default class extends Controller {
    static targets = ['newPasswordInput', 'confirmPasswordInput', 'currentPasswordInput',
        'updatePassword', 'passwordInputs', 'complexityProgressBar', 'complexityMessage', 'submit']
    static values = {
        weakWords: Array,
        minComplexityScore: Number,
        minPasswordLength: Number,
        equalityError: String,
        lengthError: String,
    }

    connect() {
        if (this.hasNewPasswordInputTarget) {
            if (this.hasMinPasswordLengthValue) {
                this.newPasswordInputTarget.addEventListener('input', debounce(200, this._validatePasswordLength.bind(this)));
                this.newPasswordInputTarget.addEventListener('blur', this._validatePasswordLength.bind(this));
                this._validatePasswordLength();
            }
            if (this.hasComplexityMessageTarget || this.hasComplexityProgressBarTarget) {
                this.newPasswordInputTarget.addEventListener('input', debounce(200, this.checkPasswordComplexity.bind(this)))
            }
        }
        if (this.hasNewPasswordInputTarget && this.hasConfirmPasswordInputTarget) {
            this.newPasswordInputTarget.addEventListener('input', debounce(200, this._validatePasswordEquality.bind(this)));
            this.newPasswordInputTarget.addEventListener('blur', this._validatePasswordEquality.bind(this));
            this.confirmPasswordInputTarget.addEventListener('input', debounce(200, this._validatePasswordEquality.bind(this)));
            this.confirmPasswordInputTarget.addEventListener('blur', this._validatePasswordEquality.bind(this));
            this._validatePasswordEquality();
        }
        if (this.hasUpdatePasswordTarget) {
            if (this.hasPasswordInputsTarget) {
                let toggleVisibility = () => {
                    this.passwordInputsTarget.classList.toggle('d-none', !this.updatePasswordTarget.checked);
                };
                this.updatePasswordTarget.addEventListener('change', () => {
                    toggleVisibility();
                    this._updateSubmitStatus();
                });
                toggleVisibility();
            } else {
                console.error("updatePasswordTarget was set but passwordInputsTarget was not set")
            }
        }
        if (this.hasSubmitTarget) {
            if (this.hasNewPasswordInputTarget) {
                this.newPasswordInputTarget.addEventListener('input', this._updateSubmitStatus.bind(this));
                if (this.hasConfirmPasswordInputTarget) {
                    this.confirmPasswordInputTarget.addEventListener('input', this._updateSubmitStatus.bind(this));
                }
            }
            if (this.hasCurrentPasswordInputTarget) {
                this.currentPasswordInputTarget.addEventListener('input', this._updateSubmitStatus.bind(this));
            }
            this._updateSubmitStatus();
        }
    }

    checkPasswordComplexity(ev) {
        if (ev.target.value.length > 0) {
            let weakWords, result;
            if (this.hasWeakWordsValue) {
                weakWords = this.weakWordsValue;
            } else {
                weakWords = null;
            }
            result = zxcvbn(ev.target.value, weakWords);
            if (result.score === 4 && result.crack_times_seconds.offline_fast_hashing_1e10_per_second > 10 * YEAR_IN_SECONDS) {
                this._updateProgressBar(5);
            } else {
                this._updateProgressBar(result.score);
            }
        } else {
            this._updateProgressBar(null);
        }
    }

    _updateProgressBar(score) {
        if (this.hasComplexityMessageTarget) {
            if (score == null) {
                this.complexityMessageTarget.textContent = '';
            } else {
                this.complexityMessageTarget.textContent = i18n.t(`views.registrations.js.password_complexity.${score}`);
            }
        }
        if (!this.hasComplexityProgressBarTarget) {
            return false;
        }
        if (score == null) {
            this.complexityProgressBarTarget.style.width = '0%';
            this.complexityProgressBarTarget.ariaValueNow = 0;
        } else {
            if (score === 0) {
                this.complexityProgressBarTarget.style.width = `1rem`;
            } else {
                this.complexityProgressBarTarget.style.width = `${Math.min(score, 4) * 25}%`;
            }
            this.complexityProgressBarTarget.ariaValueNow = score;
            let currentClass;
            if (score === 0 || this.hasMinComplexityScoreValue && this.minComplexityScoreValue > score) {
                currentClass = 'bg-danger';
            } else if (score < 3) {
                currentClass = 'bg-warning';
            } else if (score < 4) {
                currentClass = 'bg-secondary';
            } else {
                currentClass = 'bg-success';
            }
            this.complexityProgressBarTarget.classList.remove(...PROGRESS_BG_CLASSES.filter(c => c !== currentClass));
            this.complexityProgressBarTarget.classList.add(currentClass);
            this.complexityProgressBarTarget.classList.toggle('progress-bar-striped', score === 5);
            this.complexityProgressBarTarget.classList.toggle('progress-bar-animated', score === 5);
        }
    }

    _isPasswordLengthValid(password) {
        return password.length >= this.minPasswordLengthValue;
    }

    _validatePasswordLength() {
        let newPassword = this.newPasswordInputTarget.value;
        if (newPassword.length === 0 || this._isPasswordLengthValid(newPassword)) {
            this._removeErrors(this.newPasswordInputTarget);

        } else if (document.activeElement !== this.newPasswordInputTarget) {
            this._showError(this.newPasswordInputTarget, this.lengthErrorValue)
        }
    }

    _validatePasswordEquality() {
        let newPassword = this.newPasswordInputTarget.value,
            passwordConfirmation = this.confirmPasswordInputTarget.value,
            isConfirmationValid;
        if (newPassword.length === 0 || passwordConfirmation.length === 0) {
            isConfirmationValid = true;
        } else if (document.activeElement === this.confirmPasswordInputTarget) {
            isConfirmationValid = newPassword.startsWith(passwordConfirmation);
        } else {
            isConfirmationValid = newPassword === passwordConfirmation;
        }
        if (isConfirmationValid) {
            this._removeErrors(this.confirmPasswordInputTarget);
        } else {
            this._showError(this.confirmPasswordInputTarget, this.equalityErrorValue)
        }
    }

    _updateSubmitStatus() {
        let valid = true;
        if (!this.hasUpdatePasswordTarget || this.updatePasswordTarget.checked) {
            if (this.hasNewPasswordInputTarget) {
                if (this.hasMinPasswordLengthValue) {
                    valid &&= this._isPasswordLengthValid(this.newPasswordInputTarget.value);
                } else {
                    valid &&= this.newPasswordInputTarget.value.length >= 1;
                }
            }
            if (this.hasNewPasswordInputTarget && this.hasConfirmPasswordInputTarget) {
                valid &&= this.newPasswordInputTarget.value === this.confirmPasswordInputTarget.value;
            }
        }
        if (this.hasCurrentPasswordInputTarget) {
            // We can't really use MinPasswordLength here in case we ever decide to increase it.
            valid &&= this.currentPasswordInputTarget.value.length >= 6;
        }
        this.submitTarget.disabled = !valid;
    }

    _showError(input, error) {
        let formGroup = input.parentElement, feedback;
        while (formGroup && !formGroup.classList.contains('form-group')) {
            formGroup = input.parentElement;
        }
        if (formGroup == null) {
            console.error('cannot find form group for ', input);
            if (error) {
                console.error(error);
            }
            return;
        }
        feedback = formGroup.querySelector('.invalid-feedback');
        if (error) {
            if (feedback == null) {
                feedback = document.createElement('div');
                feedback.classList.add('invalid-feedback');
                formGroup.appendChild(feedback);
            }
            feedback.textContent = error;
            formGroup.classList.add('form-group-invalid');
            formGroup.querySelectorAll('input').forEach(input => input.classList.add('is-invalid'));
        } else {
            if (feedback != null) {
                feedback.remove();
            }
            formGroup.classList.remove('form-group-invalid');
            formGroup.querySelectorAll('input').forEach(input => input.classList.remove('is-invalid'));
        }
    }

    _removeErrors(input) {
        this._showError(input, null);
    }
}
