import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

const getModelName = function (selection) {
    const model = selection.value._type === 'location' ? 'customer_location' : selection.value._type;
    return i18n.t(`activerecord.models.${model}`, {count: 1});
}

export default class PolymorphicLocationAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('polymorphic_locations', {keys: ['name'], ...options})
    }

    async fetch(query) {
        const results = [], childParams = {shallow: true, l: 100};

        if (this.includeCustomers) {
            results.push(this.getResults('customer', query, this.extraParams));
        }
        if (this.includeLocations == null || this.includeLocations) {
            results.push(this.getResults('location', query, this.extraParams));
        }
        results.push(this.getResults('barn', query, this.extraParams, childParams));
        results.push(this.getResults('room', query, this.extraParams, childParams));
        results.push(this.getResults('pen', query, this.extraParams, childParams));

        return (await Promise.all(results)).flat(1);
    }

    async getResults(type, query, ...extraParams) {
        const endpoint = type === 'customer' ? 'admin/customer' : type;
        const response = await fetch(this.addParameters(`/${endpoint}s/typeahead`, query, ...extraParams));
        const results = await response.json();
        results.forEach((result) => result._type = type)
        return results;
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `
      <span class="text-truncate flex-fill">${selection.match}</span>
      <span class="text-muted small">
        ${getModelName(selection)}
      </span>`;
        if (selection.value.full_address) {
            const address = document.createElement("address");
            address.classList.add('small', 'text-muted', 'm-0', 'w-100');
            address.textContent = selection.value.full_address;
            node.classList.add('flex-wrap');
            node.append(address);
        }
    }

    styleFormattedValue(node, selection) {
        const info = document.createElement("span");
        info.classList.add('text-muted')
        info.textContent = getModelName(selection);
        node.prepend(" ");
        node.prepend(info);
    }

    onOptionSelected(selection, valueInput) {
        if (valueInput) {
            const typeInputId = valueInput.id.replace(/_id$/, '_type'),
                typeInput = document.getElementById(typeInputId);
            if (typeInput == null) {
                console.error("Cannot find location type input with id: ", typeInputId);
            } else if (typeInput.value !== selection.value._type) {
                typeInput.value = selection.value._type;
                typeInput.dispatchEvent(new Event('change', {'bubbles': true}));
            }
        }
        const locationId = selection.value._type === 'location'
            ? selection.value.id : (selection.value.location ? selection.value.location.id : null);
        if (locationId) {
            document.querySelectorAll('[data-select-date-range-target="workingCycleLocation"]').forEach(input => {
                if (input.querySelector('[value="' + locationId + '"]')) {
                    input.disabled = true;
                    if (input.value !== locationId) {
                        input.value = locationId
                        input.dispatchEvent(new Event('change', {'bubbles': true}));
                    }
                }
            });
        }
    }
}
