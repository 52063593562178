SelectAllCheckbox = {};
SelectAllCheckbox.areAllChecked = function ($checkBoxes) {
    return $checkBoxes.filter(":checked").length === $checkBoxes.length
};
SelectAllCheckbox.initialize = function (checkBox, parent) {
    var $checkAll, $parent, $checkBoxes;
    if (checkBox instanceof jQuery) {
        $checkAll = checkBox;
    } else {
        $checkAll = $(checkBox);
    }
    if (typeof parent === "undefined") {
        $parent = $checkAll.parent();
        if ($parent.is('label')) {
            $parent = $parent.parent();
        }
    } else if (typeof parent === "string") {
        $parent = $checkAll.closest(parent);
    } else if (parent instanceof jQuery) {
        $parent = parent;
    } else {
        $parent = $(parent);
    }

    $checkBoxes = $parent.find('input[type=checkbox]').not($checkAll);
    $checkAll.on('change', function () {
        $checkBoxes.prop('checked', this.checked);
    });
    $checkBoxes.on('change', function () {
        $checkAll.prop('checked', this.checked && SelectAllCheckbox.areAllChecked($checkBoxes));
    });
    $checkAll.prop('checked', SelectAllCheckbox.areAllChecked($checkBoxes));
};