import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

export default class LabelAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('labels', {keys: ['name'], ...options});
    }

    styleOptionNode(node, data) {
        node.innerHTML = data.value.html;
        node.querySelector('.leeo-label').classList.add('m-0');
    }
}
