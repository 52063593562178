import {Controller} from "@hotwired/stimulus"
import jstz from "jstz"

// Connects to data-controller="time-zone"
export default class extends Controller {
    connect() {
        let timeZone = jstz.determine();
        if (timeZone != null) {
            this.element.value = timeZone.name();
        }
    }
}
