import {Controller} from "@hotwired/stimulus";
import CodeMirror from "codemirror/lib/codemirror";
import {getIntrospectionQuery, buildClientSchema} from "graphql";
import gqlPrettier from 'graphql-prettier';
import {autocompletionKeyUpListener, autocompletionDocRenderer} from "../codemirror/graphql_utils"
import i18n from "../i18n";
import {getMetaValue} from "@rails/activestorage/src/helpers";

import "codemirror/addon/comment/comment";
import "codemirror/addon/dialog/dialog";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/lint/lint";

import "codemirror-graphql/hint";
import "codemirror-graphql/lint";
import "codemirror-graphql/info";
import "codemirror-graphql/jump";
import "codemirror-graphql/mode";

import "../codemirror/commands/smart_backspace"

export default class extends Controller {
    static targets = [
        "query",
        "resultWrapper",
        "results",
        "checkResult",
        "wizardForm"
    ];
    static values = {
        customerId: String,
        endpoint: {type: String, default: "/graphql"}
    };

    initialize() {
        this.getGraphqlSchema();
        this.results = CodeMirror(this.resultsTarget, {
            readOnly: true,
            mode: "application/ld+json",
        });
        this.results.setValue(`# ${i18n.t("js.graphql.results_placeholder")}`);
    }

    async getGraphqlSchema() {
        await fetch(this.endpointValue, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
            body: JSON.stringify({
                operationName: "IntrospectionQuery",
                query: getIntrospectionQuery()
            })
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error ${response.status}`);
                }
                this.queryTarget.disabled = true;
                return response.json();
            })
            .then((json) => {
                this.queryTarget.disabled = false;
                if (json.errors) {
                    this.results.setValue(`# ${i18n.t('js.graphql.schema_error')}\n\n${JSON.stringify(json, null, 2)}`);
                    return
                }
                this.clientSchema = buildClientSchema(json.data);
                this.editor = CodeMirror.fromTextArea(this.queryTarget, {
                    mode: 'graphql',
                    tabSize: 2,
                    matchBrackets: true,
                    autoCloseBrackets: true,
                    lint: {
                        schema: this.clientSchema,
                    },
                    hintOptions: {
                        schema: this.clientSchema,
                        closeOnUnfocus: false,
                        completeSingle: false
                    },
                    info: {
                        schema: this.clientSchema,
                        onClick: () => {
                        }
                    },
                    jump: {
                        schema: this.clientSchema,
                        onClick: () => {
                        }
                    },
                    extraKeys: {
                        'Ctrl-Space': () => this.editor.showHint({completeSingle: true}),
                        'Shift-Space': () => this.editor.showHint({completeSingle: true}),

                        'Cmd-Enter': () => this.fetchResult(),
                        'Ctrl-Enter': () => this.fetchResult(),

                        'Cmd-I': () => this._prettify(),
                        'Ctrl-I': () => this._prettify(),

                        "Backspace": "smartBackspace"
                    }
                });

                this.editor.on('change', (cm) => cm.execCommand('performLint'));
                this.editor.on('hasCompletion', autocompletionDocRenderer);
                this.editor.on('keyup', autocompletionKeyUpListener);
            })
            .catch((error) => {
                this.results.setValue(`# ${i18n.t('js.graphql.schema_error')}\n#\n# ${error.message}\n#`);
            });
    }

    connect() {
    }

    disconnect() {
        super.disconnect();
        if (this.editor) {
            this.editor.toTextArea();
            this.editor = null;
        }
    }

    fetchResult() {
        this._showProgressView();
        this._setConfirmationEnabled(false);

        this.checkResultTarget.classList.add("disabled");
        this.checkResultTarget.disabled = true;


        let payload = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
            body: JSON.stringify({
                preview: true,
                customer_id: this.customerIdValue,
                query: (this.editor ? this.editor.getValue() : this.queryTarget.value)
            }),
        };

        fetch(this.endpointValue, payload)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                this._handleResponse(data);
            })
            .catch((error) => {
                this._hideProgressView();
                this.results.setValue(`# ${i18n.t('js.graphql.query_error')}\n#\n# ${error.message}\n#`);
                this.checkResultTarget.disabled = false;
                this.checkResultTarget.classList.remove("disabled");
            });
    }

    _handleResponse(data) {
        // If the data contains errors, show only the errors
        if (data.errors) {
            delete data.data;
        }
        let responseJson = JSON.stringify(data, null, 2);
        if (data.errors) {
            responseJson = `# ${i18n.t('js.graphql.query_error')}\n\n${responseJson}`
        }
        this.results.setValue(responseJson);
        if (data.errors) {
            this.resultsTarget.classList.add('has-error');
        } else {
            this.resultsTarget.classList.remove('has-error');
        }
        this._setConfirmationEnabled(data.errors == null);
        this._hideProgressView();
        this.checkResultTarget.disabled = false;
        this.checkResultTarget.classList.remove("disabled");
    }

    _showProgressView() {
        let element = this.resultWrapperTarget.querySelector('.graphql-results-progress');
        if (!element) {
            element = document.createElement("section");
            element.className = "graphql-results-progress"
            const spinner = document.createElement("section");
            spinner.className = "spinner-border text-primary";
            spinner.setAttribute("role", "status")
            element.append(spinner);
            element.append(`${i18n.t('js.graphql.querying_results')}…`);
            this.resultWrapperTarget.append(element);
        }
        element.classList.add('active');
    }

    _hideProgressView() {
        let element = this.resultWrapperTarget.querySelector('.graphql-results-progress');
        if (element) {
            element.classList.remove("active");
        }
    }

    _setConfirmationEnabled(whether) {
        if (!this.hasWizardFormTarget) {
            return
        }

        const disabled = !whether,
            submitButton = this.wizardFormTarget.querySelector('[data-export-wizard-target=goToNextStep]');
        submitButton.disabled = disabled;
        submitButton.classList.toggle('disabled', disabled);
    }

    _prettify() {
        if (!this.editor) {
            return;
        }
        try {
            const currentValue = this.editor.getValue(),
                prettyValue = gqlPrettier(currentValue);
            if (currentValue !== prettyValue) {
                this.editor.setValue(prettyValue);
            }
        } catch (ignored) {
        }
    }
}
