import Locale from "./locale";
import moment from 'moment';

/*

Deprecated, use `DateRangeFormatter` instead;

 */

const DateRangeHelper = {};
DateRangeHelper.formatRelative = function (start, end, localization, forceRelativeFormat) {
    if (!localization) {
        return DateRangeHelper.format(start, end);
    }

    if (start.isSame(moment().startOf('year'), 'day') && (end.isSame(moment(), 'day') || end.isSame(moment().endOf('year'), 'day')) && localization.thisYear) {
        return localization.thisYear;
    } else if (start.isSame(moment().startOf('month'), 'day') && (end.isSame(moment(), 'day') || end.isSame(moment().endOf('month'), 'day')) && localization.thisMonth) {
        return localization.thisMonth;
    } else if (start.isSame(moment().subtract(1, 'year').startOf('year'), 'day') && end.isSame(moment().subtract(1, 'year').endOf('year'), 'day') && localization.lastYear) {
        return localization.lastYear;
    } else if (start.isSame(moment().subtract(1, 'month').startOf('month'), 'day') && end.isSame(moment().subtract(1, 'month').endOf('month'), 'day') && localization.lastMonth) {
        return localization.lastMonth;
    }

    if (!end.isSame(moment(), 'day') && !forceRelativeFormat) {
        return DateRangeHelper.format(start, end);
    }
    let totalHours = Math.round(end.clone().endOf('day').diff(start.clone().startOf('day'), 'hour', true));
    if (totalHours <= 48) {
        // Grouped by hour
        return String.format(localization.pastTimeFormat, {time: new Intl.NumberFormat(Locale.get()).format(totalHours) + ' ' + String.pluralize(totalHours, localization.hours)});
    }

    let years, months = end.diff(start, 'month'),
        days = end.diff(start.clone().add(months, 'month'), 'day'),
        totalDays = end.diff(start, 'day');
    years = Math.floor(months / 12);
    months = months % 12;

    if (years === 1 && months === 0 && days === 0) {
        return String.format(localization.pastTimeFormat, {time: String.pluralize(1, localization.years)});
    }
    if (years === 0 && months === 1 && days === 0) {
        return String.format(localization.pastTimeFormat, {time: String.pluralize(1, localization.months)});
    }
    if (days !== 0 && totalDays > 7 && totalDays % 7 === 0) {
        return String.format(localization.pastTimeFormat, {time: new Intl.NumberFormat(Locale.get()).format(totalDays / 7) + ' ' + String.pluralize(totalDays / 7, localization.weeks)});
    }

    let output = '';
    if (years !== 0) {
        output += new Intl.NumberFormat(Locale.get()).format(years) + ' ' + String.pluralize(years, localization.years);
    }
    if (months !== 0) {
        if (output.length) {
            output += ' ';
        }
        output += new Intl.NumberFormat(Locale.get()).format(months) + ' ' + String.pluralize(months, localization.months);
    }
    if (days !== 0) {
        if (output.length) {
            output += ' ';
        }
        output += new Intl.NumberFormat(Locale.get()).format(days) + ' ' + String.pluralize(days, localization.days);
    }
    return String.format(localization.pastTimeFormat, {time: output});
};

DateRangeHelper.format = function (start, end) {
    if (start.isSame(end, 'day')) {
        return start.format('LL');
    } else if (start.isSame(start.clone().startOf('month'), 'day') && end.isSame(start.clone().endOf('month'), 'day')) {
        return start.format('MMMM YYYY');
    } else if (start.isSame(start.clone().startOf('quarter'), 'day') && end.isSame(start.clone().endOf('quarter'), 'day')) {
        return start.format('[Q]Q YYYY');
    } else if (start.isSame(start.clone().startOf('year'), 'day') && end.isSame(start.clone().endOf('year'), 'day')) {
        return start.format('YYYY');
    } else if (start.isSame(start.clone().startOf('year'), 'day') && end.isSame(end.clone().endOf('year'), 'day')) {
        return start.format('YYYY') + " - " + end.format('YYYY');
    } else if (start.isSame(start.clone().startOf('month'), 'day') && end.isSame(end.clone().endOf('month'), 'day')) {
        let label;
        if (start.isSame(end, 'year')) {
            label = start.format('MMMM')
        } else {
            label = start.format('MMMM YYYY')
        }
        return label + " - " + end.format('MMMM YYYY');
    } else {
        let label = start.format('LL');
        if (start.isSame(end, 'year')) {
            label = label.replace(new RegExp('(, )?' + start.format('YYYY') + '(, )?'), '');
        }
        return label + " – " + end.format('LL');
    }
};

module.exports = DateRangeHelper;