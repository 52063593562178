import {Controller} from "@hotwired/stimulus"
import I18n from "../i18n";

export default class extends Controller {
    static targets = ['destroy']

    destroyTargetConnected(el) {
        if (el.disabled) {
            setTimeout(() => el.disabled = false, 50)
        }
    }

    confirmArchive(evt) {
        this._confirmAction(evt, I18n.t('model_archive_confirmation'));
    }

    confirmDearchive(evt) {
        this._confirmAction(evt, I18n.t('model_dearchive_confirmation'));
    }

    confirmDestroy(evt) {
        this._confirmAction(evt, I18n.t('model_destroy_confirmation_html'), 'btn-danger');
    }

    _confirmAction(evt, message, buttonClassName, callback) {
        const button = evt.currentTarget ? evt.currentTarget : evt.target;
        if (button.getAttribute('data-is-confirmed')) {
            return;
        }
        evt.preventDefault();
        bootbox.confirm({
            message: message,
            buttons: {
                confirm: {
                    label: I18n.t('yay'),
                    className: buttonClassName ? buttonClassName : 'btn-primary'
                },
                cancel: {
                    label: I18n.t('nay'),
                    className: 'btn-secondary'
                }
            },
            callback: (result) => {
                if (callback) {
                    callback(button, result);
                }
                if (result) {
                    button.setAttribute('data-is-confirmed', true);
                    button.click();
                }
            }
        });
    }

}
