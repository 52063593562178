import {Controller} from "@hotwired/stimulus";
import Locale from "../locale";
import moment from "moment";
import i18n from "../i18n";
import {AmpPlugin, DateTime, easepick, LockPlugin, RangePlugin} from "@easepick/bundle";
import DateRangeFormatter from "../date_range_formatter";

export default class extends Controller {
    static targets = ["workingCycleLocation", "recentPeriodQuantity", "recentPeriodType",
        "recentPeriodExcludeCurrent", "recentPeriodExcludeCurrentLabel", "recentPeriodValue", "confirmRecentPeriod",
        "comingPeriodQuantity", "comingPeriodType", "comingPeriodValue", "confirmComingPeriod",
        "easepickInput", "easepickValue", "confirmPicker"];
    static values = {
        id: String,
        weekStart: Number,
        minDate: String,
        maxDate: {type: String, default: "today"},
        cycles: Array
    }


    initialize() {
        this.updateRecentPeriodValue = this.updateRecentPeriodValue.bind(this)
        this.updateRecentPeriodExcludeLabel = this.updateRecentPeriodExcludeLabel.bind(this)
        this.updateComingPeriodValue = this.updateComingPeriodValue.bind(this)
        this.confirm = this.confirm.bind(this)
    }

    connect() {
        if (this.hasCyclesValue && this.cyclesValue.length) {
            if (this.hasWorkingCycleLocationTarget) {
                const determineCurrentWorkingCycle = () => {
                    const locationId = this.workingCycleLocationTarget.value,
                        cycleIndex = this.cyclesValue.findIndex((value) => {
                            return Array.isArray(value[2]) && value[2].includes(locationId);
                        });
                    this.currentCycle = this.cyclesValue[cycleIndex];
                    this.element.querySelectorAll('[data-cycle-index]').forEach(el => {
                        if (el.getAttribute('data-cycle-index') === cycleIndex.toString()) {
                            el.style.display = null;
                        } else {
                            el.style.display = 'none';
                        }
                    });
                    if (this.hasRecentPeriodQuantityTarget) {
                        this.updateRecentPeriodValue();
                    }
                    if (this.hasComingPeriodQuantityTarget) {
                        this.updateComingPeriodValue();
                    }
                    if (this.picker != null) {
                        this.picker.renderAll();
                    }
                };
                this.workingCycleLocationTarget.addEventListener('change', determineCurrentWorkingCycle);
                determineCurrentWorkingCycle();
            } else {
                this.currentCycle = this.cyclesValue[0];
            }
        }
        if (this.hasRecentPeriodQuantityTarget && this.hasRecentPeriodTypeTarget && this.hasRecentPeriodValueTarget) {
            this.recentPeriodQuantityTarget.addEventListener('change', this.updateRecentPeriodValue);
            this.recentPeriodQuantityTarget.addEventListener('input', this.updateRecentPeriodValue);
            this.recentPeriodQuantityTarget.addEventListener('keydown', e => {
                if (e.keyCode === 13) {
                    // Return
                    Rails.fire(this.confirmRecentPeriodTarget, 'click');
                    e.preventDefault();
                    return false;
                }
            });
            this.recentPeriodTypeTarget.addEventListener('change', () => {
                this.updateRecentPeriodValue();
                if (this.hasRecentPeriodExcludeCurrentLabelTarget) {
                    this.updateRecentPeriodExcludeLabel();
                }
            });
            if (this.hasRecentPeriodExcludeCurrentTarget) {
                this.recentPeriodExcludeCurrentTarget.addEventListener('change', this.updateRecentPeriodValue);
            }
            this.updateRecentPeriodValue();
            if (this.hasRecentPeriodExcludeCurrentLabelTarget) {
                this.updateRecentPeriodExcludeLabel();
            }
        }
        if (this.hasComingPeriodQuantityTarget && this.hasComingPeriodTypeTarget && this.hasComingPeriodValueTarget) {
            this.comingPeriodQuantityTarget.addEventListener('change', this.updateComingPeriodValue);
            this.comingPeriodQuantityTarget.addEventListener('input', this.updateComingPeriodValue);
            this.comingPeriodQuantityTarget.addEventListener('keydown', e => {
                if (e.keyCode === 13) {
                    // Return
                    Rails.fire(this.confirmComingPeriodTarget, 'click');
                    e.preventDefault();
                    return false;
                }
            });
            this.comingPeriodTypeTarget.addEventListener('change', () => {
                this.updateComingPeriodValue();
                if (this.hasComingPeriodExcludeCurrentLabelTarget) {
                    this.updateComingPeriodExcludeLabel();
                }
            });
            if (this.hasComingPeriodExcludeCurrentTarget) {
                this.comingPeriodExcludeCurrentTarget.addEventListener('change', this.updateComingPeriodValue);
            }
            this.updateComingPeriodValue();
            if (this.hasComingPeriodExcludeCurrentLabelTarget) {
                this.updateComingPeriodExcludeLabel();
            }
        }
        if (this.hasEasepickInputTarget) {
            const minDate = this.hasMinDateValue ? this.minDateValue : '2014-01-01',
                maxDate = this.maxDateValue.length > 0 ? (this.maxDateValue === 'today' ? new DateTime() : new DateTime(this.maxDateValue)) : null,
                options = {
                    element: this.easepickInputTarget,
                    css: [this.data.get("easepick-css")],
                    lang: Locale.get(),
                    firstDay: this.hasWeekStartValue ? this.weekStartValue : 1,
                    calendars: 4,
                    grid: 2,
                    inline: true,
                    plugins: [RangePlugin, AmpPlugin, LockPlugin],
                    AmpPlugin: {
                        dropdown: {
                            years: true,
                            minYear: moment(minDate).year(),
                        },
                        darkMode: false
                    },
                    RangePlugin: {
                        tooltip: true,
                        locale: i18n.t('date_range.days')
                    },
                    LockPlugin: {
                        minDate: minDate,
                        maxDate: maxDate,
                        maxDays: 500
                    }
                };
            this.picker = new easepick.create(options);
            if (this.hasEasepickValueTarget) {
                const listener = e => {
                    let {start, end} = e.detail;
                    if (start == null) {
                        this.easepickValueTarget.textContent = "";
                    } else if (end == null) {
                        this.easepickValueTarget.textContent = DateRangeFormatter.formatRelative(moment(start)) + ' – …';
                    } else {
                        this.easepickValueTarget.textContent = DateRangeFormatter.formatRelativeRange(moment(start), moment(end), {weekStart: this.weekStartValue});
                    }
                    this.confirmPickerTarget.disabled = start == null || end == null;
                    if (start != null && end != null) {
                        this.confirmPickerTarget.scrollIntoView({behavior: "smooth"});
                        this.confirmPickerTarget.addEventListener('click', () => {
                            this.confirm(start, end);
                        });
                    }
                };
                this.picker.on('preselect', listener);
                this.picker.on('select', listener);
            }

            // Make sure we don't show a bunch of disabled months.
            let firstDate = moment(maxDate.toJSDate()).startOf('month').subtract(3, 'months');
            if (firstDate.isBefore()) {
                this.picker.gotoDate(new DateTime(firstDate.toDate()));
            }
            this.picker.on('render', (e) => {
                e.target.classList.add('shadow-sm');
            });
            if (this.currentCycle != null) {
                this.picker.on('view', (evt) => {
                    const {view, date, target} = evt.detail,
                        cycleDate = this.currentCycle == null ? null : new DateTime(this.currentCycle[0]),
                        cycleDuration = this.currentCycle == null ? null : this.currentCycle[1];

                    if (cycleDate != null && cycleDuration != null && view === 'CalendarDay' &&
                        date.diff(cycleDate, 'days') % cycleDuration === 0) {
                        target.classList.add('working-cycle-start');
                    }
                });
            }
        }

        this.element.querySelectorAll('[data-fixed-period-begin][data-fixed-period-end]').forEach(el => {
            const begin = moment(el.getAttribute('data-fixed-period-begin')),
                end = moment(el.getAttribute('data-fixed-period-end'));
            if (begin.isValid() && end.isValid()) {
                el.addEventListener('click', () => {
                    this.confirm(begin, end);
                });
                el.getElementsByClassName('formatted_value')[0].textContent = DateRangeFormatter.formatRange(begin, end, {weekStart: this.weekStartValue});
            }
        });

        const beginValue = moment(document.getElementById('selected-period-begin-' + this.idValue).getAttribute('value')),
            endValue = moment(document.getElementById('selected-period-end-' + this.idValue).getAttribute('value')),
            valueEl = document.getElementById('selected-period-value-' + this.idValue);
        if (beginValue.isValid() && endValue.isValid()) {
            valueEl.textContent = DateRangeFormatter.formatRelativeRange(beginValue, endValue, {weekStart: this.weekStartValue});
        }
    }

    disconnect() {
        if (this.picker != null) {
            this.picker.destroy();
        }
    }

    confirm(start, end) {
        const valueEl = document.getElementById('selected-period-value-' + this.idValue),
            startEl = document.getElementById('selected-period-begin-' + this.idValue),
            endEl = document.getElementById('selected-period-end-' + this.idValue),
            changeEvent = document.createEvent('HTMLEvents');

        startEl.setAttribute('value', start.format('YYYY-MM-DD'));
        endEl.setAttribute('value', end.format('YYYY-MM-DD'));

        changeEvent.initEvent('change', true, false);
        startEl.dispatchEvent(changeEvent);
        endEl.dispatchEvent(changeEvent);

        valueEl.textContent = DateRangeFormatter.formatRelativeRange(moment(start), moment(end), {weekStart: this.weekStartValue});
        $('#select-period-' + this.idValue).modal('hide');
    }

    updateRecentPeriodValue() {
        const type = this.recentPeriodTypeTarget.value,
            excludeCurrent = this.hasRecentPeriodExcludeCurrentTarget && this.recentPeriodExcludeCurrentTarget.checked;
        let qty = parseInt(this.recentPeriodQuantityTarget.value), start, end;
        if (qty > 0) {
            if (type === 'working_cycles') {
                if (this.currentCycle != null) {
                    let cycleDuration = this.currentCycle[1],
                        daysAgo = moment().diff(moment(this.currentCycle[0]), 'days') % cycleDuration;
                    end = moment().add(cycleDuration - daysAgo - 1, 'days').endOf('day')
                } else {
                    end = null;
                }
            } else if (type === 'weeks' && this.hasWeekStartValue) {
                end = moment().isoWeekday((this.weekStartValue + 6) % 7).endOf('day');
                if (end.isBefore()) {
                    end.add(1, 'week');
                }
            } else {
                end = moment().endOf(type);
            }

            if (end) {
                if (excludeCurrent) {
                    if (type === 'working_cycles') {
                        end = end.subtract(this.currentCycle[1], 'days')
                    } else {
                        end = end.subtract(1, type)
                    }
                }

                if (type === 'working_cycles') {
                    start = end.clone().subtract(qty * this.currentCycle[1], 'days');
                } else {
                    start = end.clone().subtract(qty, type);
                }
                start = start.add(1, 'day').startOf('day');

                if (end.isAfter()) {
                    end = moment();
                }
            }
        }
        if (start != null && end != null) {
            this.recentPeriodValueTarget.textContent = DateRangeFormatter.formatRelativeRange(start, end, {weekStart: this.weekStartValue});
            this.confirmRecentPeriodTarget.disabled = false;
            this.confirmRecentPeriodTarget.addEventListener('click', () => {
                this.confirm(start, end);
            });
        } else {
            this.recentPeriodValueTarget.textContent = '';
            this.confirmRecentPeriodTarget.disabled = true;
        }
    }

    updateRecentPeriodExcludeLabel() {
        const type = this.recentPeriodTypeTarget.value,
            firstWeekDay = this.hasWeekStartValue ? this.weekStartValue : 1;
        let label;
        if (type === 'weeks') {
            label = i18n.t(`views.select_date_range.js.exclude_current.${type}`, {last_day: moment.weekdays((firstWeekDay + 6) % 7)});
        } else {
            label = i18n.t(`views.select_date_range.js.exclude_current.${type}`, {defaults: [{scope: "views.select_date_range.js.exclude_current.generic"}]});
        }
        this.recentPeriodExcludeCurrentLabelTarget.textContent = label;
    }

    updateComingPeriodValue() {
        const type = this.comingPeriodTypeTarget.value;
        let qty = parseInt(this.comingPeriodQuantityTarget.value), start, end;
        if (qty > 0) {
            if (type === 'working_cycles') {
                if (this.currentCycle != null) {
                    let cycleDuration = this.currentCycle[1],
                        daysAgo = moment().diff(moment(this.currentCycle[0]), 'days') % cycleDuration;
                    start = moment().subtract(daysAgo + 1, 'days').startOf('day');
                    end = start.clone().add(cycleDuration * qty, 'days').endOf('day')
                }
            } else if (type === 'weeks' && this.hasWeekStartValue) {
                start = moment().isoWeekday((this.weekStartValue + 6) % 7).endOf('day');
                if (start.isAfter()) {
                    start.subtract(1, 'week');
                }
                end = start.clone().add(qty, type).endOf('day')
            } else {
                start = moment().startOf(type);
                end = start.clone().add(qty, type).endOf(type)
            }
            if (start.isBefore()) {
                start = moment().startOf('day');
            }
        }
        if (start != null && end != null) {
            this.comingPeriodValueTarget.textContent = DateRangeFormatter.formatRelativeRange(start, end, {weekStart: this.weekStartValue});
            this.confirmComingPeriodTarget.disabled = false;
            this.confirmComingPeriodTarget.addEventListener('click', () => {
                this.confirm(start, end);
            });
        } else {
            this.comingPeriodValueTarget.textContent = '';
            this.confirmComingPeriodTarget.disabled = true;
        }
    }
}