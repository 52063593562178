import {I18n} from "i18n-js";
import translations from './locales/index'
import Locale from "./locale";

const i18n = new I18n(translations);
i18n.enableFallback = process.env.NODE_ENV !== 'development';
i18n.defaultLocale = 'en';

Locale.addListener(function (locale) {
    if (locale === 'zh-Hans') {
        i18n.locale = 'zh-CN';
    } else if (locale === 'zh-Hant') {
        i18n.locale = 'zh-TW';
    } else {
        i18n.locale = locale;
    }
});

export default i18n;