class AutocompleteSource {
    constructor(type, options) {
        this.type = type;
        if (options) {
            Object.assign(this, options);
        }
    }

    async fetch(query) {
        const url = this.getProperty('endpoint', () => `/${this.type}/typeahead`);
        const response = await fetch(this.addParameters(url, query, this.extraParams));
        return await response.json();
    }

    addParameters(url, query, ...extraParams) {
        const name = this.getProperty('queryParameter', 'q'),
            filterInputs = this.getProperty('filterInputs'),
            queryParam = new URLSearchParams();
        queryParam.set(name, query);
        url = `${url}${url.includes('?') ? '&' : '?'}${queryParam.toString()}`
        if (filterInputs) {
            // {"filterInputs":{"#input_selector":"param_name"}}
            const filterParams = new URLSearchParams();
            let inputSelector, name, filterInput, filterValue;
            for ([inputSelector, name] of Object.entries(filterInputs)) {
                filterInput = document.querySelector(inputSelector);
                if (filterInput) {
                    filterValue = filterInput.getAttribute('value');
                    if (filterValue && filterValue.length) {
                        filterParams.set(name, filterValue);
                    }
                } else {
                    console.error(`cannot find input for '${inputSelector}'`)
                }
            }
            if (filterParams.keys().next()) {
                url += '&' + filterParams;
            }
        }
        extraParams.forEach(params => {
            if (typeof params !== "string") {
                params = new URLSearchParams(params).toString();
            }
            url += '&' + params;
        });
        return url;
    }

    getProperty(name, defaultValue) {
        const value = this.hasOwnProperty(name) ? this[name] : defaultValue;
        if (typeof value === 'function') {
            return value();
        } else {
            return value;
        }
    }

    styleOptionNode(node, data) {
    }

    styleFormattedValue(node, selection) {
    }
}

export default AutocompleteSource