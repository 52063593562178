const NestedFieldsHelper = {};
const matcherCache = new Map();

NestedFieldsHelper.determineIndex = function (nestedFields, inputAttributeName, indexMatcher) {
    const input = nestedFields.querySelector(`[name$="[${inputAttributeName}]"]`);
    if (input == null) {
        throw new Error(`Cannot find input with 'name $= [${inputAttributeName}]' in the given element`);
    }
    if (!indexMatcher) {
        indexMatcher = matcherCache.get(inputAttributeName);
        if (!indexMatcher) {
            indexMatcher = RegExp(`_(\\d+)_${inputAttributeName.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}$`)
            matcherCache.set(inputAttributeName, indexMatcher);
        }
    }
    return indexMatcher.exec(input.id)?.at(1);
}

NestedFieldsHelper.copyInputValues = function (attributesName, from, fromIndex, to, toIndex, filter) {
    const idMatcher = RegExp(`_${attributesName}_${fromIndex}_.+$`),
        nameMatcher = RegExp(`[${attributesName}][${fromIndex}].+$`);
    from.querySelectorAll('input, select, textarea').forEach((fromEl) => {
        let toEl;
        if (fromEl.id) {
            let idMatch = idMatcher.exec(fromEl.id)?.at(0);
            if (idMatch) {
                toEl = to.querySelector(`${fromEl.nodeName}[id$=${idMatch.replace(`_${attributesName}_${fromIndex}_`, `_${attributesName}_${toIndex}_`)}]`);
            }
        } else if (fromEl.name) {
            let nameMatch = nameMatcher.exec(fromEl.name)?.at(0);
            if (nameMatch) {
                toEl = to.querySelector(`${fromEl.nodeName}[name$="${nameMatch.replace(`[${attributesName}][${fromIndex}]`, `[${attributesName}][${toIndex}]`)}"]`);
            }
        }
        if (toEl && (filter == null || filter(fromEl, toEl) !== false)) {
            if (fromEl.getAttribute('type') === 'checkbox') {
                toEl.checked = fromEl.checked;
            } else {
                toEl.value = fromEl.value;

                if (toEl.value.length && toEl.getAttribute('data-autocomplete-select-target') === 'valueInput') {
                    // Also copy over the formatted value of the autocomplete select input, and show the value instead
                    // of the search view.
                    const fromWrapper = fromEl.closest('[data-controller=autocomplete-select]'),
                        fromFormattedValue = fromWrapper.querySelector('[data-autocomplete-select-target="formattedValue"]'),
                        toWrapper = toEl.closest('[data-controller=autocomplete-select]'),
                        toFormattedValue = toWrapper.querySelector('[data-autocomplete-select-target="formattedValue"]');
                    toFormattedValue.innerHTML = fromFormattedValue.innerHTML
                    toWrapper.querySelector('[data-autocomplete-select-target="formattedValueWrapper"]').classList.remove('d-none')
                    toWrapper.querySelector('[data-autocomplete-select-target="searchWrapper"]').classList.add('d-none')
                }
            }
        }
    });
}

export default NestedFieldsHelper
