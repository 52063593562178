import AutocompleteSource from './autocomplete_source'

export default class EnumValueAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('enum_values', {keys: ['name'],...options});
    }

    styleOptionNode(node, data) {
        if (data.value && data.value.exists) {
            node.classList.add('checked');
        }
    }
}
