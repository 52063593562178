if (!String.format) {
    String.format = function (format) {
        var args = Array.prototype.slice.call(arguments, 1);
        if (args.length === 1 && (typeof args[0] === "object")) {
            args = args[0];
            return format.replace(/%{([a-zA-Z1-9]+)}/g, function (match, name) {
                if (typeof args[name] == 'undefined') {
                    throw "illegal argument name: " + name;
                }
                return args[name];
            });
        }
        return format.replace(/{(\d+)}/g, function (match, number) {
            if (typeof args[number] == 'undefined') {
                throw "illegal argument index: " + number;
            }
            return args[number];
        });
    };
}
if (!String.pluralize) {
    String.pluralize = function (count, plurals, params) {
        var result;
        if ($.type(plurals) === "string") {
            result = plurals;
        } else if (count === 1 && plurals.one) {
            result = plurals.one;
        } else {
            result = plurals.other;
        }
        if (params == null) {
            params = {count: count};
        } else {
            params['count'] = count;
        }
        return String.format(result, params);
    }
}

if (!String.escapeRegex) {
    String.escapeRegex = function (value) {
        return value ? value.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : value;
    }
}