import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="separated-text-fields"
export default class extends Controller {
    static targets = ['fullValue', 'section']
    static values = {
        autoSubmit: Boolean,
        pattern: String
    }

    connect() {
        this.validationPattern = this.hasPatternValue ? new RegExp(this.patternValue) : null;
    }

    sectionTargetConnected(el) {
        el.addEventListener('keydown', this._onKeyDown.bind(this));
        el.addEventListener('keyup', this._onKeyUp.bind(this));
        el.addEventListener('input', this._onInput.bind(this));
        el.addEventListener('paste', this._onPaste.bind(this));
        el.addEventListener('focus', this._onFocus.bind(this));
        el.addEventListener('change', this._onChange.bind(this));
    }

    disconnect() {
        if (this.autosubmitTimeout) {
            clearTimeout(this.autosubmitTimeout);
            this.autosubmitTimeout = null;
        }
    }

    _onKeyDown(e) {
        const target = e.currentTarget || e.target;
        if (!this._isValidKey(e)) {
            e.preventDefault();
            return;
        }
        if (e.key === 'Backspace') {
            if (target.value.length === 0) {
                const prev = this.element.querySelector(`input#${target.dataset.previous}`)
                if (prev !== null) {
                    prev.focus();
                }
            } else if (target.maxLength === 1) {
                target.value = '';
            }
            this._updateFullValue();
        } else if (this._isValidCharacter(e.key) && target.value.length && target.maxLength === 1) {
            target.value = '';
        }
    }

    _onKeyUp(e) {
        const target = e.currentTarget || e.target;
        if (e.key === 'ArrowLeft') {
            if (target.maxLength === 1 || target.selectionEnd === 0) {
                const prev = this.element.querySelector(`input#${target.dataset.previous}`)
                if (prev !== null) {
                    prev.focus();
                }
            }
        } else if (e.key === 'ArrowRight') {
            if ((target.maxLength === 1 && target.value.length) ||
                (target.maxLength === target.selectionStart &&
                    target.maxLength === target.selectionEnd)) {
                const next = this.element.querySelector(`input#${target.dataset.next}`)
                if (next !== null) {
                    next.focus()
                }
            }
        } else if (e.key === 'Enter') {
            if (!this._isValueComplete()) {
                e.preventDefault();
            } else {
                target.form.requestSubmit();
            }
        }
    }

    _onInput(e) {
        const digitValue = e.data,
            target = e.currentTarget || e.target;
        if (digitValue == null) {
            return;
        }
        if (this._isValidCharacter(digitValue) && target.value.length === target.maxLength) {
            const next = this.element.querySelector(`input#${target.dataset.next}`)
            if (next !== null) {
                next.focus();
            }
        }
        this._updateFullValue();
    }

    _onChange(e) {
        const target = e.currentTarget || e.target;
        if (target.value.length > target.maxLength){
            // Move digits over to other inputs
            const fullValue = target.value
            target.value = ''
            this._setValue(target, fullValue);
        }
        this._updateFullValue();
    }

    // Move focus to previous element if the previous is not fully filled.
    _onFocus(e) {
        let target = e.currentTarget || e.target;
        if (target.value.length === 0) {
            const prev = this.element.querySelector(`input#${target.dataset.previous}`)
            if (prev && prev.value.length < prev.maxLength) {
                prev.focus();
            }
        } else {
            window.setTimeout(() => this._moveCaretToEnd(target), 1);
        }
    }

    _onPaste(e) {
        e.preventDefault();
        const clipboardData = e.clipboardData || window.clipboardData,
            pastedData = clipboardData.getData("Text");
        this._setValue(e.currentTarget || e.target, pastedData, true);
    }

    _setValue(element, value, immediatelyAutoSubmit){
        for (let idx = 0; element && idx < value.length; idx++) {
            if (this._isValidCharacter(value[idx])) {
                if (element.value.length >= element.maxLength) {
                    if (element.dataset.next) {
                        element = this.element.querySelector(`input#${element.dataset.next}`);
                        element.value = '';
                    } else {
                        break;
                    }
                }
                element.value += value[idx];
            }
        }
        if (element) {
            element.focus();
            this._updateFullValue(immediatelyAutoSubmit);
        }
    }

    _updateFullValue(immediatelyAutoSubmit) {
        if (this.autosubmitTimeout) {
            clearTimeout(this.autosubmitTimeout);
            this.autosubmitTimeout = null;
        }
        let fullValue = '',
            isComplete = true,
            section = this.sectionTargets.find(el => el.dataset.previous == null);

        while (section != null) {
            fullValue += section.value;
            isComplete &&= section.value.length === section.maxLength;
            section = this.element.querySelector(`input#${section.dataset.next}`);
        }
        if (this.fullValueTarget.value !== fullValue) {
            this.fullValueTarget.value = fullValue;
            this.fullValueTarget.dispatchEvent(new Event("change"));

            if (isComplete && this.hasAutoSubmitValue && this.autoSubmitValue) {
                this.autosubmitTimeout = setTimeout(() => {
                    if (this.fullValueTarget.value === fullValue) {
                        this.fullValueTarget.form.requestSubmit();
                    }
                }, immediatelyAutoSubmit ? 50 : 5000);
            }
        }
    }

    _isValidKey(event) {
        return event.key === 'Backspace' ||
            event.key === 'ArrowLeft' ||
            event.key === 'ArrowRight' ||
            event.ctrlKey || event.metaKey || event.shiftKey ||
            this._isValidCharacter(event.key);
    }

    _isValidCharacter(ch) {
        if (this.validationPattern == null) {
            return true;
        } else {
            return this.validationPattern.test(ch);
        }
    }

    _isValueComplete() {
        let result = true;
        this.sectionTargets.forEach(el => {
            result &= el.value.length === el.maxLength;
        });
        return result;
    }

    _moveCaretToEnd(el) {
        if (typeof el.selectionStart == "number") {
            el.selectionStart = el.selectionEnd = el.value.length;
        } else if (typeof el.createTextRange != "undefined") {
            el.focus();
            let range = el.createTextRange();
            range.collapse(false);
            range.select();
        }
    }
}