import AutocompleteSource from './autocomplete_source'

export default class UserAutocompleteSource extends AutocompleteSource {

    constructor(options) {
        super('users', {
            endpoint: '/employees/typeahead',
            queryParameter: 'filter[search]',
            keys: ['full_name'],
            ...options
        });
    }

    styleOptionNode = (node, selection) => {
        if (selection.value.assignable_name) {
            node.innerHTML = `<div class="text-wrap">${selection.match}</div>`;
            const assignable = document.createElement("span");
            assignable.classList.add('small', 'text-muted', 'm-0');
            assignable.textContent = selection.value.assignable_name;
            node.append(assignable);
        }
    }
}
