import {Controller} from "@hotwired/stimulus";
import consumer from '../channels/consumer';
import WizardProgressBar from "../wizard_progress_bar";

export default class extends Controller {
    static targets = ['customerInput', 'selectResource', 'clearResources', 'confirmationForm',
        "progressBar", "itemsProcessed", "fileTypeInput", "fileFormatOptionsContainer", "enableAutomatedReport",
        "automatedReportForm", "goToPreviousStep", "goToNextStep"];

    static values = {
        selectedResourceCount: Number
    }

    connect() {
        if (this.hasProgressBarTarget || this.hasItemsProcessedTarget) {
            this.subscription = consumer.subscriptions.create(
                {
                    channel: "ExportStatusChannel",
                    id: this.data.get("id"),
                },
                {
                    received: this._received.bind(this),
                }
            );
        }
    }

    disconnect() {
        super.disconnect();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    customerInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    selectResourceTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.checked) {
                this.selectedResourceCountValue += 1;
            } else {
                this.selectedResourceCountValue -= 1;
            }
            this._setConfirmationEnabled(this.selectedResourceCountValue > 0);
        });
    }

    clearResourcesTargetConnected(el) {
        el.addEventListener('click', () => {
            this.selectResourceTargets.forEach(input => {
                input.checked = false;
                input.dispatchEvent(new Event('change', {'bubbles': true}));
            });
            this.selectedResourceCountValue = 0;
            this._setConfirmationEnabled(false);
        });
    }

    fileTypeInputTargetConnected(el) {
        if (el.checked) {
            this._showOutputFileFormatOptions(el.value)
        }
    }

    fileTypeChanged(ev) {
        if (ev.target.checked) {
            this._showOutputFileFormatOptions(ev.target.value)
        }
    }

    enableAutomatedReportTargetConnected(el) {
        // POST to the automated forms controller instead of navigating to the wizard finish path when the user
        // wants to create an automated report
        let automatedReportForm;
        if (this.automatedReportFormTarget.tagName === 'FORM') {
            automatedReportForm = this.automatedReportFormTarget;
        } else {
            automatedReportForm = this.automatedReportFormTarget.querySelector('form');
            if (automatedReportForm == null) {
                console.error("no form found in ", this.automatedReportFormTarget);
                throw "automatedReportFormTarget is not a form and does not contain one"
            }
        }
        this.goToNextStepTarget.addEventListener('click', e => {
            if (this.enableAutomatedReportTarget.checked) {
                e.preventDefault();
                automatedReportForm.submit();
            }
        })
    }

    _showOutputFileFormatOptions(fileType) {
        this.fileFormatOptionsContainerTargets.forEach((el) => {
            const fileTypes = el.dataset.applicableToFileType?.split(',');
            const isApplicable = fileTypes == null || fileTypes.includes(fileType);
            el.classList.toggle('d-none', !isApplicable);
        });
    }

    _setConfirmationEnabled(whether) {
        const disabled = !whether;
        this.confirmationFormTarget
            .querySelectorAll('input[type=submit],button[type=submit]')
            .forEach(submitButton => {
                submitButton.disabled = disabled;
                submitButton.classList.toggle('disabled', disabled);
            });
    }

    _received(data) {
        if (this.data.get("status") !== data.status) {
            Turbo.visit(location, {action: 'replace'});
        } else if (data.status === 'exporting') {
            WizardProgressBar.update(this, data);
        }
    }
}