import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="reset-mfa"
export default class extends Controller {
    static targets = ['identityVerified', 'token', 'tokenInput', 'submit'];

    connect() {
        this.identityVerificationChanged();
        this.tokenChanged();
    }

    identityVerificationChanged() {
        const identityVerified = this.identityVerifiedTarget.checked;
        this.tokenInputTargets.forEach(el => {
            el.disabled = !identityVerified;
        });
        if (!identityVerified) {
            this.submitTarget.disabled = true;
        } else {
            this.tokenChanged();
        }
    }

    tokenChanged() {
        this.submitTarget.disabled = !(this.identityVerifiedTarget.checked && this.tokenTarget.value.length === 16);
    }
}
