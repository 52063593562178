import {Controller} from "@hotwired/stimulus"
import {AniX} from "anix";
import * as DOMPurify from "dompurify";

const CONTENT_ALLOWED_TAGS = ['b', 'i', 'u', 'strong', 'em'];

// Connects to data-controller="list-group"
export default class extends Controller {
    static targets = ['listGroup']
    static values = {
        idInputName: String
    }

    connect() {
    }

    disconnect() {
        super.disconnect();
    }

    addListItem(content, itemId) {
        let listItem = this.listGroupTarget.querySelector(`input[value="${itemId}"]`);
        if (listItem) {
            AniX.fromTo(listItem.closest('.list-group-item'), 0.33, {
                backgroundColor: '#ffc553'
            }, {
                backgroundColor: null
            });
        } else {
            listItem = this._createListItem(content, itemId);
            this.listGroupTarget.appendChild(listItem);
        }
    }

    _createListItem(content, itemId) {
        const listItem = Object.assign(document.createElement('div'), {className: 'list-group-item'}),
            removeButton = Object.assign(document.createElement('button'), {
                className: 'btn btn-sm btn-flat-danger-on-hover mt-n1 mb-n1 float-right remove_button',
                type: 'button'
            }),
            removeIcon = Object.assign(document.createElement('i'), {
                className: 'fas fa-times',
            });

        listItem.innerHTML = DOMPurify.sanitize(content, {ALLOWED_TAGS: CONTENT_ALLOWED_TAGS});

        removeButton.appendChild(removeIcon);
        removeButton.setAttribute('data-action', 'list-group#removeListItem');
        listItem.appendChild(removeButton);

        if (itemId && this.hasIdInputNameValue) {
            const idInput = Object.assign(document.createElement('input'), {
                type: 'hidden', value: itemId, name: this.idInputNameValue
            });
            listItem.appendChild(idInput);
        }
        return listItem;
    }

    removeListItem(e) {
        e.target.closest('.list-group-item').remove();
    };
}
