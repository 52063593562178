import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="recurrence-form"
export default class extends Controller {
    static targets = ['frequencySelect', 'interval', 'intervalAppend', 'intervalInputs', 'dayOfWeekInputs', 'monthInputs', 'dayNumberInputs']

    connect() {
        this.frequencySelectTarget.addEventListener('input', () => {
            this._updateIntervalLabel();
            this._setInputsVisibility();
        });
        this.intervalTarget.addEventListener('input', () => this._updateIntervalLabel())
        this._updateIntervalLabel();
        this._setInputsVisibility();
    }

    _setInputsVisibility() {
        switch (this.frequencySelectTarget.value) {
            case "day": {
                this.intervalInputsTarget.classList.remove('d-none')
                this.dayOfWeekInputsTarget.classList.add('d-none')
                this.monthInputsTarget.classList.add('d-none')
                this.dayNumberInputsTarget.classList.add('d-none')
                break;
            }
            case "week": {
                this.intervalInputsTarget.classList.remove('d-none')
                this.dayOfWeekInputsTarget.classList.remove('d-none')
                this.monthInputsTarget.classList.add('d-none')
                this.dayNumberInputsTarget.classList.add('d-none')
                break;
            }
            case "month": {
                this.intervalInputsTarget.classList.remove('d-none')
                this.dayOfWeekInputsTarget.classList.add('d-none')
                this.monthInputsTarget.classList.add('d-none')
                this.dayNumberInputsTarget.classList.remove('d-none')
                break;
            }
            case "year": {
                this.intervalInputsTarget.classList.remove('d-none')
                this.dayOfWeekInputsTarget.classList.add('d-none')
                this.monthInputsTarget.classList.remove('d-none')
                this.dayNumberInputsTarget.classList.remove('d-none')
                break;
            }
            case "working_cycle": {
                this.intervalInputsTarget.classList.add('d-none')
                this.dayOfWeekInputsTarget.classList.add('d-none')
                this.monthInputsTarget.classList.add('d-none')
                this.dayNumberInputsTarget.classList.remove('d-none')
                break;
            }
        }
    }

    _updateIntervalLabel() {
        if (this.hasIntervalAppendTarget) {
            this.intervalAppendTarget.textContent =
                i18n.t(`date_range.${this.frequencySelectTarget.value}s`, {count: parseInt(this.intervalTarget.value)})
        }
    }
}
