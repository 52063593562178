import {Controller} from "@hotwired/stimulus"
import {
    create as createCredential,
    get as getCredential,
    parseCreationOptionsFromJSON,
    parseRequestOptionsFromJSON
} from "@github/webauthn-json/browser-ponyfill";
import i18n from "../i18n";

export default class extends Controller {
    static targets = ['errorMessage', 'formInput']
    static values = {challengeUri: String, callbackUri: String, parameter: String}

    verifyCredential() {
        const errorMessageTarget = this.errorMessageTarget,
            formInputTarget = this.formInputTarget;

        fetch(this.challengeUriValue)
            .catch(() => {
                errorMessageTarget.textContent = i18n.t('views.multi_factor_authentication.js.webauthn.fetch_error');
            })
            .then(r => r.json())
            .then(json => {
                getCredential(parseRequestOptionsFromJSON({publicKey: json}))
                    .then(async function (credential) {
                        formInputTarget.value = JSON.stringify(credential);
                        formInputTarget.form.requestSubmit();
                    })
                    .catch(error => {
                        console.error("cannot build credential ", error);
                        errorMessageTarget.textContent = i18n.t('views.multi_factor_authentication.js.webauthn.generic_authentication_error');
                    });
            });
    }

    createCredential() {
        const errorMessageTarget = this.errorMessageTarget,
            formInputTarget = this.formInputTarget;

        fetch(this.challengeUriValue)
            .catch(() => {
                errorMessageTarget.textContent = i18n.t('views.multi_factor_authentication.js.webauthn.fetch_error');
            })
            .then(response => response.json())
            .then(json => {
                createCredential(parseCreationOptionsFromJSON({publicKey: json}))
                    .then(async function (credential) {
                        formInputTarget.value = JSON.stringify(credential);
                        formInputTarget.form.requestSubmit();
                    })
                    .catch(error => {
                        console.error("cannot build credential ", error);
                        if (error.message && error.message.includes('excludeCredentials') && error.message.includes('matches')) {
                            errorMessageTarget.textContent = i18n.t('views.multi_factor_authentication.js.webauthn.credential_already_registered');
                        } else {
                            errorMessageTarget.textContent = i18n.t('views.multi_factor_authentication.js.webauthn.generic_registration_error');
                        }
                    });
            });
    }

    rename(event) {
        const id = event.currentTarget.dataset['credentialId'],
            modal = document.getElementById('rename_webauthn_credential_modal');
        let form = modal.querySelector('form');
        form.querySelector('input.nickname').value = event.currentTarget.parentElement.querySelector('[data-webauthn-target="nickname"]').textContent;
        form.setAttribute('action', form.dataset.urlFormat.replace('--ID', id));
        form.addEventListener('turbo:submit-end', function (e) {
            if (e.detail.success) {
                form.removeEventListener('turbo:submit-end', this);
                $(modal).modal('hide');
            }
        });
        $(modal).one('shown.bs.modal', () => {
            form.querySelector('input.nickname').focus().select();
        }).modal('show');
    }

    delete(event) {
        const id = event.currentTarget.dataset['credentialId'],
            modal = document.getElementById('destroy_webauthn_credential_modal');
        let form = modal.querySelector('form#destroy_webauthn_credential');
        if (id == null || id.length === 0) {
            return;
        }

        modal.querySelector('[data-webauthn-target="nickname"]').textContent = event.currentTarget.parentElement.querySelector('[data-webauthn-target="nickname"]').textContent;
        form.setAttribute('action', form.dataset.urlFormat.replace('--ID', id));
        form.addEventListener('turbo:submit-end', function (e) {
            if (e.detail.success) {
                form.removeEventListener('turbo:submit-end', this);
                $(modal).modal('hide');
            }
        });
        $(modal).one('shown.bs.modal', () => {
            form.querySelector('input.password').focus();
        }).modal('show');
    }
}