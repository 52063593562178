import {Controller} from "@hotwired/stimulus"
import {htmlEscape} from "escape-goat";
import Codemirror from "codemirror/lib/codemirror";

import "codemirror/mode/javascript/javascript";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/addon/edit/closebrackets";

const contextSize = 50;

export default class extends Controller {
    static targets = ['jsonData', 'lintResult']

    connect() {
        this.editor = Codemirror.fromTextArea(this.jsonDataTarget, {
            mode: "application/json", matchBrackets: true, autoCloseBrackets: true
        });
        let rows = this.jsonDataTarget.getAttribute('rows');
        if (rows && rows.length) {
            this.editor.setSize(null, `${parseInt(rows) * this.editor.defaultTextHeight()}px`);
        }
        this.editor.on('change', (cm) => {
            this.validateJson(cm.getValue(), this.lintResultTarget);
        });
        this.validateJson(this.jsonDataTarget.value, this.lintResultTarget);
    }

    disconnect() {
        if (this.editor) {
            this.editor.toTextArea();
            this.editor = null;
        }
    }

    validateJson(json, resultTarget) {
        try {
            JSON.parse(json);
            resultTarget.innerHTML = null;
        } catch (e) {
            if (e instanceof SyntaxError) {
                let jsonError = `
                    <h6 class="text-danger"><i class="fad fa-exclamation-triangle"></i> Invalid JSON</h6>
                    <strong>${htmlEscape(e.message.replaceAll(' in JSON ', ' '))}</strong>
                `;

                let position = null;
                if (e.message.match(/^Unexpected end of JSON.*/i)) {
                    position = json.length - 1;
                } else {
                    const positionMatch = e.message.match(/at position\s+(\d+)/i);
                    if (positionMatch) {
                        position = parseInt(positionMatch[1])
                    }
                }
                if (position) {
                    let index, contextStart = json.lastIndexOf('\n', position);
                    if (contextStart > 0 && (index = json.lastIndexOf('\n', contextStart - 1)) > -1) {
                        contextStart = index;
                    } else if (contextStart === -1) {
                        contextStart = position - contextSize;
                    }
                    if (contextStart <= 1) {
                        contextStart = 0;
                    }

                    let contextEnd = json.indexOf('\n', position);
                    if (contextEnd > 0 && (index = json.indexOf('\n', contextEnd + 1)) > -1) {
                        contextEnd = index + 1;
                    } else if (contextEnd === -1) {
                        contextEnd = position + contextSize;
                    } else {
                        contextEnd += 1;
                    }
                    if (contextEnd >= json.length - 2) {
                        contextEnd = json.length - 1;
                    }

                    jsonError += `</br><pre class="mt-2">${contextStart === 0 ? '' : '…'}${htmlEscape(json.slice(contextStart, position))}<strong style="background: red">${htmlEscape(json[position] ?? ' ')}</strong>${htmlEscape(json.slice(position + 1, contextEnd + 1))}${contextEnd === json.length - 1 ? '' : '…'}</pre>`
                }
                resultTarget.innerHTML = jsonError;
            } else {
                console.error(e);
                resultTarget.innerText = e;
            }
        }
    }
}
