import {format, mark} from "@tarekraafat/autocomplete.js/src/helpers/io";

// Always returns a match (Assumes the server does the filtering)
const searchEngine = function (query, record) {
    const diacritics = true, highlight = true, nRecord = format(record, diacritics);
    record = String(record);
    let result = record;

    // Split query on whitespace
    format(query, diacritics).split(/\s+/).forEach(word => {
        let index = nRecord.indexOf(word);
        if (~index && highlight) {
            word = record.substring(index, index + word.length);
            result = result.replace(word, mark(word, highlight));
        }
    });
    return result;
}

export default searchEngine
