import {Controller} from "@hotwired/stimulus";
import moment from "moment";
import i18n from "../i18n";

export default class extends Controller {
    static targets = ["locationSelect", "offset", "output"]
    static values = {messageScope: String, messageScopeWithLocation: String, timeOfDay: String, cycles: Array}

    connect() {
        this._showPlanning()
    }

    locationSelectTargetConnected(el) {
        el.addEventListener('change', e => {
            if (this.hasOffsetTarget) {
                const locationId = e.target.value;
                if (locationId && locationId.length) {
                    const cycle = this.cyclesValue.find((value) => {
                        return Array.isArray(value[2]) && value[2].includes(locationId);
                    })
                    this.offsetTarget.max = cycle[1];
                } else {
                    let maxDuration = 0;
                    this.cyclesValue.forEach((value) => {
                        if (value[1] > maxDuration) {
                            maxDuration = value[1];
                        }
                    })
                    this.offsetTarget.max = maxDuration;
                }
            }
            this._showPlanning()
        })
    }

    offsetTargetConnected(el) {
        el.addEventListener('input', () => this._showPlanning())
        el.addEventListener('change', () => this._showPlanning())
    }

    _showPlanning() {
        const locationId = this.hasLocationSelectTarget && this.locationSelectTarget.value.length ? this.locationSelectTarget.value : null,
            offset = parseInt(this.offsetTarget.value) - 1;
        let cycle, plannedAt;
        if (locationId) {
            cycle = this.cyclesValue.find((value) => {
                return Array.isArray(value[2]) && value[2].includes(locationId);
            });
        } else {
            cycle = this._getFirstWorkingCycle(offset);
        }
        if (cycle == null) {
            this.outputTarget.textContent = '';
            return;
        }

        if (this.hasTimeOfDayValue) {
            plannedAt = moment(this.timeOfDayValue, "HH:mm")
        } else {
            plannedAt = moment()
        }
        plannedAt = this._determineWorkingCycleStart(cycle, plannedAt).add(offset, 'days');
        if (plannedAt.isBefore()) {
            plannedAt.add(cycle[1], 'days');
        }

        if (locationId == null && this.cyclesValue.length > 0 && this.hasLocationSelectTarget && this.hasMessageScopeWithLocationValue) {
            let locationName = this.locationSelectTarget.querySelector(`[value="${cycle[2][0]}"]`).textContent
            this.outputTarget.textContent = i18n.t(this.messageScopeWithLocationValue, {
                location: locationName,
                date: plannedAt.calendar()
            })
        } else if (this.hasMessageScopeValue) {
            this.outputTarget.textContent = i18n.t(this.messageScopeValue, {date: plannedAt.calendar()})
        } else {
            this.outputTarget.textContent = plannedAt.calendar()
        }
    }

    _getFirstWorkingCycle(offset) {
        if (this.cyclesValue.length <= 1) {
            return this.cyclesValue[0];
        }
        const referenceDate = moment().subtract(offset - 1, 'days');
        let result, startsOn, date;
        this.cyclesValue.forEach(cycle => {
            if (cycle[1] > offset) {
                let locationName = this.locationSelectTarget.querySelector(`[value="${cycle[2][0]}"]`).textContent
                date = this._determineWorkingCycleStart(cycle, referenceDate);
                console.log(locationName, "\n => starts at: ", date);
                date.add(offset, 'days')
                if (date.isBefore(moment().endOf('day'))) {
                    date.add(cycle[1], 'days');
                }
                console.log(" => offset: ", date)
                if (startsOn == null || date < startsOn) {
                    result = cycle
                    startsOn = date
                }
            }
        })
        return result;
    }

    _determineWorkingCycleStart(cycle, date) {
        const cycleDate = moment(cycle[0]),
            cycleDuration = cycle[1];
        if (date == null) {
            date = moment()
        } else if (typeof date === 'string') {
            date = moment(date);
        }
        let offset = date.diff(cycleDate, 'days') % cycleDuration;
        if (offset < 0) {
            offset += cycleDuration;
        }
        return date.clone().subtract(offset, 'days');
    }

}