import {Controller} from "@hotwired/stimulus"
import {debounce} from "throttle-debounce";

// Connects to data-controller="login-code-generator"
export default class extends Controller {
    static targets = ['name', 'result']

    connect() {
        if (!this.hasNameTarget) {
            throw "Name target not specified"
        }
        if (!this.hasResultTarget) {
            throw "Result target not specified"
        }
    }

    nameTargetConnected(el) {
        const resultTarget = this.resultTarget;
        el.addEventListener('input', debounce(300, () => {
            resultTarget.value = el.value.toLowerCase();
        }));
    }
}
