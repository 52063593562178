import AutocompleteSource from './autocomplete_source'

const getEndpoint = function (options) {
    if (Object.hasOwn(options, 'endpoint')) {
        return options['endpoint']
    } else {
        throw "endpoint must be configured in options"
    }
}
export default class GenericAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('generic', {
            endpoint: getEndpoint(options),
            keys: options['keys'] || ["name"],
            ...options
        });
    }
}
