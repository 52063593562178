import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["barnCheckbox"];

    enableAllBarns(event) {
        let changeEvent = new Event('change', {'bubbles': true});
        this.barnCheckboxTargets.forEach(checkbox => {
            checkbox.checked = true;
            checkbox.dispatchEvent(changeEvent);
        });
    }

    disableAllBarns(event) {
        let changeEvent = new Event('change', {'bubbles': true});
        this.barnCheckboxTargets.forEach(checkbox => {
            checkbox.checked = false;
            checkbox.dispatchEvent(changeEvent);
        });
    }
}
