import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

export default class PenAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('pens', {keys: ['name'], ...options})
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `
      <span class="text-truncate flex-fill">${selection.match}</span>
      <span class="text-muted small">
        ${i18n.t(`pen_type.${selection.value.type}`)}, ${selection.value.farm.name}
      </span>`;
    }

    styleFormattedValue(node, selection) {
        const info = document.createElement("span");
        info.classList.add('text-muted', 'small')
        info.textContent = i18n.t(`pen_type.${selection.value.type}`);
        node.append(" ");
        node.append(info);
    }
}
