import Rails from '@rails/ujs';

const AutoSubmitForms = {};

AutoSubmitForms.addInputsToForm = function ($form, $container, debounce) {
    const textualInputsSelector = "input[type='text'],input[type='textarea'],input[type='search'],input[type='number']";
    if (!debounce) {
        debounce = 100;
    }
    let submitter = function () {
        Rails.fire($form.get(0), 'submit');
    }, debouncedSubmitter = $.debounce(debounce, submitter);
    $container.find(textualInputsSelector).filter('[name]:not([name=""])').filter(":not([data-no-autosubmit])")
        .on('input', $.debounce(Math.max(debounce, 500), submitter))
        .on('change', function () {
            // Check if the value doesn't match the already submitted value (submitted through 'input' event)
            let submittedValue = $(this).data('submitted-value');
            if (submittedValue == null || submittedValue !== $(this).val()) {
                debouncedSubmitter();
            }
        });
    $container.find("input[type='hidden'],input[type='checkbox'],input[type='radio'],select").filter('[name]:not([name=""])').filter(":not([data-no-autosubmit])").on("change", debouncedSubmitter);

    $container.find('button[type=reset]').on('click', function (e) {
        let formEl = $form.get(0);
        formEl.reset();
        Rails.fire(formEl, 'submit');

        e.preventDefault();
        return false;
    });

    $form.on('submit', function () {
        $form.find(textualInputsSelector).each(function () {
            // Save submitted values so we won't submit again after a 'change' event on 'blur'. (Form might already be submitted through 'input' event)
            $(this).data('submitted-value', $(this).val());
        });
    });
};

AutoSubmitForms.initialize = function (form, target) {
    var $form, $target;
    if (form instanceof jQuery) {
        $form = form;
    } else {
        $form = $(form);
    }
    if (!$form || !$form.length) {
        return
    }
    AutoSubmitForms.addInputsToForm($form, $form);

    if (typeof target === 'undefined') {
        $target = $($form.attr('data-target'));
    } else if (typeof target === 'string') {
        $target = $(target)
    } else {
        $target = target;
    }
    if ($target.length) {
        $form.on("ajax:before", function () {
            $target.fadeTo("fast", 0.2);
        }).on("ajax:success", function (event) {
            const [, , xhr] = event.detail;
            $target.html(xhr.response);
            $target.fadeTo("fast", 1);
            $form.find("#clear").removeClass("disabled").removeAttr("disabled");
        }).on("ajax:error", function () {
            $target.html('');
            $target.fadeTo("fast", 1);
        });
    }
};

module.exports = AutoSubmitForms;