import AutocompleteSource from './autocomplete_source'

export default class CullReasonAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('cull_reasons', {keys: ['name'], ...options});
    }

    styleOptionNode(node, data) {
        if (data.value && data.value.exists) {
            node.classList.add('checked');
        }
    }
}
