import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";
import {AniX} from "anix";
import moment from "moment";
import DateRangeFormatter from "../date_range_formatter";

// Connects to data-controller="dashboard-widget-form"
export default class extends Controller {
    static targets = ['result', 'form', 'periodSelector', 'periodBegin', 'periodEnd', 'periodType',
        'formattedSelectedPeriod', 'periodPlusTodayLabel', 'periodPlusThisWeekLabel', 'periodPlusThisMonthLabel',
        'enableComparison', 'comparisonType', 'comparisonTypeWrapper', 'splitOnComparisonInputs',
        'comparisonDatasetWrapper', 'comparisonDataset', 'addComparisonDataset', 'comparisonDatasetTemplate',
        'saveAsNew', 'confirm']

    static values = {
        dataFormat: String,
        displayType: String,
        isNew: Boolean,
        minPeriodBegin: String,
        createToken: String,
        updateToken: String,
        pigFilterOptions: Object,
        formatPeriodOptions: Object,
        startOfWeek: {type: Number, default: 0},
        datasetColors: {type: Array, default: ['000000']}
    }

    initialize() {
        this._showSelectedPeriod = this._showSelectedPeriod.bind(this)
    }

    connect() {
        this.formTarget.addEventListener('ajax:before', () => {
            if (this.resultTarget.querySelector('.chart-placeholder') == null) {
                let progressMessage = this.resultTarget.querySelector('.widget-updating-message');
                AniX.fromTo(progressMessage, 0.5,
                    {opacity: 0}, {opacity: 1});
            }
        })
        this.formTarget.addEventListener("ajax:success", (event) => {
            const [, , xhr] = event.detail;
            let progressMessage = this.resultTarget.querySelector('.widget-updating-message');
            if (this.dataFormatValue === 'json') {
                let response = JSON.parse(xhr.response),
                    chartContainer = this.resultTarget.querySelector('.chart-container');
                if (response.noChart) {
                    ChartHelper.showPlaceholder(chartContainer, response);
                    AniX.fromTo(this.resultTarget.querySelector('.widget-updating-message'), 0.3,
                        {opacity: 1}, {opacity: 0});
                    return;
                }
                if (response.data && response.data.datasets) {
                    $.each(response.data.datasets, function (index, dataset) {
                        dataset.tooltipItemUrl = null;
                    });
                }
                if (!response.options.plugins) {
                    response.options.plugins = {};
                }
                if (!response.options.plugins.tooltip) {
                    response.options.plugins.tooltip = {};
                }
                if (!response.options.plugins.tooltip.callbacks) {
                    response.options.plugins.tooltip.callbacks = {};
                }

                const footerText = this.isNewValue ?
                    i18n.t('views.dashboard_widgets.form.tooltip.save_footer') :
                    i18n.t('views.dashboard_widgets.form.tooltip.investigate_footer');
                response.options.plugins.tooltip.callbacks.footer = function () {
                    return footerText;
                };
                ChartHelper.updateChart(chartContainer, response);
            } else {
                this.resultTarget.innerHTML = xhr.response;
                this.resultTarget.prepend(progressMessage);
            }
            if (progressMessage.style.opacity ?? 1 > 0) {
                AniX.fromTo(progressMessage, 0.3,
                    {opacity: progressMessage.style.opacity ?? 1},
                    {opacity: 0});
            }
        });
        this.formTarget.addEventListener("ajax:error", () => {
            AniX.fromTo(this.resultTarget.querySelector('.widget-updating-message'), 0.3,
                {opacity: 1}, {opacity: 0});
            ChartHelper.showPlaceholder(this.resultTarget.querySelector('.chart-container'), {
                error: i18n.t('ajax.loading_error')
            });
        });

        if (this.dataFormatValue === 'json') {
            Rails.fire(this.formTarget, 'submit')
        }

        this._initializePeriodPicker(this.periodSelectorTarget);

        if (this.hasComparisonDatasetWrapperTarget) {
            this._updateRemoveDataSetVisibility();
        }

        PigFilter.initialize(
            this.pigFilterOptionsValue,
            `dashboard_widget[configuration][filter]`,
            this.element
        );
    }

    disconnect() {
        super.disconnect();
        this.easepick?.destroy();
    }

    comparisonDatasetTargetConnected(el) {
        const index = el.dataset.datasetIndex;
        if (index === '_index_') return;

        PigFilter.initialize(
            this.pigFilterOptionsValue,
            `#comparison_data_set_${index} dashboard_widget[configuration][comparison_data_sets][filter]`,
            el
        );
    }

    configureForPersistence() {
        let method, action, authenticity_token, configureUrl = this.formTarget.getAttribute('action')
        if (this.isNewValue || (this.hasSaveAsNewTarget && this.saveAsNewTarget.checked)) {
            method = 'post';
            action = configureUrl.replace(/\/widgets\/.+/, '/widgets');
            authenticity_token = this.createTokenValue;
            this.formTarget.querySelectorAll('#dashboard_widget_data_type, #dashboard_widget_display_type')
                .forEach((el) => {
                    el.removeAttribute('disabled');
                });
        } else {
            method = 'patch';
            action = configureUrl.replace(/\/widgets\/([a-zA-Z0-9]+)\/.+/, '/widgets/$1');
            authenticity_token = this.updateTokenValue;
        }

        this.formTarget.setAttribute('action', action)
        delete this.formTarget.dataset.remote

        this.formTarget.querySelector('input[name=_method]').value = method;
        this.formTarget.querySelector('input[name=authenticity_token]').value = authenticity_token;
    }

    saveAsNewChanged() {
        let confirmText = this.saveAsNewTarget.checked ?
            i18n.t('views.wizards.next') : i18n.t('model_update');
        this.confirmTarget.value = confirmText;
        this.confirmTarget.setAttribute('data-disable-with', confirmText);
    }

    enableComparisonChanged() {
        let blankOption = this.comparisonTypeTarget.querySelector('option[value=""]');
        if (this.enableComparisonTarget.checked) {
            blankOption?.remove();
            this.comparisonTypeWrapperTarget.classList.remove('d-none');
        } else {
            this.comparisonTypeWrapperTarget.classList.add('d-none');

            if (blankOption == null) {
                blankOption = document.createElement('option');
                blankOption.setAttribute('value', '');
                this.comparisonTypeTarget.prepend(blankOption);
            }
            this.comparisonTypeTarget.value = '';
        }
        this.comparisonTypeTarget.dispatchEvent(new Event('change', {'bubbles': true}));
    }

    comparisonTypeChanged() {
        let value = this.comparisonTypeTarget.value;
        if (value === 'split') {
            // Select the first splitOn radioButton if none are selected
            if (this.splitOnComparisonInputsTarget.querySelector('input[type=radio]:checked') == null) {
                let firstRadio = this.splitOnComparisonInputsTarget.querySelector('input[type=radio]');
                firstRadio.checked = true
                firstRadio.dispatchEvent(new Event('change', {'bubbles': true}));
            }
            this.splitOnComparisonInputsTarget.classList.remove('d-none');
        } else {
            this.splitOnComparisonInputsTarget.classList.add('d-none');
            // Deselect the splitOn radioButtons
            this.splitOnComparisonInputsTarget.querySelectorAll('input[type=radio]').forEach((el) => {
                el.checked = false;
            });
        }
        if (value === 'custom_data_sets') {
            if (this.comparisonDatasetWrapperTarget.childElementCount === 0) {
                this.addComparisonDataset();
            }
            this.comparisonDatasetWrapperTarget.classList.remove('d-none');
            this.addComparisonDatasetTarget.classList.remove('d-none');
        } else {
            this.comparisonDatasetWrapperTarget.classList.add('d-none');
            this.addComparisonDatasetTarget.classList.add('d-none');
        }
    }

    addComparisonDataset(evt) {
        evt?.preventDefault();

        const index = Date.now() * 100 + Math.floor(Math.random() * 100),
            number = this.comparisonDatasetWrapperTarget.childElementCount,
            dataset = this.comparisonDatasetTemplateTarget.content.cloneNode(true).firstChild;

        // Make all indices and input names unique
        dataset.innerHTML = dataset.innerHTML.replace(/_index_/g, index);
        // Update data set name and color
        this._updateDataSetNumber(dataset, number);

        this.comparisonDatasetWrapperTarget.append(dataset);

        // Need to use jQuery because of bootstrap
        $(dataset).find('> .card-collapse').collapse('show');

        this._updateRemoveDataSetVisibility();

        PigFilter.initialize(
            this.pigFilterOptionsValue,
            `#comparison_data_set_${index} dashboard_widget[configuration][comparison_data_sets][filter]`,
            dataset
        );

        Rails.fire(this.formTarget, 'submit');
    }

    removeComparisonDataset(evt) {
        evt.preventDefault();

        let dataSet = evt.target.closest('.comparison_data_set'),
            nextDataSet = dataSet.nextElementSibling,
            number = Array.prototype.indexOf.call(dataSet.parentNode.children, dataSet);

        dataSet.remove();

        while (nextDataSet) {
            this._updateDataSetNumber(nextDataSet, number++);
            nextDataSet = nextDataSet.nextElementSibling;
        }

        this._updateRemoveDataSetVisibility();
        Rails.fire(this.formTarget, 'submit');
    }

    updateDataSetTitle(evt) {
        let input = evt.target, name = input.value,
            cardHeader = input.closest('.card').querySelector(':scope > .card-header > .dataset-name');
        if (cardHeader) {
            cardHeader.textContent = (name.length ? name : input.getAttribute('placeholder'));
        }
    }

    _initializePeriodPicker(element) {
        const periodBeginTarget = this.periodBeginTarget, periodEndTarget = this.periodEndTarget,
            periodTypeTarget = this.periodTypeTarget,
            minDate = moment(this.hasMinPeriodBeginValue ? this.minPeriodBeginValue : '2014-01-01', moment.ISO_8601),
            selectedPeriodBegin = periodBeginTarget.value.length ? moment(periodBeginTarget.value, moment.ISO_8601) : moment(),
            selectedPeriodEnd = periodEndTarget.value.length ? moment(periodEndTarget.value, moment.ISO_8601) : moment(),
            options = {
                startDate: selectedPeriodBegin,
                endDate: selectedPeriodEnd,
                minDate: minDate,
                maxDate: moment(),
                timePicker: false,
                linkedCalendars: false,
                alwaysShowCalendars: true,
                showCustomRangeLabel: false,
                showDropdowns: true,
                showISOWeekNumbers: true,
                opens: 'right',
                drops: 'down',
                locale: {
                    applyLabel: i18n.t('apply'),
                    cancelLabel: i18n.t('cancel'),
                    fromLabel: i18n.t('date_range.date_from'),
                    toLabel: i18n.t('date_range.date_to_inclusive'),
                    customRangeLabel: i18n.t('custom'),
                    firstDay: this.startOfWeekValue
                },
                ranges: this._getPreselectionRanges()
            };
        let showSelectedPeriod = this._showSelectedPeriod,
            thisYearLabel = i18n.t("date_range.this_year");
        $(element).daterangepicker(options, function (start, end, label) {
            if (label === this.locale.customRangeLabel) {
                label = null;
            }
            showSelectedPeriod(start, end, label);

            periodBeginTarget.value = start.format('YYYY-MM-DD');
            periodEndTarget.value = end.format('YYYY-MM-DD');

            let periodType = null;
            if (label === thisYearLabel) {
                periodType = 'this_year';
            }
            periodTypeTarget.value = periodType;

            periodEndTarget.dispatchEvent(new Event('change', {'bubbles': true}));
        });

        let label = null;
        if (periodTypeTarget.value === 'this_year') {
            label = thisYearLabel;
        }
        this._showSelectedPeriod(selectedPeriodBegin, selectedPeriodEnd, label);
        this._updatePeriodPreselectionAtMidnight();
    }

    _getPreselectionRanges() {
        const ranges = {}
        ranges[DateRangeFormatter.formatPastDuration(moment().startOf('day'))] = [moment().startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(7, 'days'))] = [moment().subtract(7, 'days').startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(5, 'weeks'))] = [moment().subtract(5, 'weeks').startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(3, 'months'))] = [moment().subtract(3, 'months').startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(6, 'months'))] = [moment().subtract(6, 'months').startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(1, 'year'))] = [moment().subtract(1, 'year').startOf('day'), moment().endOf('day')];
        ranges[DateRangeFormatter.formatPastDuration(moment().subtract(3, 'years'))] = [moment().subtract(3, 'years').startOf('day'), moment().endOf('day')];
        ranges[i18n.t("date_range.this_year")] = [moment().startOf('year'), moment().endOf('day ')];
        ranges[i18n.t("date_range.last_year")] = [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')];
        return ranges;
    }

    _showSelectedPeriod(start, end, label) {
        if (label == null) {
            label = DashboardWidgets.formatPeriod(start, end, this.displayTypeValue === 'history', this.formatPeriodOptionsValue);
        }
        this.periodSelectorTarget.querySelector('.current_value').textContent = label;
        let labelToShow = null;

        if (this.displayTypeValue === 'history') {
            if (end.isSame(moment(), 'day') &&
                !start.isSame(end.clone().startOf('year'))) {
                if (end.diff(start, 'week', true) > 50) {
                    labelToShow = 'this month';
                } else if (end.diff(start, 'day', true) > 50) {
                    labelToShow = 'this week';
                } else if (end.clone().endOf('day').diff(start, 'hour', true) > 50) {
                    labelToShow = 'today';
                }
            }
        } else {
            if (end.isSame(moment(), 'day') &&
                !start.isSame(end.clone().startOf('year')) &&
                !start.isSame(end.clone().startOf('month')) &&
                end.clone().endOf('day').diff(start, 'hour', true) > 50) {
                labelToShow = 'today';
            }
        }
        if (this.hasPeriodPlusTodayLabelTarget) {
            this.periodPlusTodayLabelTarget.classList.toggle('d-none', labelToShow !== 'today');
        }
        if (this.hasPeriodPlusThisWeekLabelTarget) {
            this.periodPlusThisWeekLabelTarget.classList.toggle('d-none', labelToShow !== 'this week');
        }
        if (this.hasPeriodPlusThisMonthLabelTarget) {
            this.periodPlusThisMonthLabelTarget.classList.toggle('d-none', labelToShow !== 'this month');
        }
    }

    _updatePeriodPreselectionAtMidnight() {
        const midnight = new Date();
        midnight.setHours(24, 0, 0, 0);
        setTimeout(() => {
            console.log('updating period selection ranges');
            //  Update ranges so they include today's date
            const picker = $(this.periodSelectorTarget).data('daterangepicker');
            picker.maxDate = moment().endOf('day');
            picker.ranges = this._getPreselectionRanges();
            picker.updateView();

            // Plan the next update for 24h from now
            this._updatePeriodPreselectionAtMidnight();
        }, midnight.getTime() - Date.now());
    }

    _updateDataSetNumber(dataSet, number) {
        let cardHeader = dataSet.querySelector(':scope > .card-header'),
            nameInput = dataSet.querySelector('input[id^="dashboard_widget_configuration_comparison_data_sets_"][name$="[name]"]'),
            defaultName = `${i18n.t('views.dashboard_widgets.comparison_data_set')} ${number + 1}`,
            color = this.datasetColorsValue[(number + 1) % this.datasetColorsValue.length];
        dataSet.style.borderColor = color;
        cardHeader.style.borderColor = color;
        cardHeader.style.backgroundColor = color.replace(/^rgb\(([0-9]+),\s+([0-9]+),\s+([0-9]+)\)$/, 'rgba($1,$2,$3,0.2)');
        dataSet.querySelector(':scope > .card-header > .dataset-name').textContent = nameInput.value.length ? nameInput.value : defaultName;
        nameInput.setAttribute('placeholder', defaultName);
    }

    _updateRemoveDataSetVisibility() {
        const hideRemoveButton = this.comparisonDatasetWrapperTarget.childElementCount < 2;
        this.comparisonDatasetWrapperTarget.querySelectorAll('a[href="#remove-data-set"]').forEach((el) => {
            el.classList.toggle('d-none', hideRemoveButton);
        })
    }
}
