import Locale from "./locale";

const WizardProgressBar = {}

WizardProgressBar.update = function (controller, data) {
    if (data.items_processed && data.items_total && data.items_total > 0) {
        let percentage = data.items_processed * 100.0 / data.items_total,
            pageTitle = controller.data.get('pageTitle');
        if (pageTitle == null) {
            pageTitle = document.title;
            controller.data.set('pageTitle', pageTitle);
        }
        if (controller.hasProgressBarTarget) {
            controller.progressBarTarget.classList.remove('progress-bar-striped', 'progress-bar-animated');
            controller.progressBarTarget.style.width = `${percentage}%`;
        }
        if (controller.hasItemsProcessedTarget) {
            controller.itemsProcessedTarget.textContent = new Intl.NumberFormat(Locale.get()).format(data.items_processed);
        }
        document.title = Intl.NumberFormat(Locale.get(), {
            style: 'percent',
            maximumFractionDigits: 0
        }).format(percentage / 100.0) + ' - ' + pageTitle;
    } else {
        if (controller.hasProgressBarTarget) {
            controller.progressBarTarget.style.width = '100%';
            controller.progressBarTarget.classList.add('progress-bar-striped', 'progress-bar-animated');
        }
        if (controller.hasItemsProcessedTarget) {
            controller.itemsProcessedTarget.textContent = null;
        }
    }
};

export default WizardProgressBar;