import {Controller} from "@hotwired/stimulus";
import moment from "moment";
import Locale from "../locale";
import {AmpPlugin, DateTime, easepick, LockPlugin} from "@easepick/bundle";

export default class extends Controller {
    static targets = ["commonDuration", "customDurationWrapper", "customDuration", "startDatePicker"]

    initialize() {
        this.initStartOnPicker = this.initStartOnPicker.bind(this)
    }

    connect() {
        const setDurationDays = (el) => {
            let isCustom = el.value === 'other', durationDays;
            if (isCustom) {
                durationDays = parseInt(this.customDurationTarget.value);
            } else {
                durationDays = parseInt(el.value);
            }
            this.durationDays = durationDays;

            this.customDurationWrapperTarget.classList.toggle('d-none', !isCustom);
            if (this.startDatePicker != null) {
                this.startDatePicker.renderAll();
            }
        };

        this.commonDurationTargets.forEach(el => {
            el.addEventListener('change', () => {
                if (el.checked) {
                    setDurationDays(el);
                }
            });
            if (el.checked) {
                setDurationDays(el);
            }
        });
        this.customDurationTarget.addEventListener('input', () => {
            this.durationDays = parseInt(this.customDurationTarget.value);
            if (this.startDatePicker != null) {
                this.startDatePicker.renderAll();
            }
        });

        this.startDatePicker = this.initStartOnPicker(this.startDatePickerTarget);
    }

    disconnect() {
        super.disconnect();
        if (this.startDatePicker != null) {
            this.startDatePicker.destroy();
        }
    }

    initStartOnPicker(element) {
        const viewListener = (picker, evt) => {
            const {view, date, target} = evt.detail
            if (view !== 'CalendarDay') {
                return;
            }

            const startDate = picker.getDate(), cycleDuration = this.durationDays;
            if (startDate != null && cycleDuration != null && cycleDuration > 1) {
                let diff = date.diff(startDate, 'days');
                if (diff === 0) {
                    target.classList.add('start');
                } else if (diff === cycleDuration - 1) {
                    target.classList.add('end', 'intermediate');
                } else if (diff > 0 && diff < cycleDuration) {
                    target.classList.add('in-range');
                }
            }
        };
        const options = {
            element: element,
            css: [this.data.get("easepick-css")],
            lang: Locale.get(),
            firstDay: this.hasWeekStartValue ? this.weekStartValue : 1,
            calendars: 1,
            grid: 1,
            inline: true,
            plugins: [AmpPlugin, LockPlugin],
            AmpPlugin: {
                dropdown: {
                    years: false, months: true
                }, darkMode: false
            },
            LockPlugin: {
                minDate: new DateTime(moment().subtract(1, 'month').startOf('month').toDate()),
                maxDate: new DateTime(moment().add(10, 'week').endOf('month').toDate()),
            },

            setup(picker) {
                picker.ui.container.classList.add('shadow-none', 'border', 'range-plugin');
                picker.on('view', evt => viewListener(picker, evt));
            }
        };

        return new easepick.create(options);
    }

}