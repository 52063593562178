import CodeMirror from "codemirror";

/*
 * Starts autocompletion after certain key presses. Must be registered as a "keyUp" event listener.
 */
const autocompletionKeyUpListener = function (cm, event) {
    const code = event.keyCode
    if (code === 86 || event.altKey || event.ctrlKey) {
        return
    }
    if (
        (code >= 65 && code <= 90) || // letters
        (!event.shiftKey && code >= 48 && code <= 57) || // numbers
        (event.shiftKey && code === 189) || // underscore
        (event.shiftKey && code === 50) || // @
        (event.shiftKey && code === 57) // (
    ) {
        cm.execCommand('autocomplete')
    }
}

/*
 * Renders GraphQL documentation around the hint list
 */
const autocompletionDocRenderer = function (cm, data) {
    let wrapper, information, type, deprecation

    // When a hint result is selected, we touch the UI.
    CodeMirror.on(data, 'select', (ctx, el) => {
        // Only the first time (usually when the hint UI is first displayed)
        // do we create the wrapping node.
        if (!wrapper) {
            // Wrap the existing hint UI, so we have a place to put information.
            const hintsUl = el.parentNode
            const container = hintsUl.parentNode
            wrapper = document.createElement('section')
            container.appendChild(wrapper)

            // CodeMirror vertically inverts the hint UI if there is not enough
            // space below the cursor. Since this modified UI appends to the bottom
            // of CodeMirror's existing UI, it could cover the cursor. This adjusts
            // the positioning of the hint UI to accomodate.
            let top = hintsUl.style.top
            let bottom = ''
            const cursorTop = cm.cursorCoords().top
            if (parseInt(top, 10) < cursorTop) {
                top = ''
                bottom = window.innerHeight - cursorTop + 3 + 'px'
            }

            // Style the wrapper, remove positioning from hints. Note that usage
            // of this option will need to specify CSS to remove some styles from
            // the existing hint UI.
            wrapper.className = 'CodeMirror-hints default CodeMirror-GraphQL-hints-wrapper'
            wrapper.style.left = hintsUl.style.left
            wrapper.style.top = top
            wrapper.style.bottom = bottom
            hintsUl.style.left = ''
            hintsUl.style.top = ''

            // This "documentation" node will contain the additional info about the
            // highlighted typeahead option.
            const documentationWrapper = document.createElement('section')
            documentationWrapper.className = 'GraphQL-hint-documentation'

            type = document.createElement('section')
            type.className = 'GraphQL-hint-type'

            information = document.createElement('section')
            information.className = 'GraphQL-hint-description'

            deprecation = document.createElement('section')
            deprecation.className = 'GraphQL-hint-deprecation'

            documentationWrapper.append(type, information, deprecation);
            wrapper.append(hintsUl, documentationWrapper)

            const wrapperHeight = wrapper.clientHeight
            const currentTop = parseFloat(String(top).replace('px', ''))
            let newTop = currentTop
            if (wrapperHeight + currentTop > window.innerHeight) {
                newTop = window.innerHeight - 40 - wrapperHeight
            }

            wrapper.style.top = `${newTop}px`

            // When CodeMirror attempts to remove the hint UI, we detect that it was
            // removed from our wrapper and in turn remove the wrapper from the
            // original container.
            let mutationObserver = new MutationObserver(mutations => {
                mutations.forEach(mutation => {
                    if (mutation.removedNodes.length) {
                        const removedNodes = Array.from(mutation.removedNodes)
                        if (removedNodes.includes(hintsUl)) {
                            wrapper.parentNode.removeChild(wrapper);
                            wrapper = null;
                            information = null;
                            mutationObserver.disconnect();
                            mutationObserver = null;
                        }
                    }
                })
            });
            mutationObserver.observe(wrapper, {childList: true});
        }

        // Now that the UI has been set up, add info to information.
        information.innerHTML = ctx.description ?? "";
        type.textContent = ctx.type && ctx.type !== 'undefined' ? ctx.type : null;

        if (ctx.isDeprecated) {
            deprecation.innerHTML = ctx.deprecationReason;
            deprecation.style.display = null
        } else {
            deprecation.style.display = 'none'
        }
    })
}

export {autocompletionKeyUpListener, autocompletionDocRenderer}