import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="feeds-form"
export default class extends Controller {
    static targets = ['penTypeInputWrapper', 'preWeaningInput']

    connect() {
    }

    preWeaningInputTargetConnected(el) {
        el.addEventListener('input', (ev) => {
            if (this.hasPenTypeInputWrapperTarget) {
                const penTypeInputs = this.penTypeInputWrapperTarget.querySelectorAll('input[type="checkbox"]');
                if (el.checked) {
                    const farrowingPenTypeInput = this.penTypeInputWrapperTarget.querySelector('[value="farrowing"]')
                    if (farrowingPenTypeInput) {
                        farrowingPenTypeInput.checked = true;
                    }
                    penTypeInputs.forEach((cb) => {
                        if (cb !== farrowingPenTypeInput && cb.checked) {
                            cb.dataset.waschecked = true
                            cb.checked = false
                        }
                        cb.disabled = true
                    });
                } else {
                    penTypeInputs.forEach((cb) => {
                        cb.disabled = false
                        if (cb.dataset.waschecked) {
                            cb.checked = true
                            delete cb.dataset.waschecked
                        }
                    });
                }
            }
        })
    }
}
