import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

const getModelName = function (selection) {
    return i18n.t(`activerecord.models.${selection.value._type}`, {count: 1});
}

export default class UserAssignableAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('user_assignables', {keys: ['name'], ...options})
    }

    async fetch(query) {
        const results = [],
            types = this.getProperty("types", null);
        if (types == null || types.includes('distributor')) {
            results.push(this.getResults('distributor', query, this.extraParams));
        }
        if (types == null || types.includes('corporate_customer')) {
            results.push(this.getResults('corporate_customer', query, this.extraParams));
        }
        if (types == null || types.includes('customer')) {
            results.push(this.getResults('customer', query, this.extraParams));
        }
        if (types == null || types.includes('transporter')) {
            results.push(this.getResults('transporter', query, this.extraParams));
        }
        return (await Promise.all(results)).flat(1);
    }

    async getResults(type, query, ...extraParams) {
        const endpoint = type === 'transporter' ? type : `admin/${type}`
        const response = await fetch(this.addParameters(`/${endpoint}s/typeahead`, query, ...extraParams));
        const results = await response.json();
        results.forEach((result) => result._type = type)
        return results;
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `
      <span class="text-truncate flex-fill">${selection.match}</span>
      <span class="text-muted small">
        ${getModelName(selection)}
      </span>`;
        if (selection.value.full_address) {
            const address = document.createElement("address");
            address.classList.add('small', 'text-muted', 'm-0', 'w-100');
            address.textContent = selection.value.full_address;
            node.classList.add('flex-wrap');
            node.append(address);
        }
    }

    styleFormattedValue(node, selection) {
        const info = document.createElement("span");
        info.classList.add('text-muted', 'small')
        info.textContent = getModelName(selection);
        node.append(" ", info);
    }

    onOptionSelected(selection, valueInput) {
        if (valueInput) {
            const typeInputId = valueInput.id.replace(/_id$/, '_type'),
                typeInput = document.getElementById(typeInputId);
            if (typeInput == null) {
                console.error("Cannot find location type input with id: ", typeInputId);
            } else if (typeInput.value !== selection.value._type) {
                typeInput.value = selection.value._type;
                typeInput.dispatchEvent(new Event('change', {'bubbles': true}));
            }
        }
    }
}
