import {Controller} from "@hotwired/stimulus";
import Locale from "../locale";
import I18n from "../i18n";

const categories = ['breeding_gilts', 'breeding_sows', 'breeding_boars', 'sucklings', 'nursery', 'rearing']

export default class extends Controller {
    static targets = ["totalStartingBalance", "totalArrived", "totalAdded", "totalDropouts", "totalDeparted",
        "totalEndingBalance", "totalAverageInventory"];
    static values = {params: Object}


    initialize() {
        this._showDataset = this._showDataset.bind(this)
        this._showTotals = this._showTotals.bind(this)
    }

    connect() {
        this.abortController = new AbortController();
        let abortSignal = this.abortController.signal,
            params = this.hasParamsValue && this.paramsValue && Object.keys(this.paramsValue).length > 0 ?
                new URLSearchParams(this.paramsValue).toString() : "";

        categories.forEach(category => {
            fetch(`/reports/livestock_balance/data/${category}.json?${params}`, {signal: abortSignal})
                .then(r => r.ok ? r.json() : null)
                .then(value => this._showDataset(category, value))
                .catch(err => {
                    if (!abortSignal.aborted) {
                        console.error(`Cannot fetch data for ${category}: ${err.message}`);
                    }
                });
        });
    }

    disconnect() {
        if (this.abortController) {
            this.abortController.abort();
        }
    }

    _showDataset(pigCategory, value) {
        if (value == null) {
            const icon = document.createElement('i'),
                element = this.element.querySelector(`#${pigCategory} .loading-placeholder`);
            icon.classList.add('fa', 'fa-exclamation-circle', 'text-danger', 'mr-2');
            element.innerHTML = "";
            element.classList.add('font-weight-bold');
            element.append(icon);
            element.append(I18n.t('generic_error'))
            return
        }
        if (this.datasets == null) {
            this.datasets = {}
        }

        let html = value.html
        delete value.html
        this.datasets[pigCategory] = value

        this.element.querySelector(`#${pigCategory}`).innerHTML = html

        if (categories.every(category => this.datasets[category] != null)) {
            this._showTotals(this.datasets)
        }
    }

    _showTotals(allValues) {
        const intFormat = new Intl.NumberFormat(Locale.get()),
            floatFormat = new Intl.NumberFormat(Locale.get(), {minimumFractionDigits: 1, maximumFractionDigits: 1});
        this._showTotal(allValues, 'starting_balance', this.totalStartingBalanceTarget, intFormat);
        this._showTotal(allValues, 'arrived', this.totalArrivedTarget, intFormat);
        this._showTotal(allValues, 'added', this.totalAddedTarget, intFormat);
        this._showTotal(allValues, 'dropouts', this.totalDropoutsTarget, intFormat);
        this._showTotal(allValues, 'departed', this.totalDepartedTarget, intFormat);
        this._showTotal(allValues, 'ending_balance', this.totalEndingBalanceTarget, intFormat);
        this._showTotal(allValues, 'average_inventory', this.totalAverageInventoryTarget, floatFormat);
    }

    _showTotal(allValues, dataset, target, numberFormat) {
        let total = 0;
        categories.forEach(category => {
            let value = allValues[category][dataset]
            if (value) {
                if (typeof value === "string") {
                    if (dataset === "average_inventory") {
                        value = Math.round(parseFloat(value) * 10) / 10;
                    } else {
                        value = parseInt(value);
                    }
                }
                total += value
            }
        });
        if (total > 0) {
            target.textContent = numberFormat.format(total)
        }
    }
}