import Rails from '@rails/ujs';

const DashboardConfigurator = {};

DashboardConfigurator.autoScroller = {};
DashboardConfigurator.autoScroller.scrollWindowInterval = 0;
DashboardConfigurator.autoScroller.isAutoScrolling = false;
DashboardConfigurator.autoScroller.autoScrollingSpeed = null;
DashboardConfigurator.autoScroller.stopAutoScrolling = function () {
    clearInterval(DashboardConfigurator.autoScroller.scrollWindowInterval);
    DashboardConfigurator.autoScroller.isAutoScrolling = false;
};
DashboardConfigurator.autoScroller.startAutoScrolling = function (draggable, speed) {
    if (speed === 0) {
        return;
    }
    let scrollElement = $(document);
    DashboardConfigurator.autoScroller.autoScrollingSpeed = speed;

    DashboardConfigurator.autoScroller.isAutoScrolling = true;
    clearInterval(DashboardConfigurator.autoScroller.scrollWindowInterval);
    DashboardConfigurator.autoScroller.scrollWindowInterval = setInterval(function () {
        if (DashboardConfigurator.autoScroller.autoScrollingSpeed === 0) {
            return;
        }
        let deltaY = Math.round(Math.pow(DashboardConfigurator.autoScroller.autoScrollingSpeed, 3) * 40), dragY;
        if (deltaY < 0 && window.pageYOffset + deltaY <= 0) {
            // stopAutoScrolling();
            scrollElement.scrollTop(0);
        } else if (deltaY > 0 && Math.ceil(window.innerHeight + window.pageYOffset + deltaY) >= scrollElement.height()) {
            // stopAutoScrolling();
            scrollElement.scrollTop(scrollElement.get(0).scrollHeight);
        } else {
            scrollElement.scrollTop(scrollElement.scrollTop() + deltaY);

            if (DashboardConfigurator.hammer.dragHandler.active) {
                let $draggable = DashboardConfigurator.hammer.dragHandler.$draggable,
                    dragDelta = $draggable.data('drag-delta'), scrollDelta = $draggable.data('scroll-delta');
                if ($draggable && dragDelta && scrollDelta) {
                    scrollDelta.y += deltaY;
                    $draggable.css('transform', 'translate3D(' + (dragDelta.x + scrollDelta.x) + 'px, ' + (dragDelta.y + scrollDelta.y) + 'px, 0)');
                }
            }
            if (DashboardConfigurator.hammer.resizeRowHandler.active) {
                let $row = DashboardConfigurator.hammer.resizeRowHandler.$row;
                if ($row) {
                    // TODO adjust row height (per 5vh)
                }
            }
        }
    }, 10);
};

DashboardConfigurator.contextMenu = {};
DashboardConfigurator.contextMenu.closeListener = function (e) {
    if ($(e.target).closest('.context-menu').length === 0) {
        e.preventDefault();

        $('.context-menu').hide();
        $(document).off('scroll mousedown', DashboardConfigurator.contextMenu.closeListener);
    }
};
DashboardConfigurator.contextMenu.bind = function () {
    $('#main-content').on('contextmenu', '.draggable', function (e) {
        if (!(e.shiftKey || e.ctrlKey || e.altKey || e.metaKey)) {
            let $contextMenu = $('.context-menu'),
                $widget = $(this).find('.dashboard-widget-wrapper'), $content;
            if ($widget.length === 0) {
                return;
            }
            if ($contextMenu.length === 0) {
                $contextMenu = $('<div></div>', {class: 'context-menu'});

                $content = $('<a></a>', {
                    href: 'javascript:;',
                    class: 'nav-link text-danger remove_action'
                }).html('<i class="fas fa-trash"></i>');
                if (DashboardConfigurator.configuration && DashboardConfigurator.configuration.localization && DashboardConfigurator.configuration.localization.contextMenu) {
                    $content.append(' ').append(DashboardConfigurator.configuration.localization.contextMenu.remove);
                }
                $contextMenu.append($('<div></div>', {class: 'nav nav-pills nav-stacked'}).append($content));

                $('#container-root').append($contextMenu);
            }

            $contextMenu.css({left: e.clientX, top: e.clientY});
            $contextMenu.show();
            $contextMenu.find('.remove_action').on('click', function () {
                DashboardConfigurator.hammer.dragHandler.removeColumn($widget.closest('.col-12, .col-6, .col-4, .col-3'));
                $contextMenu.hide();
            });

            $(document).on('scroll mousedown', DashboardConfigurator.contextMenu.closeListener);
            e.preventDefault();
        }
    });
};

DashboardConfigurator.widgetForm = {};
DashboardConfigurator.widgetForm.configure = function (configuration) {
    let $form = $('form#new_dashboard_widget'),
        showBoundsInputs = configuration.displayType === 'current',
        // Distribution format always supports relative format
        showFormatInput = configuration.displayType === 'distribution' || !!configuration.supportsRelative;

    $form.find('.bounds_inputs').toggle(showBoundsInputs).find('input').prop('disabled', !showBoundsInputs);
    $form.find('.form-group.dashboard_widget_configuration_format').toggle(showFormatInput).find('input').prop('disabled', !showFormatInput);

    if (DashboardConfigurator.configuration && DashboardConfigurator.configuration.configureWidgetUrl) {
        let formUrl = DashboardConfigurator.configuration.configureWidgetUrl.replace('%data-type%', configuration.dataType).replace('%display-type%', configuration.displayType);
        $form.attr('action', formUrl);
        Rails.fire($form.get(0), 'submit');
    } else {
        console.error('DashboardConfigurator.configuration.configureWidgetUrl is not configured');
    }
};

DashboardConfigurator.hammer = {};
DashboardConfigurator.hammer.manager = null;
DashboardConfigurator.hammer.stop = function () {
    DashboardConfigurator.hammer.manager.stop();
    $.each(DashboardConfigurator.hammer.manager.recognizers, function (i, recognizer) {
        recognizer.state = Hammer.STATE_CANCELLED;
    });
};

DashboardConfigurator.hammer.dragHandler = {};
DashboardConfigurator.hammer.dragHandler.active = false;
DashboardConfigurator.hammer.dragHandler.$draggable = null;

DashboardConfigurator.hammer.dragHandler.removeColumn = function ($column, $row) {
    if ($row == null) {
        $row = $column.closest('.row.layout_row');
    }
    // If no draggables or dropzones will be left, remove column.
    if ($row.find('.draggable, .draggable-dropzone').length - $column.find('.draggable, .draggable-dropzone').length === 0) {
        $row.css({height: $row.height(), 'min-height': ''})
            .addClass('removing')
            .animate({height: 0}, {
                duration: 300, done: function () {
                    $row.remove();
                }
            });
    } else {
        $row.css({height: ''});
        $column.remove();
        let $columns = $row.find('.col-12, .col-6, .col-4, .col-3'), columnClass;
        switch ($columns.length) {
            case 1:
                columnClass = 'col-12';
                break;
            case 2:
                columnClass = 'col-6';
                break;
            case 3:
                columnClass = 'col-4';
                break;
            case 4:
                columnClass = 'col-3';
                break;
        }
        if (columnClass) {
            $columns.removeClass('col-12 col-6 col-4 col-3').addClass(columnClass);
        }
    }
};

DashboardConfigurator.hammer.dragHandler.listeners = {};
DashboardConfigurator.hammer.dragHandler.listeners.panStart = function (e) {
    if (!DashboardConfigurator.hammer.dragHandler.active) {
        DashboardConfigurator.hammer.dragHandler.stop();
        return;
    }

    let $draggable = DashboardConfigurator.hammer.dragHandler.$draggable,
        $contentWrapper = $('#content-wrapper'),
        $draggableContainer = $('<div />', {
            class: 'draggable-container'
        });

    if ($draggable == null) {
        let pointer = e.pointers[0];
        if (pointer == null) {
            return;
        }
        $draggable = $(document.elementFromPoint(pointer.clientX, pointer.clientY)).closest('.draggable');
        if ($draggable.length === 0) {
            DashboardConfigurator.hammer.stop();
            return;
        }
        DashboardConfigurator.hammer.dragHandler.$draggable = $draggable;
    }

    $(document.body).addClass('is-dragging');

    let $parent = $draggable.parent(),
        $dropZone = $('<div></div>', {class: 'draggable-dropzone'}),
        $trashCan = $('.trash-can');

    if ($trashCan.length === 0) {
        $trashCan = $('<div></div>', {class: 'trash-can'});
        $('#sidebar').append($trashCan);
    }
    if ($draggable.find('.dashboard-widget-wrapper').attr('data-widget-id')) {
        $trashCan.html('<i class="fas fa-times"></i>');
        if (DashboardConfigurator.configuration && DashboardConfigurator.configuration.localization && DashboardConfigurator.configuration.localization.hammer) {
            $trashCan.append('<br/>').append(DashboardConfigurator.configuration.localization.hammer.removeFromDashboard);
        }
    } else {
        $trashCan.html('<i class="fas fa-trash-alt"></i>');
        if (DashboardConfigurator.configuration && DashboardConfigurator.configuration.localization && DashboardConfigurator.configuration.localization.hammer) {
            $trashCan.append('<br/>').append(DashboardConfigurator.configuration.localization.hammer.deleteWidget);
        }
    }
    if ($draggable.find('.dashboard-widget-wrapper').hasClass('dashboard-widget-unadded')) {
        $trashCan.addClass('hidden');
    } else {
        $trashCan.removeClass('hidden');
    }

    $dropZone.css('height', $parent.closest('.row.layout_row').height());

    $draggableContainer.appendTo($contentWrapper)
    // Set position to relative so we can absolutely position the draggable-container in the wrapper.
    $contentWrapper.css({position: 'relative'});

    $draggable.css($draggable.offset());
    $draggable.find('.draggable-content').css({width: $draggable.width()});
    $draggable.appendTo($draggableContainer);
    $draggable.css({position: 'absolute'}).addClass('is-dragging').removeClass('is-pressed');

    $draggable.data('drag-delta', {x: 0, y: 0});
    $draggable.data('scroll-delta', {x: 0, y: 0});

    $parent.append($dropZone);
};

DashboardConfigurator.hammer.dragHandler.listeners.panMove = function (e) {
    if (!DashboardConfigurator.hammer.dragHandler.active) {
        DashboardConfigurator.hammer.dragHandler.stop();
        return;
    }

    let pointer = e.pointers[0],
        $draggable = DashboardConfigurator.hammer.dragHandler.$draggable;
    if (pointer == null) {
        return;
    }

    if ($draggable == null) {
        DashboardConfigurator.hammer.stop();
        return;
    }

    if (pointer.clientY < 100) {
        let speed = -Math.max(100 - pointer.clientY, 0) / 100.0;
        if (!DashboardConfigurator.autoScroller.isAutoScrolling) {
            DashboardConfigurator.autoScroller.startAutoScrolling(this, speed);
        } else {
            DashboardConfigurator.autoScroller.autoScrollingSpeed = speed;
        }
        return;
    } else if (pointer.clientY > window.innerHeight - 100) {
        let speed = Math.max(0, 100 - (window.innerHeight - pointer.clientY)) / 100.0;

        if (!DashboardConfigurator.autoScroller.isAutoScrolling) {
            DashboardConfigurator.autoScroller.startAutoScrolling(this, speed);
        } else {
            DashboardConfigurator.autoScroller.autoScrollingSpeed = speed;
        }
        return;
    }
    DashboardConfigurator.autoScroller.stopAutoScrolling();

    $draggable.hide();
    let hoverElement = document.elementFromPoint(pointer.clientX, pointer.clientY);
    $draggable.show();

    let dragDelta = $draggable.data('drag-delta'), scrollDelta = $draggable.data('scroll-delta'),
        $dropZone = $('.draggable-dropzone'),
        onlyHasDropZone = function ($row, $dropZoneRow) {
            return $dropZoneRow.length && $dropZoneRow.get(0) === $row.get(0) && $row.children().not('.resizer').length === 1;
        };

    dragDelta.x = e.deltaX;
    dragDelta.y = e.deltaY;
    $draggable.css('transform', 'translate3D(' + (dragDelta.x + scrollDelta.x) + 'px, ' + (dragDelta.y + scrollDelta.y) + 'px, 0)');

    if ($dropZone.length === 0) {
        $dropZone = $('<div></div>', {class: 'draggable-dropzone'});
    }
    let $hoverDraggable = $(hoverElement).closest('.draggable'),
        $hoverRow = $(hoverElement).closest('.row.layout_row'),
        isDeleting = $(hoverElement).closest('#sidebar').length > 0,
        $dropZoneRow = $dropZone.closest('.row.layout_row'),
        $dropZoneColumn = $dropZone.closest('.col-12, .col-6, .col-4, .col-3');

    if (isDeleting) {
        $('.trash-can').addClass('active');
        $draggable.css('opacity', 0.2).addClass('is-deleting');
        $dropZone.remove();
    } else {
        $('.trash-can').removeClass('active');
        $draggable.css('opacity', '').removeClass('is-deleting');
    }
    if ($hoverRow.length || isDeleting) {
        let hoverRowOffset = $hoverRow.offset();

        if (onlyHasDropZone($dropZoneRow, $dropZoneRow)) {
            // Set a min height so the row does not collapse when removing the dropzone
            $dropZoneRow.css({'min-height': $dropZoneRow.children().height()});
        }

        if (!isDeleting) {
            if ($dropZone.length === 0) {
                $dropZone = $('<div></div>', {class: 'draggable-dropzone'});
            }

            if (pointer.pageY > hoverRowOffset.top && pointer.pageY < hoverRowOffset.top + 41) {
                if (!onlyHasDropZone($hoverRow, $dropZoneRow) && !onlyHasDropZone($hoverRow.prev('.row.layout_row'), $dropZoneRow)) {
                    let $newRow = $('<div></div>', {class: 'row layout_row mb-3'}),
                        $newColumn = $('<div></div>', {class: 'col-12'});

                    $dropZone.css('height', 1);
                    $newRow.append($newColumn.append($dropZone)).append($('<div></div>', {class: 'resizer'}));
                    $newRow.insertBefore($hoverRow);

                    $dropZone.stop().animate({height: 200}, {duration: 100});

                    let leftChange = ($draggable.find('.draggable-content').width() - $dropZone.width()) / 2;
                    if (leftChange < 0) {
                        leftChange = '-=' + -leftChange;
                    } else {
                        leftChange = '+=' + leftChange;
                    }
                    $draggable.find('.draggable-content').stop().animate({width: $dropZone.width()}, {duration: 200});
                    $draggable.stop().animate({left: leftChange}, {duration: 200});
                }
            } else if (pointer.pageY > hoverRowOffset.top + $hoverRow.height() - 41 && pointer.pageY < hoverRowOffset.top + $hoverRow.height()) {
                if (!onlyHasDropZone($hoverRow, $dropZoneRow) && !onlyHasDropZone($hoverRow.next('.row.layout_row'), $dropZoneRow)) {
                    let $newRow = $('<div></div>', {class: 'row layout_row mb-3'}),
                        $newColumn = $('<div></div>', {class: 'col-12'});

                    $dropZone.css('height', 1);
                    $newRow.append($newColumn.append($dropZone)).append($('<div></div>', {class: 'resizer'}));
                    $newRow.insertAfter($hoverRow);

                    $dropZone.stop().animate({height: 200}, {duration: 100});

                    let leftChange = ($draggable.find('.draggable-content').width() - $dropZone.width()) / 2;
                    if (leftChange < 0) {
                        leftChange = '-=' + -leftChange;
                    } else {
                        leftChange = '+=' + leftChange;
                    }
                    $draggable.find('.draggable-content').stop().animate({width: $dropZone.width()}, {duration: 200});
                    $draggable.stop().animate({left: leftChange}, {duration: 200});
                }
            } else {
                let widgetCount = $hoverRow.find('.draggable').length;
                if (widgetCount >= 4) {
                    if (!$hoverRow.hasClass('row-full')) {
                        let $rowFullMessage = $('<div></div>', {class: 'row-full-message'});
                        if (DashboardConfigurator.configuration && DashboardConfigurator.configuration.localization && DashboardConfigurator.configuration.localization.hammer) {
                            $rowFullMessage.append($('<span></span>').html(DashboardConfigurator.configuration.localization.hammer.rowFull));
                        }
                        $rowFullMessage.hide();
                        $hoverRow.addClass('row-full').append($rowFullMessage);
                        $rowFullMessage.fadeIn('fast');
                    } else {
                        $hoverRow.find('.row-full-message').fadeIn('fast');
                    }
                } else if ($hoverDraggable.length && widgetCount > 0) {
                    let columnClass, $dropZoneColumn;
                    switch (widgetCount) {
                        case 1:
                            columnClass = 'col-6';
                            break;
                        case 2:
                            columnClass = 'col-4';
                            break;
                        case 3:
                            columnClass = 'col-3';
                            break;
                    }
                    $hoverRow.find('.col-12, .col-6, .col-4, .col-3').removeClass('col-12 col-6 col-4 col-3').addClass(columnClass);
                    let $hoverColumn = $hoverDraggable.closest('.' + columnClass);

                    if ($dropZoneRow.get(0) === $hoverRow.get(0)) {
                        $dropZoneColumn = $dropZone.closest('.' + columnClass);
                        if ($dropZoneColumn.index() > $hoverColumn.index()) {
                            $dropZoneColumn.insertBefore($hoverColumn);
                        } else {
                            $dropZoneColumn.insertAfter($hoverColumn);
                        }
                    } else {
                        $dropZoneColumn = $('<div></div>', {class: columnClass}).append($dropZone);
                        $dropZone.stop().css('height', $hoverRow.height());

                        if (pointer.pageX > $hoverDraggable.offset().left + $hoverDraggable.width() / 2) {
                            $dropZoneColumn.insertAfter($hoverColumn);
                        } else {
                            $dropZoneColumn.insertBefore($hoverColumn);
                        }
                    }

                    if ($draggable.find('.draggable-content').width() !== $dropZone.width()) {
                        let leftChange = ($draggable.find('.draggable-content').width() - $dropZone.width()) / 2;
                        if (leftChange < 0) {
                            leftChange = '-=' + -leftChange;
                        } else {
                            leftChange = '+=' + leftChange;
                        }
                        $draggable.find('.draggable-content').stop().animate({width: $dropZone.width()}, {duration: 200});
                        $draggable.stop().animate({left: leftChange}, {duration: 200});
                    }
                }
            }
        }
    } else {
        let $allRows = $('#main-content').find('.row.layout_row'), $lastRow = $($allRows.get($allRows.length - 1));
        if ($allRows.length === 0 || pointer.pageY > $lastRow.offset().top + $lastRow.height()) {
            if (!onlyHasDropZone($lastRow, $dropZoneRow)) {
                if ($dropZone.length === 0) {
                    $dropZone = $('<div></div>', {class: 'draggable-dropzone'});
                }

                let $newRow = $('<div></div>', {class: 'row layout_row mb-3'}),
                    $newColumn = $('<div></div>', {class: 'col-12'});

                $dropZone.css('height', 1);
                $newRow.append($newColumn.append($dropZone)).append($('<div></div>', {class: 'resizer'}));
                if ($allRows.length === 0) {
                    $newRow.insertAfter('.save-panel');
                } else {
                    $newRow.insertAfter($lastRow);
                }

                $dropZone.stop().animate({height: 200}, {duration: 100});

                let leftChange = ($draggable.find('.draggable-content').width() - $dropZone.width()) / 2;
                if (leftChange < 0) {
                    leftChange = '-=' + -leftChange;
                } else {
                    leftChange = '+=' + leftChange;
                }
                $draggable.find('.draggable-content').stop().animate({width: $dropZone.width()}, {duration: 200});
                $draggable.stop().animate({left: leftChange}, {duration: 200});
                $draggable.css('opacity', '').removeClass('is-deleting');
            }
        }
    }

    if ($dropZoneRow.length && $dropZoneRow.find('.draggable-dropzone').length === 0) {
        DashboardConfigurator.hammer.dragHandler.removeColumn($dropZoneColumn, $dropZoneRow);
    }else{
        $dropZoneRow.css({'min-height': ''})
    }
    $(document.body).find('.row.row-full').not($hoverRow).find('.row-full-message').fadeOut({
        duration: 'fast',
        done: function () {
            $(this).closest('.row.row-full').removeClass('row-full');
            $(this).remove();
        }
    });
};
DashboardConfigurator.hammer.dragHandler.listeners.panEnd = function (e) {
    DashboardConfigurator.autoScroller.stopAutoScrolling();

    let $dashboardWrapper = $(document.body),
        $draggable = DashboardConfigurator.hammer.dragHandler.$draggable,
        chart;
    if ($draggable != null) {
        $draggable.css({
            position: '',
            left: '',
            top: '',
            right: '',
            bottom: '',
            transform: ''
        }).removeClass('is-dragging is-pressed');
        $draggable.find('.draggable-content').stop().css({width: ''});

        if ($draggable.hasClass('is-deleting')) {
            let $widgetWrapper = $draggable.find('.dashboard-widget-wrapper');
            if ($widgetWrapper.attr('data-widget-id')) {
                $draggable.removeClass('is-deleting').css('opacity', '');
                $widgetWrapper.addClass('dashboard-widget-unadded').css({height: ''});
                $('.my_widget_list').append($draggable);

                chart = $widgetWrapper.find('.chart-container').data('chart');
                if (chart) {
                    chart.resize();
                }
            } else if ($widgetWrapper.hasClass('dashboard-widget-configuration-result')) {
                $('#widget-configure-result').html($draggable.css({opacity: ''}));
            } else {
                $draggable.remove();
            }
        } else {
            let $dropZone = $('.draggable-dropzone'),
                height = $dropZone.closest('.row.layout_row').attr('data-widget-height'),
                $widgetWrapper = $draggable.find('.dashboard-widget-wrapper');

            if (!height) {
                height = '25vh';
            }
            if ($dropZone.length) {
                $dropZone.replaceWith($draggable);
            } else {
                $('<div></div>', {class: 'row layout_row mb-3'})
                    .attr('data-widget-height', height)
                    .append($('<div></div>', {class: 'col-12'}).append($draggable))
                    .append($('<div></div>', {class: 'resizer'}))
                    .insertAfter($dashboardWrapper.find('.row.layout_row').last());
            }

            $widgetWrapper.css('height', height);

            $dashboardWrapper.find('.row.layout_row').css({height: ''}).filter('.row-full').find('.row-full-message').fadeOut({
                duration: 'fast',
                done: function () {
                    $(this).closest('.row.row-full').removeClass('row-full');
                    $(this).remove();
                }
            });

            if ($widgetWrapper.hasClass('dashboard-widget-unadded')) {
                $widgetWrapper.removeClass('dashboard-widget-unadded');
            }
            if ($widgetWrapper.hasClass('dashboard-widget-configuration-result')) {
                $widgetWrapper.removeClass('dashboard-widget-configuration-result');
                if ($('.slider-page.standard_widget_result.active').length !== 0) {
                    let $form = $('form#new_dashboard_widget'),
                        $nextLink = $('.slider-page.standard_widget_result.active .secret_next_link');
                    $('.slider-page.' + $nextLink.attr('href').substr(1)).addClass('next');
                    requestAnimationFrame(function () {
                        $nextLink.click();
                    });

                    $form.get(0).reset();
                }
            }

            chart = $widgetWrapper.find('.chart-container').data('chart');
            if (chart) {
                chart.resize();
            }
        }
    }

    $dashboardWrapper.removeClass('is-dragging');
    $('.draggable-container').remove();
    $('#content-wrapper').css({position: ''});

    DashboardConfigurator.hammer.dragHandler.stop();
};

DashboardConfigurator.hammer.dragHandler.start = function ($draggable) {
    DashboardConfigurator.hammer.dragHandler.active = true;
    DashboardConfigurator.hammer.dragHandler.$draggable = $draggable;
    $draggable.addClass('is-pressed');

    DashboardConfigurator.hammer.manager
        .on('panstart', DashboardConfigurator.hammer.dragHandler.listeners.panStart)
        .on('panmove', DashboardConfigurator.hammer.dragHandler.listeners.panMove)
        .on('panend', DashboardConfigurator.hammer.dragHandler.listeners.panEnd);
};
DashboardConfigurator.hammer.dragHandler.stop = function () {
    let $draggable = DashboardConfigurator.hammer.dragHandler.$draggable;
    DashboardConfigurator.hammer.dragHandler.active = false;
    DashboardConfigurator.hammer.dragHandler.$draggable = null;

    DashboardConfigurator.hammer.manager
        .off('panstart', DashboardConfigurator.hammer.dragHandler.listeners.panStart)
        .off('panmove', DashboardConfigurator.hammer.dragHandler.listeners.panMove)
        .off('panend', DashboardConfigurator.hammer.dragHandler.listeners.panEnd);

    if ($draggable != null) {
        $draggable.removeClass('is-dragging is-pressed');
    }
};

DashboardConfigurator.hammer.resizeRowHandler = {};
DashboardConfigurator.hammer.resizeRowHandler.active = false;
DashboardConfigurator.hammer.resizeRowHandler.$row = null;

DashboardConfigurator.hammer.resizeRowHandler.listeners = {};
DashboardConfigurator.hammer.resizeRowHandler.listeners.panStart = function (e) {
    if (!DashboardConfigurator.hammer.resizeRowHandler.active) {
        DashboardConfigurator.hammer.resizeRowHandler.stop();
        return;
    }

    let $mainContainer = $('#main-content'),
        $row = DashboardConfigurator.hammer.resizeRowHandler.$row;

    $mainContainer.css('min-height', $mainContainer.outerHeight());
    $(document.body).addClass('is-resizing-ns');
    $row.addClass('is-resizing');
};
DashboardConfigurator.hammer.resizeRowHandler.listeners.panMove = function (e) {
    if (!DashboardConfigurator.hammer.resizeRowHandler.active) {
        DashboardConfigurator.hammer.resizeRowHandler.stop();
        return;
    }

    let pointer = e.pointers[0],
        $row = DashboardConfigurator.hammer.resizeRowHandler.$row;
    if (pointer == null || $row == null) {
        return;
    }

    let pixels = Math.max(200, pointer.pageY - $row.offset().top),
        viewHeight = Math.min(Math.floor(pixels * 20.0 / window.innerHeight) * 5, 90);
    $row.attr('data-widget-height', viewHeight + 'vh').find('.dashboard-widget-wrapper').height(viewHeight + 'vh');
};
DashboardConfigurator.hammer.resizeRowHandler.listeners.panEnd = function (e) {
    DashboardConfigurator.autoScroller.stopAutoScrolling();

    $('#main-content').css('min-height', '');
    $(document.body).removeClass('is-resizing-ns');

    let $row = DashboardConfigurator.hammer.resizeRowHandler.$row, chart;
    if ($row) {
        $row.find('.chart-container').each(function () {
            chart = $(this).data('chart');
            if (chart) {
                chart.resize();
            }
        });
    }

    DashboardConfigurator.hammer.resizeRowHandler.stop();
};

DashboardConfigurator.hammer.resizeRowHandler.start = function ($row) {
    DashboardConfigurator.hammer.resizeRowHandler.active = true;
    DashboardConfigurator.hammer.resizeRowHandler.$row = $row;
    $row.addClass('is-pressed');

    DashboardConfigurator.hammer.manager
        .on('panstart', DashboardConfigurator.hammer.resizeRowHandler.listeners.panStart)
        .on('panmove', DashboardConfigurator.hammer.resizeRowHandler.listeners.panMove)
        .on('panend', DashboardConfigurator.hammer.resizeRowHandler.listeners.panEnd);
};
DashboardConfigurator.hammer.resizeRowHandler.stop = function () {
    let $resizeRow = DashboardConfigurator.hammer.resizeRowHandler.$row;
    DashboardConfigurator.hammer.resizeRowHandler.active = false;
    DashboardConfigurator.hammer.resizeRowHandler.$row = null;

    DashboardConfigurator.hammer.manager
        .off('panstart', DashboardConfigurator.hammer.resizeRowHandler.listeners.panStart)
        .off('panmove', DashboardConfigurator.hammer.resizeRowHandler.listeners.panMove)
        .off('panend', DashboardConfigurator.hammer.resizeRowHandler.listeners.panEnd);

    if ($resizeRow != null) {
        $resizeRow.removeClass('is-resizing is-pressed').css('transition', '');
    }
};

DashboardConfigurator.hammer.bind = function () {
    DashboardConfigurator.hammer.manager = new Hammer.Manager(document.body, {
        recognizers: [
            [Hammer.Pan, {direction: Hammer.DIRECTION_ALL}],
            [Hammer.Press, {time: 0}]
        ]
    });

    DashboardConfigurator.hammer.manager.on('press', function (e) {
        let pointer = e.pointers[0];
        if (pointer == null) {
            return;
        }
        let $hoverElement = $(document.elementFromPoint(pointer.clientX, pointer.clientY)),
            $hoverRow = $hoverElement.closest('.row.layout_row'), $draggable = $hoverElement.closest('.draggable');
        if ($hoverRow.length && pointer.pageY > $hoverRow.offset().top + $hoverRow.height() - 20) {
            DashboardConfigurator.hammer.resizeRowHandler.start($hoverRow);
        } else if ($draggable.length) {
            DashboardConfigurator.hammer.dragHandler.start($draggable);
        } else {
            DashboardConfigurator.hammer.stop();
        }
    }).on('pressup', function () {
        DashboardConfigurator.hammer.resizeRowHandler.stop();
        DashboardConfigurator.hammer.dragHandler.stop();
    });
};

DashboardConfigurator.sidebarPager = {};
DashboardConfigurator.sidebarPager.slidePage = function (direction) {
    if (direction !== 'forward' && direction !== 'backward') {
        console.error('slidePage(): direction must be either "forward" or "backward"');
        return;
    }
    let backward = direction === 'backward',
        $previousPage = $('.slider-page.previous'),
        $nextPage = $('.slider-page.next'),
        $activePage = $('.slider-page.active'),
        $activatingPage = backward ? $previousPage : $nextPage;

    if ($activatingPage.length === 0) {
        console.error("No page to go to (Going " + direction + ")");
        return;
    }
    $('.slider-page.entering, .slider-page.exiting').removeClass('entering exiting');

    $previousPage.removeClass('previous');
    $nextPage.removeClass('next');
    $activePage
        .addClass(backward ? 'next' : 'previous').addClass('exiting').removeClass('active')
        .one('transitionend', function () {
            $(this).removeClass('exiting');
        });
    $activatingPage
        .addClass('active entering')
        .one('transitionend', function () {
            $(this).removeClass('entering');
        });
    if (backward) {
        $activatingPage.prev('.slider-page').addClass('previous');
    } else {
        $activatingPage.next('.slider-page').addClass('next');
    }
};

DashboardConfigurator.configuration = {};
DashboardConfigurator.initialize = function (configuration) {
    DashboardConfigurator.configuration = configuration;
    DashboardConfigurator.contextMenu.bind();
    DashboardConfigurator.hammer.bind();

    $('.slider-pages').find('a[data-slide]').on('click', function (e) {
        e.preventDefault();
        DashboardConfigurator.sidebarPager.slidePage($(this).attr('data-slide'));
    });
    $('.save-panel .save_button').on('click', function (e) {
        e.preventDefault();
        DashboardConfigurator.save();
    });
};

DashboardConfigurator.save = function () {
    let configuration = {rows: []}, row, column;
    $('#main-content').find('.row.layout_row').each(function () {
        row = {height: $(this).attr('data-widget-height'), columns: []};
        $(this).find('.dashboard-widget-wrapper').each(function () {
            let $this = $(this);
            if ($this.attr('data-widget-id')) {
                column = {widget_id: $(this).attr('data-widget-id')}
            } else if ($this.attr('data-widget-type')) {
                column = {
                    name: $this.attr('data-name'),
                    description: $this.attr('data-description'),
                    data_type: $this.attr('data-widget-type'),
                    display_type: $this.attr('data-display-type'),
                    format: $this.attr('data-format'),
                    bounds: {
                        minimum: $this.attr('data-minimum-bound'),
                        maximum: $this.attr('data-maximum-bound')
                    }
                };
                if ($this.attr('data-period-duration')) {
                    column['period_duration'] = $this.attr('data-period-duration');
                } else {
                    column['period_begin'] = $this.attr('data-period-begin');
                    column['period_end'] = $this.attr('data-period-end');
                }
            } else {
                // Skip this column
                return;
            }
            row.columns.push(column);
        });
        if (row.columns.length) {
            configuration.rows.push(row);
        }
    });

    $.ajax({
        url: window.location,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
            dashboard_layout: {configuration: configuration}
        })
    }).done(function () {
        Turbo.visit('/');
    }).fail(function (jqXHR, textStatus) {
        // TODO show error message
    });
};

module.exports = DashboardConfigurator;