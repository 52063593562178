import {Controller} from "@hotwired/stimulus";
import consumer from '../channels/consumer';
import WizardProgressBar from "../wizard_progress_bar";

export default class extends Controller {
    static targets = ['customerInput', 'fileInput', 'confirmationForm', "progressBar", "itemsProcessed"];

    connect() {
        if (this.hasProgressBarTarget || this.hasItemsProcessedTarget) {
            this.subscription = consumer.subscriptions.create(
                {
                    channel: "ImportStatusChannel",
                    id: this.data.get("id"),
                },
                {
                    received: this._received.bind(this),
                }
            );
        }
    }

    disconnect() {
        super.disconnect();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    customerInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    fileInputTargetConnected(el) {
        el.addEventListener('change', () => {
            this._setConfirmationEnabled(el.value && el.value.length > 0);
        });
    }

    _setConfirmationEnabled(whether) {
        const disabled = !whether;
        this.confirmationFormTarget
            .querySelectorAll('input[type=submit],button[type=submit]')
            .forEach(submitButton => {
                submitButton.disabled = disabled;
                submitButton.classList.toggle('disabled', disabled);
            });
    }

    _received(data) {
        if (this.data.get("status") !== data.status) {
            Turbo.visit(location, {action: 'replace'});
        } else if (data.status === 'pre_flight' || data.status === 'importing') {
            WizardProgressBar.update(this, data);
        }
    }
}