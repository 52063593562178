import moment from 'moment';
import Locale from "./locale";

window.moment = moment;
require("./locales/moment");

Locale.addListener(function (locale) {
    let [language, region, script] = locale.split('-');
    if (region && region.length >= 4) {
        [region, script] = [null, region]
    }
    let languageRegion = [language, region].filter(i => i != null).join('-').toLowerCase();
    if (moment.locales().includes(languageRegion)) {
        moment.locale(languageRegion);
    } else if (moment.locales().includes(language)) {
        moment.locale(language);
    } else {
        moment.locale('en');
    }
});