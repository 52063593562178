if (typeof Array.prototype.includes === "undefined") {
    Array.prototype.includes = function (value) {
        return this.indexOf(value) !== -1;
    }
}
if (typeof String.prototype.includes === "undefined") {
    String.prototype.includes = function (value) {
        return this.indexOf(value) !== -1;
    }
}
if (typeof String.prototype.startsWith === "undefined") {
    String.prototype.startsWith = function (value) {
        return this.indexOf(value) === 0;
    }
}