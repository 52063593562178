import AutocompleteSource from './autocomplete_source'
import i18n from "../../i18n";

const getBoarIdentifier = function (ejaculate) {
    if (ejaculate.boar.current_name && ejaculate.boar.current_name.length) {
        return ejaculate.boar.current_name;
    } else {
        return ejaculate.boar.breed_registry_code;
    }
}

export default class BoarEjaculateAutocompleteSource extends AutocompleteSource {
    constructor(options) {
        super('boar_ejaculates', {keys: ['code'], ...options});
    }

    styleOptionNode(node, selection) {
        node.classList.add('d-flex', 'align-items-center');
        node.innerHTML = `<div class="text-wrap">${selection.match}</div><div class="text-truncate text-monospace ml-2">${getBoarIdentifier(selection.value)}</div>`;
    }

    styleFormattedValue(node, selection) {
        node.append(' – ');
        node.append(getBoarIdentifier(selection.value));
    }
}
