import {Controller} from "@hotwired/stimulus"
import i18n from "../i18n";

// Connects to data-controller="pattern-validator"
export default class extends Controller {
    static values = {
        regex: String
    }

    connect() {
        this.element.addEventListener("input", () => {
            if (this.element.value.match(this.regexValue) || this.element.value.length === this.element.max) {
                this._validate(this.element.value);
            } else {
                this._hideValidation();
            }
        });
        this.element.addEventListener("blur", () => {
            this._validate(this.element.value);
        });
    }

    _validate(value) {
        if (value.length === 0) {
            this._hideValidation();
        } else if (value.match(this.regexValue)) {
            this._showValidation(null);
        } else {
            this._showValidation(i18n.t('errors.messages.invalid'));
        }
    };

    _hideValidation() {
        const group = this.element.closest('.form-group');
        this.element.classList.remove('is-valid', 'is-invalid');

        let feedback = group.querySelector('.invalid-feedback');
        feedback?.remove();
    };

    _showValidation(error) {
        const formGroup = this.element.closest('.form-group');
        let feedback = formGroup.querySelector('.invalid-feedback');
        if (error) {
            this.element.classList.add('is-invalid');
            this.element.classList.remove('is-valid');

            if (feedback == null) {
                feedback = document.createElement('div');
                feedback.classList.add('invalid-feedback');
                feedback.textContent = error
                formGroup.append(feedback)
            }
        } else {
            this.element.classList.remove('is-invalid');
            this.element.classList.add('is-valid');

            feedback?.remove();
        }
    };
}
