import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="eula"
export default class extends Controller {
    static targets = ['readConfirmation', 'fullDocument', 'acceptButton']

    connect() {
        if (this.hasReadConfirmationTarget) {
            if (!this.hasAcceptButtonTarget) {
                throw 'eula-target="acceptButton" is not present';
            }
            this.readConfirmationTarget.addEventListener('change', () => {
                this.acceptButtonTarget.disabled = !this.readConfirmationTarget.checked;
            });
            this.acceptButtonTarget.disabled = !this.readConfirmationTarget.checked;
            if (this.hasFullDocumentTarget) {
                this.fullDocumentTarget.addEventListener('scroll', (ev) => {
                    let element = ev.target;
                    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                        this.readConfirmationTarget.checked = true;
                        this.acceptButtonTarget.disabled = false;
                    }
                });
            }
        }
    }
}
