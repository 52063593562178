import {Controller} from "@hotwired/stimulus";

let connected = false;

// Connects to data-controller="edit-dynamic-form"
export default class extends Controller {
    static targets = ['dynamicFormFieldInputs']

    connect() {
        connected = true;
    }

    disconnect() {
        connected = false;
    }

    dynamicFormFieldInputsTargetConnected(el) {
        this._showOptionErrors(el);
        if (connected) {
            el.querySelector('input:not([type="hidden"])').focus();
        }
    }

    updateValueTypeInputs(event) {
        const dialog = this._findOptionsModal(event.target.closest('[data-edit-dynamic-form-target=dynamicFormFieldInputs]')),
            classToShow = `value_type_${event.target.value}`;
        if (dialog == null) {
            console.error("Cannot find the field options dialog for ", event.target);
            return;
        }
        dialog.querySelectorAll('[class*="value_type_"]').forEach(function (el) {
            if (el.classList.contains(classToShow)) {
                el.classList.remove('d-none');
            } else {
                el.classList.add('d-none');
            }
        });
    }

    _showOptionErrors(el) {
        const dialog = this._findOptionsModal(el);
        if (dialog == null) {
            console.error("Cannot find the field options dialog for ", event.target);
            return;
        }
        const optionsButton = el.querySelector(`[href="#${dialog.parentElement?.id}"]`),
            optionsIcon = optionsButton.querySelector('.fas');
        if (dialog && dialog.querySelector('.is-invalid')) {
            optionsButton.classList.remove('btn-flat-primary');
            optionsButton.classList.add('btn-outline-danger')
            optionsIcon.classList.remove('fa-pen-square');
            optionsIcon.classList.add('fa-exclamation-circle');
        }
    }

    _findOptionsModal(dynamicFieldEl) {
        return dynamicFieldEl && dynamicFieldEl.querySelector('.modal-dialog.dynamic_form_field_options');
    }

}