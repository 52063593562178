import {Controller} from "@hotwired/stimulus"
import {AniX} from "anix";
import {debounce} from "throttle-debounce";
import NestedFieldsHelper from "../nested-fields-helper";

const stepsAttributesIndexMatcher = RegExp('^treatment_steps_attributes_(\\d+)_position$');

// Connects to data-controller="treatment-form"
export default class extends Controller {
    static targets = ['treatmentType', 'remedialInputs', 'preventiveInputs', 'afflictionSearch',
        'treatedAfflictionsWrapper', 'treatedAffliction', 'treatmentApplicableTo', 'treatmentStepsWrapper',
        'stepOptional']

    initialize() {
        this.treatmentApplicableToChanged = debounce(50, this.treatmentApplicableToChanged);
    }

    connect() {
        const plannableEventOptions = {}
        this.preventiveInputsTarget.querySelector('#treatment_plannable_event').querySelectorAll('option')
            .forEach((option) => {
                plannableEventOptions[option.value] = option;
            });
        this.plannableEventOptions = plannableEventOptions;
        this.treatmentApplicableToChanged();
    }

    afflictionSearchTargetConnected(el) {
        const template = el.getAttribute('data-input-template'),
            templateElement = document.createElement('template');

        el.addEventListener('selection', (e) => {
            e.target.value = "";
            e.stopImmediatePropagation();

            const id = e.detail.selection.value.id;
            let afflictionInput = this.treatedAfflictionTargets.find((el) => el.value === id);
            if (afflictionInput) {
                afflictionInput.checked = true;
            } else {
                templateElement.innerHTML = template.replaceAll('__affliction_name__', e.detail.selection.value.name)
                    .replaceAll('__affliction_id__', id);
                afflictionInput = templateElement.content.firstElementChild;
                this.treatedAfflictionsWrapperTarget.append(afflictionInput);
            }
            AniX.fromTo(afflictionInput.closest('.form-check'), 1, {
                backgroundColor: '#ffc553', scale: 1.025
            }, {
                backgroundColor: null, scale: 1
            });
        });
    }

    afterAdd(addedStep) {
        let previousStep = addedStep.previousElementSibling;
        // Skip the destroyed steps
        while (previousStep && previousStep.style.display === 'none') {
            previousStep = previousStep.previousElementSibling;
        }
        if (previousStep) {
            // Get the position input to determine the indices for the inputs in the previous and the new card.
            const previousIndex = NestedFieldsHelper.determineIndex(previousStep, 'position'),
                addedIndex = NestedFieldsHelper.determineIndex(addedStep, 'position');

            if (previousIndex && addedIndex) {
                // Copy values from last step to this step
                NestedFieldsHelper.copyInputValues('steps_attributes', previousStep, previousIndex,
                    addedStep, addedIndex, (fromEl, toEl) => {
                        if (toEl.name.endsWith('[position]')) {
                            return false;
                        }
                        if (toEl.closest(`#treatment_step_${addedIndex}_drugs`)) {
                            // Do the drug inputs separately
                            return false;
                        }
                    }
                );

                // Add drug fields to match the number of drugs in the previous step
                const nestedDrugsFields = previousStep.querySelector(`#treatment_step_${previousIndex}_drugs`)?.querySelectorAll('.nested-fields'),
                    drugCount = nestedDrugsFields?.length;
                for (let i = 1; i < drugCount; i++) {
                    addedStep.querySelector(`a[data-association-insertion-node="#treatment_step_${addedIndex}_drugs"]`).click();
                }
                const allAddedDrugsFields = addedStep.querySelector(`#treatment_step_${addedIndex}_drugs`)?.querySelectorAll('.nested-fields');
                nestedDrugsFields.forEach((previousDrugFields, index) => {
                    // Get the position input to determine the indices for the inputs in the previous and the new card.
                    const addedDrugsFields = allAddedDrugsFields[index],
                        previousDrugsIndex = NestedFieldsHelper.determineIndex(previousDrugFields, 'position'),
                        addedDrugsIndex = NestedFieldsHelper.determineIndex(addedDrugsFields, 'position');

                    if (previousDrugsIndex && addedDrugsIndex) {
                        NestedFieldsHelper.copyInputValues('drugs_attributes',
                            previousDrugFields, previousDrugsIndex,
                            addedDrugsFields, addedDrugsIndex, (fromEl, toEl) => {
                                if (toEl.name.endsWith('[position]') || toEl.name.endsWith('[quantity_prefill]')) {
                                    return false;
                                }
                            }
                        );
                    } else {
                        console.log("Cannot copy over drugs values, indices not found")
                    }
                });
            } else {
                console.warn("Cannot copy over treatment step values, indices not found")
            }
        } else {
            console.warn("Cannot copy over treatment step values, no previous element")
        }
    }

    stepOptionalChanged(e) {
        if (e.target.checked) {
            // Also make following steps optional
            let next = e.target.closest('.nested-fields').nextElementSibling, stepOptionalInput;
            while (next) {
                stepOptionalInput = next.querySelector('[data-treatment-form-target="stepOptional"]');
                if (stepOptionalInput) {
                    stepOptionalInput.checked = true
                }
                next = next.nextElementSibling;
            }
        } else {
            // Also make previous steps required
            let previous = e.target.closest('.nested-fields').previousElementSibling, stepOptionalInput;
            while (previous) {
                stepOptionalInput = previous.querySelector('[data-treatment-form-target="stepOptional"]');
                if (stepOptionalInput) {
                    stepOptionalInput.checked = false
                }
                previous = previous.previousElementSibling;
            }
        }
    }

    treatmentTypeChanged(e) {
        if (e.target.checked) {
            switch (e.target.value) {
                case "remedial":
                    this.remedialInputsTarget.classList.remove('d-none');
                    this.preventiveInputsTarget.classList.add('d-none');
                    break;
                case "preventive":
                    this.remedialInputsTarget.classList.add('d-none');
                    this.preventiveInputsTarget.classList.remove('d-none');
                    break;
            }
        }
    }

    treatmentApplicableToChanged() {
        const breedingInput = this.treatmentApplicableToTargets.find((el) => el.getAttribute('data-applicable-to-value') === 'breeding');
        // Show breeding options if breeding is checked or if none of the applicableTo inputs are checked
        if (breedingInput?.checked || this.treatmentApplicableToTargets.every((el) => !el.checked)) {
            this._addPlannableEventOption('insemination');
            this._addPlannableEventOption('farrowing');
            this._addPlannableEventOption('sow_weaning');
        } else {
            this._removePlannableEventOption('insemination');
            this._removePlannableEventOption('farrowing');
            this._removePlannableEventOption('sow_weaning');
        }
    }

    _addPlannableEventOption(value) {
        const option = this.plannableEventOptions[value];
        if (option) {
            const select = this.preventiveInputsTarget.querySelector('#treatment_plannable_event');
            // Add the option or move it to the end of the list
            select.append(option);
        } else {
            throw new TypeError(`option for value '${value}' does not exist`);
        }
    }

    _removePlannableEventOption(value) {
        this.plannableEventOptions[value]?.remove();
    }
}
