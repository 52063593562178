import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="sign-in"
export default class extends Controller {
    static targets = ['webauthnVerificationAction']
    static values = {expiresOn: Number}

    connect() {
        if (this.hasExpiresOnValue) {
            this.expiryTimeout = setTimeout(() => {
                console.warn('Authentication session expired');
                location.reload();
            }, this.expiresOnValue * 1000 - Date.now());
        }
    }

    disconnect() {
        if (this.expiryTimeout != null) {
            clearTimeout(this.expiryTimeout);
            this.expiryTimeout = null;
        }
    }

    webauthnVerificationActionTargetConnected(target) {
        setTimeout(() => {
            if (document.hasFocus()) {
                target.focus();
                target.click();
            }
        }, 250);
    }
}
