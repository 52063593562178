import {Controller} from "@hotwired/stimulus"

const secondsInADay = 86400, secondsInAnHour = 3600;

// Connects to data-controller="duration-input"
export default class extends Controller {
    static targets = ['days', 'hours', 'totalSeconds']

    hoursTargetConnected(el) {
        el.addEventListener('keydown', (evt) => {
            if (evt.key === 'ArrowDown' && el.value === "0") {
                const days = parseInt(this.daysTarget.value)
                if (days > 0) {
                    this.daysTarget.value = days - 1;
                    el.value = "24";
                    this.valueChanged();
                }
            }
        });
    }

    valueChanged() {
        const days = this.daysTarget.value.length > 0 ? parseFloat(this.daysTarget.value.replace(",", ".")) : null,
            hours = this.hoursTarget.value.length > 0 ? parseFloat(this.hoursTarget.value.replace(",", ".")) : null;

        let totalSeconds = "";
        if (days != null || hours != null) {
            totalSeconds = ((days ?? 0) * secondsInADay + (hours ?? 0) * secondsInAnHour).toString();
        }
        if (this.totalSecondsTarget.value !== totalSeconds) {
            this.totalSecondsTarget.value = totalSeconds;
            this.totalSecondsTarget.dispatchEvent(new Event('change', {'bubbles': true}));
        }
    }
}
