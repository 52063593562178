import {Controller} from "@hotwired/stimulus"
import {debounce} from "throttle-debounce";
import i18n from "../i18n";

// Connects to data-controller="government-code-validator"
export default class extends Controller {
    static targets = ["input", "inputLabel", "addressInputs", "countryInput"]
    static values = {pattern: String}

    connect() {
    }

    inputTargetConnected(el) {
        el.addEventListener('input', debounce(300, () => this._validateGovernmentCode()));
        this._validateGovernmentCode();
    }

    addressInputsTargetConnected(el) {
        if (!this.hasCountryInput) {
            let countryInput = el.querySelector('[name$="[address_attributes][country_code]"]');
            if (countryInput) {
                this.countryInputTargetConnected(countryInput);
            } else {
                console.error("cannot find country code input in addressInputs")
            }
        }
    }

    countryInputTargetConnected(el) {
        el.addEventListener('change', () => {
            if (el.value && el.value.length) {
                this._updateGovernmentCodeInfo(el.value);
            }
        });
    }

    async _updateGovernmentCodeInfo(countryCode) {
        const response = await fetch(`/government_codes/${countryCode}.json`)
            .then(r => r.ok ? r.json() : null);
        if (response) {
            this.patternValue = response.government_code_pattern.location_code_pattern;
            if (this.hasInputLabelTarget) {
                this.inputLabelTarget.textContent = response.government_code_pattern.location_code_name;
            }
        } else {
            this.patternValue = "";
            if (this.hasInputLabelTarget) {
                this.inputLabelTarget.textContent = i18n.t('activerecord.models.government_code_pattern', {count: 1});
            }
        }
        this._validateGovernmentCode();
    }

    _validateGovernmentCode() {
        const group = this.inputTarget.closest('.form-group'),
            value = this.inputTarget.value,
            pattern = this.hasPatternValue && this.patternValue.length ?
                new RegExp('^' + this.patternValue + '$') : null;
        let feedback = group.querySelector('.invalid-feedback');

        if (pattern == null || value.length === 0) {
            this.inputTarget.classList.remove('is-valid', 'is-invalid');
            feedback?.remove();
        } else if (value.match(pattern)) {
            this.inputTarget.classList.remove('is-invalid');
            this.inputTarget.classList.add('is-valid');
            feedback?.remove();
        } else {
            this.inputTarget.classList.add('is-invalid');
            this.inputTarget.classList.remove('is-valid');
            if (feedback == null) {
                feedback = document.createElement('div');
                feedback.classList.add('invalid-feedback');
                feedback.textContent = i18n.t('views.government_code_patterns.js.invalid_government_code')
                group.append(feedback)
            }
        }
    };
}
