import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="address-form"
export default class extends Controller {
    static targets = ['countrySelect', 'postalCodeLabel', 'regionCodeLabel', 'regionCodeSelect'];

    connect() {
    }

    async countryChanged(ev) {
        const countryCode = ev.target.value;
        if (countryCode) {
            const countryInfoResponse = await this._fetchCountryInfo(countryCode),
                countryInfo = countryInfoResponse?.country;

            if (this.hasPostalCodeLabelTarget) {
                this.postalCodeLabelTarget.textContent = countryInfo.postal_code_label;
            }
            if (this.hasRegionCodeSelectTarget) {
                const showRegionSelect = countryInfo.subdivision_required && countryInfo.subdivisions;
                this.regionCodeSelectTarget.closest('.form-group').classList.toggle('d-none', !showRegionSelect);
                this.regionCodeSelectTarget.replaceChildren(document.createElement('option'));
                this.regionCodeSelectTarget.value = "";
                if (showRegionSelect) {
                    this.regionCodeLabelTarget.textContent = countryInfo.subdivisions_label;
                    Object.entries(countryInfo.subdivisions).forEach(entry => {
                        const [subdivisionCode, subdivisionName] = entry;
                        const optionEl = Object.assign(
                            document.createElement('option'),
                            {textContent: subdivisionName, value: subdivisionCode}
                        );
                        this.regionCodeSelectTarget.append(optionEl);
                    });
                }
            }
        }
    }

    _fetchCountryInfo(countryCode) {
        return fetch(`/countries/${countryCode}`)
            .then((response) => {
                return response.ok ? response.json() : null;
            })
    }
}
