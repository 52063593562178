import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="refresh"
export default class extends Controller {
    static targets = ['refresh']
    static values = {
        interval: Number
    }

    connect() {
        this._setRefreshTimeout();
    }

    disconnect() {
        if (this.timeout != null) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    refreshTargetConnected(el) {
        if (el.nodeName !== 'TURBO-FRAME') {
            throw 'Refresh target must be a turbo frame';
        }
    }

    now() {
        if (this.hasRefreshTarget) {
            this.reloadTurboFrame(this.refreshTarget);
            this._setRefreshTimeout();
        } else {
            location.reload();
        }
    }

    reloadTurboFrame(frame) {
        if (frame.getAttribute('src') == null) {
            frame.setAttribute('src', window.location);
        } else {
            frame.reload();
        }
    }

    _setRefreshTimeout() {
        if (this.hasIntervalValue && this.intervalValue >= 1) {
            this.timeout = setTimeout(this.now.bind(this), this.intervalValue * 1000);
        }
    }
}
